// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbtEOGIHQQmh9F82gPkEWhiTaZ0vEgGMM",
  authDomain: "auth.petmeetly.com",
  projectId: "petmeetly",
  storageBucket: "petmeetly.appspot.com",
  messagingSenderId: "769831117346",
  appId: "1:769831117346:web:a25e785c4d276e71e6a07b",
  measurementId: "G-7CGRZ1HCDK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }