<template>
    <v-card class="rounded-lg mild-shadow mx-1 mb-6">

        <v-fab-transition>
            <v-btn color="primary" fab small absolute top right @click.stop="showConversation=true">
                <v-badge color="secondary" :content="this.$store.getters.getUnreadMessages(pet.xid)"
                    :value="this.$store.getters.getUnreadMessages(pet.xid)">
                    <v-icon size="21" color="#f0e7ff">mdi-chat-processing</v-icon>
                </v-badge>
            </v-btn>
        </v-fab-transition>

        <v-img v-if="pet.avatar" :src="pet.avatar.photo" cover min-height="200" max-height="200" class="rounded-lg"
            @click="goToPet(pet.xid)" :class="pet.active==0?'greyscale':''">
            <div class="hidden-tag" v-if="pet.active==0">Hidden</div>
            <div class="premium-tag" v-else-if="pet.is_premium">Premium</div>
            <div class="price-tag" v-if="pet.price > 0">{{Number(pet.price).toLocaleString()}} {{ pet.price_ccy == null
                ? '$ USD' : pet.price_ccy }}</div>
            <div class="verified-tag" v-if="pet.video_verified">Verified</div>
  
        </v-img>

        <v-avatar v-else tile size="200" color="primary" style="align:center; width:100%" class="rounded-lg"
            @click="goToPet(pet.xid)" :class="pet.active==0?'greyscale':''">
            <div class="hidden-tag" v-if="pet.active==0">Hidden</div>
            <div class="premium-tag" v-else-if="pet.is_premium">Premium</div>
            <v-icon size="80" class="white--text">{{pet.pet_type ? '$vuetify.icons.'+pet.pet_type.icon :
                'mdi-paw'}}</v-icon>
            <div class="price-tag" v-if="pet.price > 0">{{Number(pet.price).toLocaleString()}} {{ pet.price_ccy == null
                ? '$ USD' : pet.price_ccy }}</div>
            <div class="verified-tag" v-if="pet.video_verified">Verified</div>

        </v-avatar>

        <!-- <div class="ribbon ribbon-top-left"><span>HIDDEN</span></div> -->



        <v-card-text>

            <v-row no-gutters>
                <v-col cols="11" @click="goToPet(pet.xid)">
                    <div class="text-body-1 font-weight-bold black--text">{{pet.name}}
                        <!-- <v-icon small v-if="pet.pedigree_certified" class="primary--text">mdi-seal-variant</v-icon>
                    <v-icon small v-if="pet.dna_tested" class="primary--text">mdi-dna</v-icon> -->
                    </div>
                    <div class="text-caption">{{pet.breed}}</div>
                    <div class="text-caption">{{pet.age ? pet.age.long : '-'}} old, {{pet.gender == 'M' ? 'Male' :
                        'Female'}}</div>
                </v-col>
                <v-col cols="1" class="text-right">

                    <v-btn icon @click="menuSheet=true" v-if="$vuetify.breakpoint.xs">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>

                    <v-menu bottom left v-else>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense nav>

                            <v-list-item @click="goToPet(pet.xid)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>mdi-post-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>View</v-list-item-title>
                                    <v-list-item-subtitle>View your pet details</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="editPet(pet.xid)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>$vuetify.icons.edit-icon</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                    <v-list-item-subtitle>Update your pet's details and photos</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="showPet(pet.xid, false)" v-if="pet.active == 1">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>mdi-eye-off-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Hide (Unpublish)</v-list-item-title>
                                    <v-list-item-subtitle>Keep your pet's profile hidden.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="showPet(pet.xid, true)" v-else>
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>mdi-eye-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Visible (Publish)</v-list-item-title>
                                    <v-list-item-subtitle>Keep your pet's profile visible.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="confirmDelete=true">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Delete</v-list-item-title>
                                    <v-list-item-subtitle>Delete your pet's profile.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <!--  <v-list-item @click="console.log('clicked')">
                            <v-list-item-icon class="mr-2">
                                <v-icon small>mdi-bell-ring-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Match Settings</v-list-item-title>
                                <v-list-item-subtitle>Match preferences for your pet.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->

                        </v-list>
                    </v-menu>

                </v-col>
            </v-row>

            <v-row no-gutters class="pt-4 pb-1">
            <v-col cols="9" class="">
                <span class="text-caption grey--text" >Profile Completion</span>
                <v-btn text x-small @click="showProfileCompletenessDialog=true" class="font-weight-medium text-caption primary--text ml-2">Edit</v-btn>
                  
            </v-col>
                <v-col cols="3" class="text-right">
                
                    <span class="float-end text-caption font-weight-bold" >{{ profilePercentage }}%</span>
            </v-col>
            </v-row> 
            <div class="pb-2">
                <v-progress-linear :value="profilePercentage" color="success" background-color="#eee" rounded height="8" @click="showProfileCompletenessDialog=true">
                   
                </v-progress-linear>

            </div>

            <div class="pt-4" v-if="pet.matches > 1">
                <v-icon size="20" color="primary" class="pr-2">mdi-thumb-up-outline</v-icon>
                <span class="text-caption primary--text"> You've got <strong>{{pet.matches}}</strong> matches.</span>
            </div>

            <div class="pt-4">

                <v-row align="center" justify="center">
                    <v-col cols="12" class="text-center"
                        v-if="!this.$store.getters.userDetails.email_verified || !this.$store.getters.userDetails.phone_verified">
                        <v-btn large block color="primary" @click="showVerifyContactPop">FIND {{pet.purpose == 'lover' ?
                            'MATCHES' : (pet.purpose == 'adopter' ? 'ADOPTERS' : (pet.purpose == 'buyer' ? 'BUYERS' :
                            'FRIENDS'))}} <v-icon size="20" right>mdi-arrow-right</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center"
                        v-else-if="pet.video_verified == 0 && ['adopter', 'buyer'].includes(pet.purpose)">
                        <v-btn large block color="primary" @click="showPetProfileVerificationPop = true">FIND
                            {{pet.purpose == 'lover' ? 'MATCHES' : (pet.purpose == 'adopter' ? 'ADOPTERS' : (pet.purpose
                            == 'buyer' ? 'BUYERS' : 'FRIENDS'))}} <v-icon size="20"
                                right>mdi-arrow-right</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center" v-else>
                        <v-btn large block color="primary" :to="'/search/'+pet.purpose+'/'+pet.xid">FIND {{pet.purpose
                            == 'lover' ? 'MATCHES' : (pet.purpose == 'adopter' ? 'ADOPTERS' : (pet.purpose == 'buyer' ?
                            'BUYERS' : 'FRIENDS'))}} <v-icon size="20" right>mdi-arrow-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </div>

            <!--  <div v-if="newMatchLoading" class="pt-8">

            <v-row align="center" justify="center" no-gutters>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
            </v-row>

        </div>
        <div v-else>

            <div v-if="newMatches.length < 3" class="pt-8">
                <v-row align="center" justify="center">
                    <v-col cols="12" class="text-center">
                        <v-btn block color="primary" :to="'/search/'+pet.purpose+'/'+pet.xid">Find Matches</v-btn>
                    </v-col>
                </v-row>
            </div>

            <div v-else>

                <div class="text-subtitle-2 black--text py-3 pt-5">New matches</div>

                <v-row align="center" justify="center">
                    <v-col cols="3" class="text-center" v-for="(item,index) in newMatches" :key="index" @click="goToPet(item.xid)">
                        <v-avatar color="dark">
                            <img v-if="item.avatar" :src="item.avatar.photo" cover alt="item.name">
                            <v-icon color="white" v-else>{{item.pet_type ? item.pet_type.icon : 'mdi-pets'}}</v-icon>
                        </v-avatar>
                        <div class="text-truncate text-caption font-weight-medium primary--text mt-1">{{item.name}}</div>
                    </v-col>
                    <v-col cols="3" class="text-center">
                        <v-btn fab elevation="0" style="height:48px;width:48px;" color="primary" :to="'/search/'+pet.purpose+'/'+pet.xid">
                            <v-icon size="24">mdi-chevron-double-right</v-icon>
                        </v-btn>
                        <div class="text-caption font-weight-medium primary--text mt-1">See All</div>
                    </v-col>
                </v-row>
            </div>
        </div> -->

        </v-card-text>

        <v-bottom-sheet v-model="menuSheet">
            <v-list nav>
                <v-list-item @click="goToPet(pet.xid)">
                    <v-list-item-icon>
                        <v-icon>mdi-post-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>View</v-list-item-title>
                        <v-list-item-subtitle>View your pet details</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="editPet(pet.xid)">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.edit-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Edit</v-list-item-title>
                        <v-list-item-subtitle>Update your pet's details and photos.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="showPet(pet.xid, false)" v-if="pet.active == 1">
                    <v-list-item-icon>
                        <v-icon>mdi-eye-off-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Hide (Unpublish)</v-list-item-title>
                        <v-list-item-subtitle>Keep your pet's profile hidden.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="showPet(pet.xid, true)" v-else>
                    <v-list-item-icon>
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Make Visible (Publish)</v-list-item-title>
                        <v-list-item-subtitle>Keep your pet's profile visible.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="confirmDelete=true">
                    <v-list-item-icon>
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Delete</v-list-item-title>
                        <v-list-item-subtitle>Delete your pet's profile.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item @click="console.log('clicked')">
                <v-list-item-icon>
                    <v-icon>mdi-bell-ring-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Match Settings</v-list-item-title>
                    <v-list-item-subtitle>Match preferences for your pet.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->
            </v-list>
        </v-bottom-sheet>


        <v-dialog v-model="confirmDelete" max-width="400">
            <v-card>
                <v-card-title class="text-subtitle-1">Delete Pet</v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to delete this pet's profile?
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="confirmDelete = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="deletePet(pet.xid)">Submit</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <pet-pop v-if="isMounted" v-model="showPetPop" :pet="pet" />
        <pet-profile-completeness-pop v-if="isMounted" v-model="showProfileCompletenessDialog" :pet="pet"
            @force-pet-rerender="forcePetRender" />
        <conversations v-if="isMounted" v-model="showConversation" :pet="pet" />
        <pet-profile-verification-pop v-if="isMounted" v-model="showPetProfileVerificationPop" :pet="pet"
            @force-pet-rerender="forcePetRender" />


    </v-card>
</template>

<script>
import PetPop from './PetPop.vue'
import PetProfileVerificationPop from './PetProfileVerificationPop.vue'
import PetProfileCompletenessPop from './PetProfileCompletenessPop.vue'
import Conversations from './Conversations.vue'
import PetDnaReportVerificationPop from "./PetDnaReportVerificationPop.vue";
export default {
    name: 'MyPetCard',
    props: ['petId'],

    mounted() {
        //this.getNewMatches();
        this.pet = this.$store.getters.myPetDetails(this.petId);
        this.isMounted = true;
        /* this.progressColor = this.pet.profile_completeness.total_percentage > 80 ? '#56ca02' :
            this.pet.profile_completeness.total_percentage > 60 ? '#54bdff' : '#f9aa16';
        
        this.progressColorBg = this.pet.profile_completeness.total_percentage > 80 ? '#e7f7e0' :
            this.pet.profile_completeness.total_percentage > 60 ? '#e3f3ff' : '#fff3e0';

        this.profilePercentage = this.pet.profile_completeness ? this.pet.profile_completeness.total_percentage : 0; */
    },



    data: () => ({
        pet:{},
        confirmDelete: false,
        isMounted: false,
        menuSheet: false,
        newMatches: [],
        newMatchLoading: false,
        showPetPop: false,
        showPetProfileVerificationPop: false,
        showDnaReportDialog: false,
        showConversation: false,

        /* progressColor: '#f9aa16',
        progressColorBg: '#fff3e0',
        profilePercentage: 0, */
        showProfileCompletenessDialog: false,
    }),

    components: {
      PetDnaReportVerificationPop,
    PetPop,
    Conversations,
    PetProfileVerificationPop,
      PetProfileCompletenessPop,
  },

  computed: {

    profilePercentage() {
      return this.pet.profile_completeness ? this.pet.profile_completeness.total_percentage : 0;
    },
    progressColor() {
      return this.profilePercentage > 80 ? '#56ca02' :
        this.profilePercentage > 60 ? '#54bdff' : '#f9aa16';
    },
    progressColorBg() {
      return this.profilePercentage > 80 ? '#e7f7e0' :
        this.profilePercentage > 60 ? '#e3f3ff' : '#fff3e0';
    }
  },

    methods: {
        goToPet(xid) {
            /* this.$router.push({
                name: 'Pet',
                params: {
                    id: xid
                }
            }) */
            this.showPetPop=true
        },

        editPet(xid) {
            this.$router.push({
                name: 'EditPet',
                params: {
                    id: xid
                }
            })
        },

        deletePet(xid) {
            this.$store.dispatch('deletePet', xid).then((resp) => {
                    this.menuSheet = false;
                    this.confirmDelete = false;
                    this.$emit('force-hooper-rerender');
                })
                .catch(err => {
                    this.menuSheet = false;
                });
        },

        showPet(xid, show) {

            this.$store.dispatch('showPet', {
                    xid,
                    show
                }).then((resp) => {

                    this.pet.active = show ? 1 : 0
                    this.menuSheet = false;

                })
                .catch(err => {
                    this.menuSheet = false;
                });
        },

        getNewMatches() {
            this.newMatchLoading = true
            this.$store.dispatch('newMatches', this.pet.xid).then((resp) => {
                    //console.log(resp.data);
                    this.newMatches = resp.data;
                    this.newMatchLoading = false;
                })
                .catch(err => {
                    this.newMatchLoading = false;
                });

        },

        showVerifyContactPop()
        {
            this.$store.dispatch('showVerifyContactPop', true)
        },

        forcePetRender() {
            // Force the  component to re-render
            this.pet = this.$store.getters.myPetDetails(this.petId);
        },
    }

}
</script>
