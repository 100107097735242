<template>
  <div>
   <v-app-bar app flat color="primary" dark prominent>
        <v-btn icon :to="'/home'" v-if="$store.getters.previousRoute.name == 'Chats'">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon :to="'/home'" v-else-if="$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="$router.go(-1)" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-h5 font-weight-light" style="letter-spacing:1px !important">Archived Messages</v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-slot:img>
            <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">mdi-archive-outline</v-icon>
        </template>

        

    </v-app-bar>

    <v-row no-gutters v-if="this.$store.getters.conversations.length == 0">
        <v-col cols="12" class="pa-12 text-center" >
            <v-icon size="120" color="#eee">mdi-email-off</v-icon>
            <div>No Messages</div>
        </v-col>
    </v-row>


  <v-list two-line v-else>
                <div v-for="(item,index) in this.$store.getters.conversations" :key="index">
                    <conversation-row :item="item" v-if="item.is_archived_for_user"></conversation-row>
                    <v-divider inset v-if="item.is_archived_for_user"></v-divider>
                </div>
            </v-list>

  </div>
</template>


<script>
import ConversationRow from './components/ConversationRow.vue'
  export default {
    name: 'ArchivedMessages',
    mounted() {
        if (this.$store.getters.userDetails.latitude == null || this.$store.getters.userDetails.latitude == '') {
            this.$router.replace('home');
        }
    },
    components: {
        ConversationRow
    },
    methods: {

    }
  }
</script>
