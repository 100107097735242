<template>
  <v-container fill-height class="home onboard-bg">

    <v-app-bar app flat color="primary" dark prominent>

      <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn icon @click="$router.go(-1)" v-else>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title class="text-h4 font-weight-light" style="letter-spacing:2px !important">Search</v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:img>
        <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">mdi-magnify</v-icon>
      </template>



    </v-app-bar>

    <v-row justify="center" align="center">
      <v-col cols="12" md="6" lg="4" class="text-center">
        <h2 class="primary--text font-weight-regular pl-4 py-4">Hi <span
            class="">{{ this.$store.getters.userDetails.name }},</span><br /> What are you looking for?</h2>

        <div>

          <v-row no-gutters>
            <v-col cols="6" v-for="(item, index) in this.$store.getters.userDetails.pets" :key="index">
              <v-card :to="'/search/' + item.purpose + '/' + item.xid" class="ma-2 soft-shadow">
                <v-img :src="item.avatar ? item.avatar.photo : require('@/assets/logo/logo-bg2.jpg')"
                  :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300"
                  class="white--text align-end rounded-lg" gradient="to bottom , rgba(0,0,0,.01) 50%, rgba(0,0,0,.7)">
                  <div class="text-subtitle-2 font-weight-medium text-truncate pb-1">{{ item.name }} </div>
                </v-img>
                <v-card-actions>

                  <v-btn color="primary" dark block :to="'/search/' + item.purpose + '/' + item.xid"
                    style="text-transform:capitalize !important">Find {{ item.purpose }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="pa-2">
          <v-btn large color="primary"  block class="my-4" :to="'/newpet'">Add A
            Pet</v-btn>
          <v-btn v-if="this.$store.getters.location != 'IN'" large color="secondary" dark block class="my-4 mild-shadow"
            :to="'/search/available'">Adopt/Buy A Pet</v-btn>
        </div>


      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'Search',
  mounted() {

    if (this.$store.getters.userDetails.latitude == null || this.$store.getters.userDetails.latitude == '') {
      this.$router.replace('home');
    }

  },
}
</script>
