<template>
<div>
<v-card @click.stop="overlay ? showAdopterPop=false : showAdopterPop=true" class="rounded-lg soft-shadow"  style="overflow:hidden">
    <v-overlay
        :absolute="true"
        :value="overlay"
    >
      <v-btn class="ml-1"  v-if="unblockUserButton" @click="unblockDialog=true;" rounded depressed color="success"><v-icon small >mdi-block-helper</v-icon>&nbsp;Unblock</v-btn>
    </v-overlay>

    <div class="premium-tag" v-if="adopter.is_premium">PREMIUM</div>
    <div class="adopter-verified-tag" v-if="adopter.user.adopter_status === 'verified' && adopter.user.video_verified && adopter.user.email_verified && adopter.user.phone_verified">VERIFIED</div>
    <v-card-title class="pa-0 pt-3">

        <v-row no-gutters align="center">
            
        <v-col cols="10" class="pl-2">
        <v-list>
            <v-list-item two-line>
                <v-list-item-avatar style="border: 2px solid #ffffff" size="48" color="grey lighten-4" class="my-0 align-center justify-center">
                    <v-img v-if="adopter.user.photo" :src="adopter.user.photo"></v-img>
                    <v-icon color="primary" v-else size="28">$vuetify.icons.account-icon</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="black--text">
                    <v-list-item-title class="font-weight-medium">
                        {{adopter.user.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                        {{location}}
                    </v-list-item-subtitle>
                </v-list-item-content>

            </v-list-item>
        </v-list>
        </v-col>
        <v-col>
            <!-- <v-btn icon text  @click.stop.prevent="markFavouriteAdopter" :loading="favLoading" >
                <v-icon v-if="adopter.is_favorite" color="red">
                    mdi-heart
                </v-icon>
                <v-icon v-else color="grey">
                    mdi-heart-outline
                </v-icon>
            </v-btn> -->

            <v-btn icon text v-if="adopter.conversation && $store.getters.getConversation(adopter.conversation.xid)">
                <v-badge color="secondary"  :content="this.$store.getters.getConversation(adopter.conversation.xid).unread_messages_count" :value="this.$store.getters.getConversation(adopter.conversation.xid).unread_messages_count" overlap>
                    <v-icon color="grey">
                        mdi-chat-outline
                    </v-icon>
                </v-badge>
            </v-btn>
        </v-col>
        </v-row>
    </v-card-title>
    <v-card-text class="pa-0 pb-3">
        <!-- <v-card-text class="px-0 pt-0" @click="show = !show"> -->
        <div class="px-4">
            <p class="text-pre-wrap text-caption">{{adopter.description}}</p>
            <!-- <v-divider class="my-2"></v-divider> -->

            <div class="text-caption grey--text pb-1">
                    Preferences:
                    </div>
                    <div>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.pet_type.type}} </v-chip>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.age == 'B' ? 'Baby ' : (adopter.age == 'Y' ? 'Young ' : (adopter.age == 'A' ? 'Adult ' : ( adopter.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.gender == 'M' ? 'Male ' : (adopter.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.pet_size ? adopter.pet_size.size+' size':'Any size'}} </v-chip>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.pet_breed ? adopter.pet_breed.breed+' ':'Any breed'}} </v-chip>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.available_for ? 'For '+ adopter.available_for: 'For sale/adoption'}} </v-chip>
                        <v-chip x-small class="ma-1" style="height: 20px"> {{'Within ' + adopter.pref_distance + ' Miles'}} </v-chip>
                    </div>
            <!-- <v-alert text dense color="secondary" icon="mdi-information" class="text-caption">
                {{adopter.user.name}} prefers a {{adopter.age == 'B' ? 'baby ' : (adopter.age == 'Y' ? 'young ' : (adopter.age == 'A' ? 'adult ' : ( adopter.age == 'S' ? 'senior ' : '')))}}{{adopter.gender == 'M' ? 'male ' : (adopter.gender == 'F' ? 'female ' : '')}}{{adopter.breed?adopter.breed.breed+' ':''}}{{adopter.pet_type.type.toLowerCase()}}.
            </v-alert> -->
        </div>


        <v-row no-gutters class="pa-4">
            <v-col>
                <v-chip v-if="adopter.user.phone_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-cellphone-check</v-icon>
                                    Phone Verified</v-chip>

                    <v-chip v-if="adopter.user.email_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-email-check-outline</v-icon>
                                    Email Verified</v-chip>
                    <v-chip v-if="adopter.user.video_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-video-check-outline</v-icon>
                                    Video Verified</v-chip>
            </v-col>
        </v-row>
        
    </v-card-text>

    <!-- <v-card-actions class="pt-0">
        <v-spacer></v-spacer>


        <v-btn text color="primary" @click.stop="initDirectConnect" v-if="adopter.user.phone_verified && !$store.getters.isConnection(adopter.user.xid)">
                        <v-icon left>mdi-phone</v-icon> CALL
                    </v-btn>

                    <v-btn text color="primary" @click.stop="addConnection" :loading="connecting" class="mr-2" v-if="adopter.user.phone_verified && $store.getters.isConnection(adopter.user.xid)">
                        <v-icon left>mdi-phone-check</v-icon> CALL
                    </v-btn>
              

                    <v-btn text color="primary" @click.stop="chat" v-if="adopter.conversation">
                        <v-badge color="secondary"  :content="this.$store.getters.getConversation(adopter.conversation.xid).unread_messages_count" :value="this.$store.getters.getConversation(adopter.conversation.xid).unread_messages_count" overlap>
                        <v-icon left>mdi-chat-processing</v-icon> MESSAGE
                        </v-badge>
                    </v-btn>

                    <v-btn text color="primary" @click.stop="chat" v-else>
                        <v-icon left>mdi-chat-processing</v-icon> MESSAGE
                    </v-btn>


    </v-card-actions> -->
    

</v-card>

  <v-dialog v-model="unblockDialog" max-width="400">
    <v-card>
      <v-card-title class="text-subtitle-1">Unblock Member</v-card-title>
      <v-card-text>
        <p >
          Are you sure you want to unblock {{adopter.user.name}}?
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="grey" @click="unblockDialog = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="unblockUser">Submit</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

<adopter-pop v-model="showAdopterPop" :adopter="adopter" />
</div>
</template>

<script>
import AdopterPop from './AdopterPop.vue'
export default {
    name: 'AdopterCard',
    props: ['adopter'],

    data: () => ({
        mbu: [],
        mbb: [],
        overlay: false,
        favLoading: false,
        showAdopterPop: false,

        unblockUserButton: false,
        unblockDialog: false,
        unblockUserXid: '',
    }),

    components: {
        AdopterPop,
    },
    mounted() {
      this.mbb = this.$store.getters.userDetails.meBlocked.map(o => {
        return o.bbid;
      });

      this.mbu = this.$store.getters.userDetails.myBlocked.map(o => {
        return o.buid;
      });

      this.overlay = this.mbb.includes(this.adopter.user.xid) || this.mbu.includes(this.adopter.user.xid);
      this.unblockUserButton = this.mbu.includes(this.adopter.user.xid)

    },

    computed: {

        location()
        {
            return (this.adopter.locality ? this.adopter.locality : (this.adopter.city ? this.adopter.city : this.adopter.state)) +  (this.adopter.distance > 0 ? ' ('+ Math.round(this.adopter.distance) +' Miles)' :'')
        }
    },

    methods: {
      unblockUser()
      {
        let xid = this.adopter.user.xid;

        this.$store.dispatch('unblockUser', {
          xid
        })
            .then((resp) => {
              this.$store.dispatch('profile');
              this.overlay = false;
              this.snackbar_text = "Unblocked successfully";
              this.snackbar = true;
              this.unblockDialog = false;
            })
            .catch(err => {
              this.connecting = false;
              this.snackbar_text = "Error submitting";
              this.snackbar = true;
            });
      }
        /* markFavouriteAdopter() {
            let for_id = this.$store.getters.myPetID == null ? 'user' : this.$store.getters.myPetID;

            if (typeof (this.adopter.fav_for) !== 'undefined') {
                for_id = this.adopter.fav_for.fav_for_xid
            }

            let adopter_id = this.adopter.xid;

            this.favLoading = true;
            this.$store.dispatch('markFavoriteAdopter', {
                    for_id,
                    adopter_id,
                }).then(() => {
                    this.favLoading = false;
                })
                .catch(err => {
                    this.favLoading = false;
                    //this.snackbar_text = "Oops, we encountered an error";
                    //this.snackbar = true;
                });

        }, */
    }

}
</script>
