<template>
  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0H24V24H0z"></path><path d="M19.243 4.757c1.462 1.466 2.012 3.493 1.65 5.38.568.16 1.106.463 1.554.908 1.404 1.394 1.404 3.654 0 5.047L17 21.5l-3.022-3L11 21.485 2.52 12.993C.417 10.637.496 7.019 2.757 4.757c2.265-2.264 5.888-2.34 8.244-.228 2.349-2.109 5.979-2.039 8.242.228zm-6.281 7.708c-.616.611-.616 1.597 0 2.208L17 18.682l4.038-4.009c.616-.611.616-1.597 0-2.208-.624-.62-1.642-.62-2.268.002l-1.772 1.754-1.407-1.396-.363-.36c-.624-.62-1.642-.62-2.266 0zm-8.79-6.293c-1.49 1.49-1.565 3.875-.192 5.451L11 18.654l1.559-1.562-1.006-1c-1.404-1.393-1.404-3.653 0-5.047 1.404-1.393 3.68-1.393 5.084 0l.363.36.363-.36c.425-.421.93-.715 1.465-.882.416-1.367.078-2.912-1.001-3.993-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154z"></path></g></svg> -->

  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M4348 4918 c-358 -374 -430 -452 -439 -475 -6 -16 2 -62 27 -160 20
-76 38 -141 40 -145 7 -11 6 -11 -82 21 -176 64 -371 68 -544 12 -140 -45
-280 -135 -362 -233 l-40 -47 -67 68 c-104 107 -203 168 -351 217 -79 26 -97
28 -245 28 -155 1 -163 0 -255 -32 -288 -99 -490 -324 -555 -618 -14 -61 -17
-107 -12 -228 5 -166 27 -272 87 -423 l33 -83 -46 -49 -47 -50 -27 36 c-81
106 -271 224 -428 264 -161 41 -386 22 -537 -44 -78 -35 -164 -92 -184 -121
-31 -48 8 -116 68 -116 18 0 51 13 77 29 119 77 242 114 376 113 208 0 411
-102 525 -263 114 -162 149 -167 236 -37 125 187 337 301 559 301 292 -1 539
-186 637 -478 20 -60 23 -90 23 -205 0 -95 -6 -159 -19 -215 -102 -443 -462
-886 -1026 -1264 -80 -54 -175 -114 -212 -135 l-68 -38 -62 38 c-80 48 -326
213 -332 223 -6 8 102 131 115 131 4 0 29 -14 53 -32 27 -18 58 -31 76 -31 43
0 80 37 80 80 0 39 3 36 -145 148 -55 41 -122 93 -148 115 -67 55 -114 52
-146 -8 -20 -38 -4 -77 50 -123 l49 -41 -58 -64 c-31 -35 -61 -64 -67 -64 -18
0 -251 217 -344 321 -157 175 -251 307 -330 466 -138 275 -155 473 -69 761 11
37 10 47 -3 70 -31 55 -107 54 -140 -3 -10 -17 -28 -68 -40 -115 -45 -167 -25
-430 47 -632 109 -307 345 -627 670 -908 l102 -88 -53 -52 -52 -51 -82 70
c-45 39 -93 74 -107 77 -39 10 -73 -12 -94 -59 -46 -105 -250 -682 -250 -708
0 -39 32 -69 74 -69 41 0 691 315 724 351 42 44 31 75 -58 159 l-81 75 54 53
54 53 114 -80 c187 -131 360 -231 400 -231 62 0 405 215 634 396 144 115 422
394 511 514 64 86 176 262 186 292 3 8 23 3 61 -17 68 -36 68 -36 241 65 237
139 492 331 686 517 133 127 190 196 190 228 0 29 -38 73 -65 76 -47 4 -61 -6
-148 -97 -197 -207 -522 -458 -780 -604 -51 -29 -61 -31 -86 -20 l-29 12 23
68 c46 134 59 225 60 390 0 131 -4 167 -23 233 -96 334 -375 572 -712 606
-156 16 -350 -19 -469 -85 -24 -13 -47 -24 -51 -24 -13 0 -59 137 -80 239 -27
134 -27 282 1 381 114 412 566 618 940 429 108 -54 207 -142 264 -234 36 -59
67 -85 100 -85 33 0 64 26 100 85 112 182 339 305 560 305 81 0 209 -26 260
-52 l25 -14 -64 -63 -63 -63 -39 11 c-22 6 -67 11 -101 11 -53 0 -64 -3 -77
-22 -22 -32 -20 -91 3 -112 12 -11 47 -21 85 -26 90 -11 150 -43 222 -117 52
-54 66 -63 96 -63 66 0 100 72 62 130 -10 14 -35 44 -58 68 l-40 42 56 56 c56
56 56 57 79 39 77 -59 173 -219 204 -340 19 -77 19 -249 0 -352 -26 -136 -88
-302 -159 -423 -43 -73 -46 -117 -13 -148 33 -31 89 -30 115 3 62 79 160 306
200 464 24 95 27 124 27 286 0 178 -1 181 -33 275 -36 104 -95 211 -149 271
-20 22 -31 39 -27 39 5 0 57 -10 116 -21 59 -12 118 -19 131 -15 21 5 190 178
596 609 45 47 55 64 55 93 0 60 -23 73 -203 109 -89 18 -164 35 -167 38 -3 2
-24 74 -46 158 -46 174 -61 199 -119 199 -35 0 -47 -10 -231 -202z m227 -161
c20 -79 34 -113 51 -126 12 -10 71 -28 130 -39 59 -12 110 -24 113 -27 3 -3
-32 -42 -76 -87 l-82 -82 -125 24 c-69 13 -129 26 -134 29 -8 6 -72 230 -72
252 0 17 153 180 160 172 4 -5 19 -57 35 -116z m-299 -302 c18 -69 36 -118 49
-131 15 -15 54 -27 138 -44 l117 -23 -81 -88 c-45 -49 -87 -89 -94 -89 -14 0
-257 49 -261 53 -5 6 -64 238 -64 253 0 14 159 189 165 181 2 -1 15 -51 31
-112z m-3576 -3945 c47 -40 89 -76 94 -81 5 -4 -73 -48 -173 -98 -99 -50 -181
-88 -181 -84 0 5 30 90 66 191 l67 183 21 -19 c12 -10 59 -51 106 -92z"/>
</g>
</svg>

</template>

<script>
export default {
name: 'lover-icon'
}
</script>