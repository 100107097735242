import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import DogIcon from '../assets/icons/dog-icon.vue'
import CatIcon from '../assets/icons/cat-icon.vue'
import RabbitIcon from '../assets/icons/rabbit-icon.vue'
import RodentIcon from '../assets/icons/rodent-icon.vue'
import LoverIcon from '../assets/icons/lover-icon.vue'
import FriendIcon from '../assets/icons/friend-icon.vue'
import AdopterIcon from '../assets/icons/adopter-icon.vue'
import BuyerIcon from '../assets/icons/buyer-icon.vue'
import CoinIcon from '../assets/icons/coin-icon.vue'
import MenuIcon from '../assets/icons/menu-icon.vue'
import SearchIcon from '../assets/icons/search-icon.vue'
import HomeIcon from '../assets/icons/home-icon.vue'
import MessagesIcon from '../assets/icons/messages-icon.vue'
import HeartIcon from '../assets/icons/heart-icon.vue'
import AccountIcon from '../assets/icons/account-icon.vue'
import EditIcon from '../assets/icons/edit-icon.vue'
import HelpIcon from '../assets/icons/help-icon.vue'
import LogoutIcon from '../assets/icons/logout-icon.vue'
import FilterIcon from '../assets/icons/filter-icon.vue'

Vuetify.config.silent = true;

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      'dog-icon': {
        component: DogIcon,
      },
      'cat-icon': {
        component: CatIcon,
      },
      'rabbit-icon': {
        component: RabbitIcon,
      },
      'rodent-icon': {
        component: RodentIcon,
      },
      'lover-icon': {
        component: LoverIcon,
      },
      'friend-icon': {
        component: FriendIcon,
      },
      'adopter-icon': {
        component: AdopterIcon,
      },
      'buyer-icon': {
        component: BuyerIcon,
      },
      'coin-icon': {
        component: CoinIcon,
      },
      'menu': {
        component: MenuIcon,
      },
      'search-icon': {
        component: SearchIcon,
      },
      'home-icon': {
        component: HomeIcon,
      },
      'messages-icon': {
        component: MessagesIcon,
      },
      'heart-icon': {
        component: HeartIcon,
      },
      'account-icon': {
        component: AccountIcon,
      },
      'edit-icon': {
        component: EditIcon,
      },
      'help-icon': {
        component: HelpIcon,
      },
      'logout-icon': {
        component: LogoutIcon,
      },
      'filter-icon': {
        component: FilterIcon,
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#7E0DE7',
        secondary: '#F1397C',
        dark: '#2C0A3F'
      },
    },
  },
});
