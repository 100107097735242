<template>
<v-card class="rounded-lg mild-shadow mx-1 mb-6">

    <!-- <v-fab-transition>
        <v-btn color="#f0e7ff" fab small absolute top right>
            <v-badge color="secondary" :content="pet.my_unread_messages" :value="pet.my_unread_messages">
                <v-icon color="primary">mdi-email</v-icon>
            </v-badge>
        </v-btn>
    </v-fab-transition> -->

    <v-avatar tile size="200" color="primary" style="align:center; width:100%" class="rounded-lg">
        <div class="cr cr-top cr-left cr-grey" v-if="preferences.active==0">Hidden</div>
        <v-icon size="80" class="white--text">{{preferences.pet_type ? '$vuetify.icons.'+preferences.pet_type.icon : 'mdi-paw'}}</v-icon>
    </v-avatar>

    <!-- <div class="ribbon ribbon-top-left"><span>HIDDEN</span></div> -->

    <v-card-text>

        <v-row no-gutters>
            <v-col cols="11">
                <div class="text-subtitle-2 black--text pb-2">
                    My Preferences:
                    </div>
                    <div>
                        <v-chip small class="ma-1"> {{preferences.pet_type.type}} </v-chip>
                        <v-chip small class="ma-1"> {{preferences.age == 'B' ? 'Baby ' : (preferences.age == 'Y' ? 'Young ' : (preferences.age == 'A' ? 'Adult ' : ( preferences.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                        <v-chip small class="ma-1"> {{preferences.gender == 'M' ? 'Male ' : (preferences.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                        <v-chip small class="ma-1"> {{preferences.pet_size  ? preferences.pet_size.size+' size':'Any size'}} </v-chip>
                        <v-chip small class="ma-1"> {{preferences.pet_breed ? preferences.pet_breed.breed+' ':'Any breed'}} </v-chip>
                        <v-chip small class="ma-1"> {{preferences.available_for ? 'For '+ preferences.available_for: 'For sale/adoption'}} </v-chip>
                        <v-chip small class="ma-1"> {{'Within ' + preferences.pref_distance + ' Miles'}} </v-chip>
                    </div>
            </v-col>
            <v-col cols="1" class="text-right">

                <v-btn icon @click="menuSheet=true" v-if="$vuetify.breakpoint.xs">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>

                <v-menu bottom left v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense nav>

                        <v-list-item @click="deletePreferences(preferences.xid)">
                            <v-list-item-icon class="mr-2">
                                <v-icon small>mdi-delete-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Delete</v-list-item-title>
                                <v-list-item-subtitle>Remove my pet preferences.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <!--  <v-list-item @click="console.log('clicked')">
                            <v-list-item-icon class="mr-2">
                                <v-icon small>mdi-bell-ring-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Match Settings</v-list-item-title>
                                <v-list-item-subtitle>Match preferences for your pet.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->

                    </v-list>
                </v-menu>

            </v-col>
        </v-row>


        <div class="pt-4" v-if="preferences.matches > 1">
                <v-icon size="20" color="primary" class="pr-2">mdi-thumb-up-outline</v-icon>
                <span class="text-caption primary--text"> You've got <strong>{{preferences.matches}}</strong> matches.</span>
        </div>

        <div class="pt-4">

            <v-row align="center" justify="center">
                <v-col cols="12" class="text-center" v-if="!this.$store.getters.userDetails.email_verified || !this.$store.getters.userDetails.phone_verified">
                    <v-btn large block color="primary" @click="showVerifyContactPop">FIND PETS <v-icon size="20" right>mdi-arrow-right</v-icon></v-btn>
                </v-col>
                <v-col cols="12" class="text-center" v-else>
                    <v-btn large block color="primary" :to="'/search/available'">FIND PETS <v-icon size="20" right>mdi-arrow-right</v-icon></v-btn>
                </v-col>
            </v-row>

        </div>

        <!--  <div v-if="newMatchLoading" class="pt-8">

            <v-row align="center" justify="center" no-gutters>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
                <v-col cols="3">
                    <v-skeleton-loader class="mx-auto" type="avatar"></v-skeleton-loader>
                </v-col>
            </v-row>

        </div>
        <div v-else>

            <div v-if="newMatches.length < 3" class="pt-8">
                <v-row align="center" justify="center">
                    <v-col cols="12" class="text-center">
                        <v-btn block color="primary" :to="'/search/'+pet.purpose+'/'+pet.xid">Find Matches</v-btn>
                    </v-col>
                </v-row>
            </div>

            <div v-else>

                <div class="text-subtitle-2 black--text py-3 pt-5">New matches</div>

                <v-row align="center" justify="center">
                    <v-col cols="3" class="text-center" v-for="(item,index) in newMatches" :key="index" @click="goToPet(item.xid)">
                        <v-avatar color="dark">
                            <img v-if="item.avatar" :src="item.avatar.photo" cover alt="item.name">
                            <v-icon color="white" v-else>{{item.pet_type ? item.pet_type.icon : 'mdi-pets'}}</v-icon>
                        </v-avatar>
                        <div class="text-truncate text-caption font-weight-medium primary--text mt-1">{{item.name}}</div>
                    </v-col>
                    <v-col cols="3" class="text-center">
                        <v-btn fab elevation="0" style="height:48px;width:48px;" color="primary" :to="'/search/'+pet.purpose+'/'+pet.xid">
                            <v-icon size="24">mdi-chevron-double-right</v-icon>
                        </v-btn>
                        <div class="text-caption font-weight-medium primary--text mt-1">See All</div>
                    </v-col>
                </v-row>
            </div>
        </div> -->

    </v-card-text>

    <v-bottom-sheet v-model="menuSheet">
        <v-list nav>

            <v-list-item @click="deletePreferences(preferences.xid)">
                <v-list-item-icon>
                    <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                    <v-list-item-subtitle>Remove my pet preferences.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item @click="console.log('clicked')">
                <v-list-item-icon>
                    <v-icon>mdi-bell-ring-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Match Settings</v-list-item-title>
                    <v-list-item-subtitle>Match preferences for your pet.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->
        </v-list>
    </v-bottom-sheet>

</v-card>
</template>

<script>
export default {
    name: 'MyPreferenceCard',
    props: ['preferences'],

    mounted() {
        //this.getNewMatches();
        //console.log(this.preferences)
    },

    data: () => ({
        menuSheet: false,
        newMatches: [],
        newMatchLoading: false,
        showPetPop: false
    }),

    methods: {


        deletePreferences(xid) {

            this.$store.dispatch('deletePreferences', xid).then((resp) => {
                    this.menuSheet = false;
                })
                .catch(err => {
                    this.menuSheet = false;
                });
        },

        showVerifyContactPop()
        {
            this.$store.dispatch('showVerifyContactPop', true)
        }

    }

}
</script>
