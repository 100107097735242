<template>
    <div>
        <v-app-bar app flat color="primary" dark prominent>

            <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn icon @click="$router.go(-1)" v-else>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title class="text-h4 font-weight-light"
                style="letter-spacing:2px !important">Account</v-toolbar-title>

            <v-spacer></v-spacer>

            <template v-slot:img>
                <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">$vuetify.icons.account-icon</v-icon>
            </template>

        </v-app-bar>

        <v-row no-gutters justify="center">
            <v-col cols="12" sm="6" md="4" lg="3">


                        <v-row no-gutters class="py-6 pt-12 align-center justify-center">

                            <v-col cols="12" class="px-6 d-flex align-center justify-center">

                                <v-img class="text-center align-end" v-if="this.$store.getters.userDetails.photo"
                                    :src="this.$store.getters.userDetails.photo" cover style="border-radius:60px;"
                                    max-width="120" aspect-ratio="1">
                                    <v-btn class="mb-0 px-6" dark color="black" small @click="launchAvatarPicker">
                                        change
                                    </v-btn>
                                </v-img>

                                <v-card v-else flat
                                    style="border-radius:60px; border: 2px dashed #adb5bd; width: 120px; height: 120px;"
                                    @click="launchAvatarPicker">
                                    <v-icon class="pa-8" color="primary" size="36">mdi-image-plus</v-icon>
                                </v-card>

                                <input type="file" ref="avatarpic" name="avatarpic" @change="loadAvatar($event)"
                                    style="display:none" accept="image/png, image/jpg, image/jpeg">

                                <v-dialog v-model="avatarDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                                    <v-card color="black">
                                        <v-toolbar flat dark color="black">
                                            <v-btn icon @click="avatarDialog = false" color="grey">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn icon dark @click="saveAvatar" :loading="avatarSaving"
                                                :disabled="avatarSaving">
                                                <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text class="pa-0">
                                            <cropper ref="acropper" :src="avatarFile" :canvas="{
                                                height: 600,
                                                width: 600
                                            }" :stencil-props="{
    movable: true,
    scalable: true,
}" image-restriction="stencil" stencil-component="circle-stencil" />

                                        </v-card-text>
                                    </v-card>
                                </v-dialog>

                            </v-col>

                        </v-row>

                        <v-row no-gutters>
                            <v-col class="pa-4 pb-2" cols="10">
                                <div class="primary--text text-subtitle-2 font-weight-600">Name</div>
                                <div>{{ this.$store.getters.userDetails.name }}</div>
                            </v-col>
                            <v-col class="d-flex align-center justify-end pa-4" cols="2">
                                <v-btn icon color="primary" @click="nameDialog = true">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row no-gutters>
                            <v-col class="pa-4 pb-2" cols="10">
                                <div class="primary--text text-subtitle-2 font-weight-600">Email</div>
                                <div>{{ this.$store.getters.userDetails.email }}</div>
                                <div v-show="this.$store.getters.userDetails.sociallogin"
                                    class="text-caption grey--text py-2">You are using a social login. Please set a password
                                    to change your email address</div>
                            </v-col>
                            <v-col class="d-flex align-center justify-end pa-4" cols="2">
                                <v-btn icon color="primary" :disabled="this.$store.getters.userDetails.sociallogin"
                                    @click="emailDialog = true">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row no-gutters>
                            <v-col class="pa-4 pb-2" cols="10">
                                <div class="primary--text text-subtitle-2 font-weight-600">Phone</div>
                                <div>{{ this.$store.getters.userDetails.phone || '-' }} </div>
                                <!-- <v-btn color="primary" class="ml-0 my-2" @click="initPhoneVerify" small v-if="this.$store.getters.userDetails.phone && this.$store.getters.userDetails.phone_verified == 0">
                                Verify Phone
                            </v-btn> -->

                            </v-col>
                            <v-col class="d-flex align-center justify-end pa-4" cols="2">
                                <v-btn icon color="primary" @click="phoneDialog = true">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row no-gutters>
                            <v-col class="pa-4 d-flex align-center" cols="6">
                                <div class="primary--text text-subtitle-2 font-weight-600">Password</div>
                            </v-col>
                            <v-col class="d-flex align-center justify-end pa-4" cols="6">
                                <v-btn color="primary" small @click="passwordDialog = true">
                                    Change Password
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>

                        <v-row no-gutters>
                            <v-col class="pa-4 pb-2" cols="10">
                                <div class="primary--text text-subtitle-2 font-weight-600">Location</div>
                                <div>{{ this.$store.getters.userDetails.location_description }}</div>
                            </v-col>
                            <v-col class="d-flex align-center justify-end pa-4" cols="2">
                                <v-btn icon color="primary" @click="clearLocation(); locationDialog = true">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>



                        <div class="py-12 text-center">
                            <v-btn small text color="red" @click="deleteAccountDialog = true" class="font-weight-600">Delete Account</v-btn>
                        </div>

                  

            </v-col>
        </v-row>

        <v-dialog v-model="locationDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Location</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="locationDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="locationForm" @submit.prevent="setUserLocation">
                    <v-card-text class="pt-4">

                        <v-alert text v-model="locationPicked">
                            <v-row align="center">
                                <v-col class="grow">
                                    {{ location ? location.description : '' }}
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn icon @click="clearLocation">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>

                        <div v-show="!locationPicked">

                            <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat solo
                                outlined dense hide-no-data placeholder="Enter your location" item-text="description"
                                item-value="placeId" clearable autofocus persistent-hint
                                prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails"
                                @click:clear="clearLocation"></v-autocomplete>

                            <div class="py-0 text-center">
                                <v-btn color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                                    <v-icon left>mdi-crosshairs-gps</v-icon> {{ gettingLocation ? 'Getting your location' :
                                        'Use my current location' }}
                                </v-btn>
                            </div>

                        </div>


                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="locationSaving"
                            :disabled="!locationPicked || locationSaving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="nameDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Name</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="nameDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="nameForm" v-model="valid" lazy-validation @submit.prevent="saveName">
                    <v-card-text class="pt-4">
                        <v-text-field outlined dense v-model="name" placeholder="Your Name"
                            :rules="nameRules"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="saving" :disabled="!valid || saving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="emailDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Email</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="emailDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="emailForm" v-model="valid" lazy-validation @submit.prevent="saveEmail">
                    <v-card-text class="pt-4">
                        <v-text-field outlined dense v-model="email" placeholder="Your Email Address" :rules="emailRules"
                            :loading="emailValidating"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="saving" :disabled="!valid || saving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="phoneDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Phone</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="phoneDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form ref="phoneForm" v-model="valid" lazy-validation @submit.prevent="savePhone">
                  <v-card-text class="pt-2 pb-0">
                        <div class="text-caption pb-4">Your phone number with country code <br />(e.g. +19876543210)</div>
                        <vue-tel-input outlined dense v-model="phone" validCharactersOnly @validate="telValidate"
                             place :defaultCountry="'US'" :preferredCountries="['US','CA','GB','AU','IN']"
                             :inputOptions="{showDialCode:true, placeholder: +19876543210}" :dropdownOptions="{width:'300px', showFlags:true}"></vue-tel-input>
<!--                        <v-text-field outlined dense v-model="phone" type="tel" placeholder="+19876543210"-->
<!--                            :rules="phoneRules" :loading="phoneValidating"></v-text-field>-->
                        <p class="text-caption grey--text">By providing your mobile number, you agree to receive text
                            messages from Petmeetly and calls from other verified pet owners.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="saving" :disabled="!valid || saving">Save</v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>
        </v-dialog>

        <v-dialog v-model="passwordDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Password</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="passwordDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="passwordForm" v-model="valid" lazy-validation @submit.prevent="savePassword">
                    <v-card-text class="pt-4">
                        <v-text-field outlined dense v-model="password" type="password" placeholder="New Password"
                            :rules="passwordRules"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="saving" :disabled="!valid || saving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="phoneVerificationDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Phone Verification</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="phoneVerificationDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form ref="codeForm" v-model="valid" lazy-validation @submit.prevent="verifyPhone">
                    <v-card-text class="pt-4">
                        <div class="text-caption pb-4">We will send an SMS with a 6-digit verification code to your phone.
                            If you do not receive a code within a few minutes, please try again.</div>
                        <v-progress-linear v-show="sendingCode" color="primary" indeterminate rounded height="6"
                            class="mb-4"></v-progress-linear>
                        <v-otp-input length="6" type="number" hide-spin-buttons @finish="verifyPhone"
                            v-model="verificationcode"></v-otp-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="submitting"
                            :disabled="!valid || submitting">Verify</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>

        </v-dialog>

        <v-dialog v-model="deleteAccountDialog" persistent max-width="450" :retain-focus="false">

            <v-card>

                <div v-show="deleteStep == 1">
                    <v-card-text>
                        <div class="text-subtitle-1 font-weight-bold py-4">
                            Are you sure you want to delete your account?
                        </div>

                        <div class="py-2" v-if="this.$store.getters.userDetails.dconnects > 0">

                            <v-alert type="primary" icon="mdi-phone">

                                <h5 class="text-subtitle-2">
                                    Connect for FREE
                                </h5>

                                <div class="py-2 text-caption">You have a "Direct Connect Coin" in your account, and you can
                                    use it to call a Pet/User for FREE. Why miss it? </div>

                                <v-row class="pa-4 pb-2">
                                    <v-spacer></v-spacer>
                                    <v-btn small color="white" class="primary--text" :to="'/home'">
                                        Try It Now
                                    </v-btn>

                                </v-row>

                            </v-alert>
                        </div>

                        <div class="py-2">
                            <ul>
                                <li>All your pet profiles will be disabled.</li>
                                <li>Your saved pet preferences will be disabled.</li>
                                <li>Your active subscriptions will be cancelled immediately.</li>
                                <li>Your direct connect balance will be cancelled.</li>
                            </ul>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn depressed @click="deleteAccountDialog = false;" color="secondary">
                            No
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn depressed @click="deleteStep = 2;">
                            Yes
                        </v-btn>
                    </v-card-actions>
                </div>

                <div v-show="deleteStep == 2">

                    <v-toolbar dense flat color="primary" dark>
                        <v-toolbar-title class="text-subtitle-1">Feedback</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="deleteStep = 1; deleteAccountDialog = false;">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text>
                        <div class="text-subtitle-2 pb-4">
                            Your account will be closed shortly. Hope you don't mind answering a couple of questions.
                        </div>
                        <v-divider></v-divider>
                        <div class="text-subtitle-2 pt-4 primary--text">
                            Why do you want to delete your account?
                        </div>
                        <div class="pt-2">
                            <v-radio-group v-model="deleteReason" class="mt-2">
                                <v-radio label="Already found a pet/buyer" value="Already found a pet"></v-radio>
                                <v-radio label="Couldn't find a pet/buyer" value="Couldn't find a pet"></v-radio>
                                <v-radio label="Not looking for a pet/buyer" value="Not looking for a pet"></v-radio>
                                <v-radio label="Other reason" value="Other reason"></v-radio>
                            </v-radio-group>
                        </div>
                        <div v-show="deleteReason == 'Other reason'">
                            <v-text-field class="pt-0" outlined dense v-model="otherReason"
                                placeholder="Please let us know"></v-text-field>
                        </div>

                        <div v-show="deleteReason == 'Already found a pet'">

                            <v-divider></v-divider>
                            <div class="text-subtitle-2 pt-4 primary--text">
                                Where did you find the pet/buyer?
                            </div>
                            <div class="pt-2">
                                <v-radio-group v-model="matchSource" class="mt-2">
                                    <v-radio label="Petmeetly" value="Petmeetly"></v-radio>
                                    <v-radio label="Friends & Family" value="Friends & Family"></v-radio>
                                    <v-radio label="From other sources" value="From other sources"></v-radio>
                                </v-radio-group>
                            </div>
                            <div v-show="matchSource == 'From other sources'">
                                <v-text-field class="pt-0" outlined dense v-model="otherSource"
                                    placeholder="Care to share from where?"></v-text-field>
                            </div>

                        </div>

                        <div v-show="matchSource == 'Petmeetly' && deleteReason == 'Already found a pet'">

                            <v-divider></v-divider>
                            <div class="text-subtitle-2 pt-4 primary--text">
                                Will you recommend Petmeetly to your friends?
                            </div>
                            <div class="pt-2">
                                <v-radio-group v-model="willRecommend" class="mt-2">
                                    <v-radio label="Yes" value="Yes"></v-radio>
                                    <v-radio label="No" value="No"></v-radio>
                                </v-radio-group>
                            </div>

                            <div v-show="willRecommend == 'No'">
                                <v-textarea dense outlined auto-grow row-height="30" rows="2"
                                    placeholder="Care to share why you won't?" v-model="willRecommendReason"></v-textarea>
                            </div>

                        </div>

                        <v-divider></v-divider>
                        <div class="text-subtitle-2 py-4 primary--text">
                            Anything that can be improved?
                        </div>
                        <v-textarea dense outlined auto-grow row-height="30" rows="2"
                            placeholder="Please let us know your suggestions" v-model="feedback"></v-textarea>

                    </v-card-text>

                    <v-card-actions>

                        <v-btn depressed @click="deleteStep = 1; deleteAccountDialog = false;">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn color="secondary" :loading="deleting"
                            :disabled="deleting || !deleteReason || (deleteReason == 'Already found a pet' && !matchSource)"
                            @click="deleteAccount">
                            Submit
                        </v-btn>

                    </v-card-actions>

                </div>

            </v-card>

        </v-dialog>

        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <div id="recaptcha-container-acc"></div>

    </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import {
    Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import {
    auth
} from "../../plugins/firebase"

import {
    RecaptchaVerifier,
    signInWithPhoneNumber
} from "firebase/auth"

import gmapsInit from '../../plugins/gmaps';

export default {
    name: 'Account',

    async mounted() {
        const google = await gmapsInit();
        //this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        this.service = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();

        //console.log('Token');
        //console.log(this.sessionToken);

        if (this.$store.getters.userDetails.name == '') {

            this.$store.dispatch('profile').then(() => {
                this.userAvatar = this.$store.getters.userDetails.photo
                this.notifications_freq = this.$store.getters.userDetails.notifications_freq
                this.name = this.$store.getters.userDetails.name
                this.email = this.$store.getters.userDetails.email
                this.phone = this.$store.getters.userDetails.phone
            });

        } else {
            this.userAvatar = this.$store.getters.userDetails.photo
            this.notifications_freq = this.$store.getters.userDetails.notifications_freq
            this.name = this.$store.getters.userDetails.name
            this.email = this.$store.getters.userDetails.email
            this.phone = this.$store.getters.userDetails.phone

        }

        setTimeout(() => {
            if (!window.recaptchaVerifier) {

                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container-acc', {
                    'size': 'invisible',
                    'callback': (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        //onSignInSubmit();
                    }
                });


            }
        }, 1000);
    },


    data() {
        return {
   
            name: '',
            email: '',
            phone: '',
            validphone: '',
            password: '',

            verificationcode: '',

            step: 'phone',

            nameDialog: false,
            emailDialog: false,
            phoneDialog: false,
            passwordDialog: false,
            deleteAccountDialog: false,
            phoneVerificationDialog: false,

            snackbar: false,
            snackbar_text: '',

            saving: false,
            submitting: false,

            sendingCode: false,

            avatarSaving: false,
            userAvatar: '',
            userAvatarBlob: null,
            avatarDialog: false,
            avatarFile: null,

            tab: null,
            valid: true,

            deleteStep: 1,
            deleteReason: '',
            otherReason: '',
            matchSource: '',
            otherSource: '',
            willRecommend: '',
            willRecommendReason: '',
            feedback: '',
            deleting: false,

            emailValidating: false,
            phoneValidating: false,

            locationDialog: false,

            location: {
                description: '',
                placeId: '',
                state: '',
                country: '',
                zip: '',
                locality: '',
            },
            searchResults: [],
            service: null,
            geocoder: null,
            sessionToken: null,
            search: null,
            cordinates: null,
            locationPicked: false,

            gettingLocation: false,
            locationSaving: false,

            nameRules: [
                v => !!v || 'Name is required',
                v => !(/@/.test(v)) || 'Name must be valid',
            ],

            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],

            phoneRules: [
                v => !!v || 'Phone is required',
                v => /^[+]?[0-9]+$/.test(v) || 'Need valid phone with country code',
                v => (v || '').length >= 12 || 'Need valid phone with country code',
            ],

            passwordRules: [
                v => (v || '').length >= 6 || 'Password must be minimum of 6 characters',
            ],

            verificationCodeRules: [
                v => !!v || 'Verification Code is required',
                v => (v || '').length == 6 || 'Verification Code must be of 6 characters',
            ],

        }
    },
    components: {
        Cropper,
        VueTelInput,
    },

    watch: {


        search(val) {
            if (val != '' && val !== null) {
                if (val.length > 3) {
                    this.service.getPlacePredictions({
                        input: val,
                        //sessionToken: this.sessionToken,
                        types: ['geocode']
                    }, this.displaySuggestions)
                }
            }
        }
    },


    methods: {
        saveName() {

            if (this.$refs.nameForm.validate()) {
                this.saving = true;
                let name = this.name

                this.$store.dispatch('saveAccount', {
                    name,
                })
                    .then(() => {
                        this.saving = false;
                        this.snackbar_text = "Name updated";
                        this.snackbar = true;
                        this.nameDialog = false;
                    })
                    .catch(err => {
                        this.snackbar_text = "Error while updating";
                        this.snackbar = true;
                        this.saving = false;
                    });
            }

        },

        saveEmail() {

            if (this.$refs.emailForm.validate()) {
                this.saving = true;
                let email = this.email;
                this.emailValidating = true;

                this.$store.dispatch('checkProfileEmailExists', this.email)
                    .then((resp) => {
                        if (resp.data.message == 'Email Already Exists') {
                            this.snackbar_text = "Oops, Email address is already in use. Try a different email address.";
                            this.snackbar = true;
                            this.emailValidating = false;
                            this.saving = false;
                        } else if (resp.data.message == 'New Email') {
                            this.emailValidating = false;
                            this.$store.dispatch('saveAccount', {
                                email,
                            })
                                .then(() => {
                                    this.saving = false;
                                    this.snackbar_text = "Email updated";
                                    this.snackbar = true;
                                    this.emailDialog = false;
                                })
                                .catch(err => {
                                    this.snackbar_text = "Error while updating";
                                    this.snackbar = true;
                                    this.saving = false;
                                });

                        }
                    });

            }

        },
        telValidate(telephone){
          //console.log(telephone);

          this.valid = telephone.valid
          this.validphone = telephone.number

        },
        initPhoneVerify() {
            this.sendingCode = true;
            this.phoneVerificationDialog = true;






            const appVerifier = window.recaptchaVerifier;

            signInWithPhoneNumber(auth, this.phone, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    this.sendingCode = false;

                    window.gtag('event', 'phone_verification_init', {
                        event_category: 'Phone Verification',
                    });

                }).catch((error) => {
                    console.log(error)
                    this.snackbar_text = 'Something Went Wrong';
                    this.snackbar = true;
                    this.sendingCode = false;
                });





        },

        savePhone() {

            if (this.$refs.phoneForm.validate()) {
                this.saving = true;
                let phone = this.validphone;
                this.phoneValidating = true;

                this.$store.dispatch('checkProfilePhoneExists', this.validphone)
                    .then((resp) => {
                        if (resp.data.message == 'Phone Already Exists') {
                            this.snackbar_text = "Oops, Phone is already in use. Try a different phone number.";
                            this.snackbar = true;
                            this.phoneValidating = false;
                            this.saving = false;
                        } else if (resp.data.message == 'New Phone') {
                            this.phoneValidating = false;
                            this.$store.dispatch('saveAccount', {
                                phone,
                            })
                                .then(() => {
                                    this.saving = false;
                                    this.snackbar_text = "Phone updated";
                                    this.snackbar = true;
                                    this.phoneDialog = false;
                                })
                                .catch(err => {
                                    this.snackbar_text = "Error while updating";
                                    this.snackbar = true;
                                    this.saving = false;
                                });

                        }
                    });

            }

        },

        verifyPhone() {
            if (this.$refs.codeForm.validate()) {

                this.submitting = true;

                window.confirmationResult.confirm(this.verificationcode)
                    .then((result) => {
                        //console.log(result);
                        this.submitting = false;
                        let token = result.user.uid;
                        this.$store.dispatch('verifyPhone', {
                            token
                        })
                            .then((resp) => {
                                this.snackbar_text = 'Phone Verified';
                                this.snackbar = true;
                                this.submitting = false;
                                this.phoneVerificationDialog = false;

                                window.gtag('event', 'phone_verification_done', {
                                    event_category: 'Phone Verification',
                                });

                            })
                            .catch(err => {
                                //console.log(err);
                                this.submitting = false;
                                this.snackbar_text = 'Error while verifying';
                                this.snackbar = true;
                            });

                    }, (error) => {
                        //console.log(error); 
                        this.snackbar_text = 'Invalid verification code';
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }

        },

        /* isRecaptchaValid() {
            return (grecaptcha.getResponse(window.recaptchaWidgetId).length > 0);
        }, */

        savePassword() {

            if (this.$refs.passwordForm.validate()) {
                this.saving = true;
                let password = this.password

                this.$store.dispatch('saveAccount', {
                    password,
                })
                    .then(() => {
                        this.saving = false;
                        this.snackbar_text = "Password updated";
                        this.snackbar = true;
                        this.passwordDialog = false;
                    })
                    .catch(err => {
                        this.snackbar_text = "Error while updating";
                        this.snackbar = true;
                        this.saving = false;
                    });
            }

        },


        launchAvatarPicker() {
            this.$refs.avatarpic.click();
        },

        loadAvatar(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.avatarFile = e.target.result;
                    this.avatarDialog = true;

                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        saveAvatar() {
            this.avatarSaving = true;
            const {
                canvas
            } = this.$refs.acropper.getResult();
            if (canvas) {

                canvas.toBlob((blob) => {

                    let formData = new FormData();

                    formData.append('avatar', blob);

                    this.$store.dispatch('addUserAvatar', formData)
                        .then((resp) => {
                            this.$store.dispatch('profile').then(() => {
                                this.avatarDialog = false;
                                this.avatarSaving = false;
                                this.snackbar_text = "Photo updated";
                                this.snackbar = true;
                            });
                        })
                        .catch(err => {
                            this.snackbar_text = "Error while updating";
                            this.snackbar = true;
                            this.avatarSaving = false;
                        });

                }, "image/jpeg");

            }
        },

        deleteAccount() {

            this.deleting = true;
            let deleteReason = this.deleteReason
            let otherReason = this.otherReason
            let matchSource = this.matchSource
            let otherSource = this.otherSource
            let willRecommend = this.willRecommend
            let willRecommendReason = this.willRecommendReason
            let feedback = this.feedback

            this.$store.dispatch('deleteAccount', {
                deleteReason,
                otherReason,
                matchSource,
                otherSource,
                willRecommend,
                willRecommendReason,
                feedback
            })
                .then(() => {
                    this.deleting = false;
                    this.snackbar_text = "Account Suspended";
                    this.snackbar = true;
                    this.deleteAccountDialog = false;
                    this.$router.replace('/login');
                })
                .catch(err => {
                    this.snackbar_text = "Error while updating";
                    this.snackbar = true;
                    this.deleting = false;
                });

        },

        clearLocation() {

            //console.log('clear location');
            this.location = {
                description: '',
                placeId: '',
                state: '',
                country: '',
                zip: '',
                locality: '',
            };
            this.cordinates = null;
            this.searchResults = [];
            this.locationPicked = false;

            //this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        },

        /* MapsInit() {
        console.log('Initializing map');
        this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        this.service = new window.google.maps.places.AutocompleteService();
        this.geocoder = new window.google.maps.Geocoder();
        }, */
        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = []
                return
            }
            this.searchResults = predictions.map(prediction => ({
                description: prediction.description,
                placeId: prediction.place_id
            }))
        },

        getPlaceDetails() {
            if (this.location && this.location.placeId != '') {
                //console.log(this.location.placeId);
                this.geocoder.geocode({
                    placeId: this.location.placeId
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.cordinates = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        };

                        this.locationPicked = true;
                        //console.log(this.cordinates);
                        //this.closeFieldDialog();

                    }
                });
            }
        },

        async getCurrentLocation() {
            this.gettingLocation = true;
            try {

                let location = await this.getLocation();

                //console.log(location);
                this.cordinates = {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude
                };

                this.geocoder.geocode({
                    location: this.cordinates
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        //console.log(results[0]);
                        this.gettingLocation = false;

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.location.description = results[0].formatted_address;
                        this.location.placeId = results[0].place_id;

                        this.searchResults = [{
                            description: results[0].formatted_address,
                            placeId: results[0].place_id
                        }];

                        this.locationPicked = true;

                        //console.log(this.searchResults);

                        //this.closeFieldDialog();

                    }
                });

            } catch (e) {
                this.gettingLocation = false;
                this.errorStr = e.message;
            }

        },

        async getLocation() {

            return new Promise((resolve, reject) => {

                if (!("geolocation" in navigator)) {
                    reject(new Error('Geolocation is not available.'));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                    resolve(pos);
                }, err => {
                    reject(err);
                });

            });
        },

        setUserLocation() {

            this.locationSaving = true;

            let formData = new FormData();


            formData.append('location', JSON.stringify(this.location));
            formData.append('cordinates', JSON.stringify(this.cordinates));



            this.$store.dispatch('setUserLocation', formData)
                .then((resp) => {

                    this.locationSaving = false;
                    this.locationDialog = false;
                    this.snackbar_text = "Location Saved";
                    this.snackbar = true;

                })
                .catch(err => {

                    this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
                    this.snackbar = true;
                    this.locationSaving = false;
                });

        },

    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>