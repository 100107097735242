<template>
    <video ref="videoPlayer" class="video-js"></video>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css'

export default {
  name: 'VideoPlayer',
  props: {
    source: String,
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    this.options.sources = [
      {
        src:this.source,
        type: 'application/x-mpegURL'
      }
    ];
    let player = videojs(this.$refs.videoPlayer, this.options);
    player.on('error', function(e) {
        var errordivs = document.querySelectorAll('.vjs-modal-dialog-description');

        errordivs.forEach(ele => {
          ele.nextSibling.innerText = 'Processing Video, Please check after sometime!!!'
        });

          let errorTxts = document.querySelectorAll('.vjs-error .vjs-error-display .vjs-modal-dialog-content')
          var styleElem = document.head.appendChild(document.createElement("style"));
          styleElem.innerHTML = ".vjs-error .vjs-error-display:before {content: '';}";
          errorTxts.forEach(ele => {
              ele.style.paddingTop = '20%';
          });
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
<style>
.video-js {
  width: 100%;
  height: 100%;
}
</style>