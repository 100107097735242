<template>
  <div>
  <v-dialog v-model="this.$store.getters.contactDialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600" scrollable>
        <v-card>

            <v-toolbar flat dense max-height="48">
                <v-toolbar-title class="text-subtitle-1">Contact Support</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="pt-12">

                <v-form ref="contactform" v-model="valid" lazy-validation>

                    <!-- <v-row>
                        <v-col cols="12" class="px-4 py-0">
                            <span class="font-weight-medium-custom dark-text">Name</span>
                            <v-text-field class="mt-1" outlined dense v-model="name" :rules="nameRules" placeholder="Your Name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="px-4 py-0">
                            <span class="font-weight-medium-custom dark-text">Email</span>
                            <v-text-field class="mt-1" outlined dense v-model="email" :rules="emailRules" placeholder="Your Email address"></v-text-field>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="12" class="px-4 py-0">
                            <span class="font-weight-medium-custom dark-text">Subject</span>
                            <v-text-field class="mt-1" outlined dense v-model="subject" :rules="subjectRules" placeholder="Query regarding..."></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="px-4 py-0">
                            <span class="font-weight-medium-custom dark-text">Query</span>
                            <v-textarea class="mt-2" auto-grow outlined dense v-model="query" :rules="queryRules"></v-textarea>
                        </v-col>
                    </v-row>

                </v-form>

            </v-card-text>
            <v-card-actions class="px-4 py-4 grey lighten-5">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="submitQuery" :disabled="submitting" :loading="submitting" width=120>Submit</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
  </div>

 

</template>


<script>

  export default {
    name: 'Contact',
/*     mounted() {
console.log(this.$store.getters.userDetails);
        this.name = this.$store.getters.userDetails.name;
        this.email = this.$store.getters.userDetails.email;
    }, */
    data: () => ({

        contactDialog: false,
       // name: '',
       // email: '',
        subject: '',
        query: '',
        submitting: false,

        valid: true,
        snackbar: false,
        snackbar_text: '',

        /* nameRules: [
            v => !!v || 'Name is required',
        ], */

        subjectRules: [
            v => !!v || 'Subject is required',
        ],

        /* emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+/.test(v) || 'Email must be valid',
        ],
 */
        queryRules: [
            v => !!v || 'Query is required',
        ],

    }),

     methods: {

        close() {
            this.$store.dispatch('showContactDialog', false);
        },



        submitQuery() {

            if (this.$refs.contactform.validate()) {

                this.submitting = true;

                let email = this.$store.getters.userDetails.email
                let name = this.$store.getters.userDetails.name
                let subject = this.subject
                let query = this.query

                this.$store.dispatch('contactSupport', {
                        email,
                        name,
                        subject,
                        query
                    })
                    .then((resp) => {
                        this.submitting = false;
                        this.subject = '';
                        this.query = '';
                        this.snackbar_text = "Query submitted";
                        this.snackbar = true;
                        this.close();
                    })
                    .catch(err => {

                        this.snackbar_text = 'Error while submitting';
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }
        },


    },
    
  }
</script>
