<template>
    <div>
        <v-dialog v-model="this.$store.getters.categoryPop" persistent scrollable max-width="520" :retain-focus="false">

            <v-card class="pb-2">
                <v-card-text class="pt-6 pb-4">

                    <div class="text-left mb-4">

                        <div class="text-subtitle-1 black--text  mb-6">Choose Your Petmeetly Journey</div>
                    </div>


                    <v-tabs vertical >
                        <v-tab class="left-aligned-tab" @click="selectedCategory='mate-seeker'">
                            <v-icon small left class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">mdi-heart</v-icon>
                            Mate Seeker
                        </v-tab>
                        <v-tab class="left-aligned-tab" @click="selectedCategory='adopter'">
                            <v-icon small left class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">mdi-account-heart</v-icon>
                            Adopter
                        </v-tab>
                        <v-tab class="left-aligned-tab" @click="selectedCategory='buyer'">
                            <v-icon small left class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">mdi-cart</v-icon>
                            Buyer
                        </v-tab>
                        <v-tab class="left-aligned-tab" @click="selectedCategory='breeder'">
                            <v-icon small left class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">mdi-paw</v-icon>
                            Breeder
                        </v-tab>
                        <v-tab class="left-aligned-tab" @click="selectedCategory='shelter'">
                            <v-icon small left class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">mdi-home-heart</v-icon>
                            Shelter
                        </v-tab>
                        <v-tab class="left-aligned-tab" @click="selectedCategory='individual'">
                            <v-icon small left class="mr-2" v-if="!$vuetify.breakpoint.smAndDown">mdi-account</v-icon>
                            Seller
                        </v-tab>

                        <v-tab-item>
                            <v-card flat color="#f6f6f6" class="rounded-lg">
                                <v-card-text>
                                    <p class="text-caption">
                                        If you are looking for a breeding partner for your pet. You can connect with others who have pets ready for mating.
                                    </p>
                                    <p class="text-caption">
                                        You will need to add your pet's profile to find suitable matches
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat color="#f6f6f6" class="rounded-lg">
                                <v-card-text>
                                    <p class="text-caption">
                                        If you are looking to welcome a pet into your home, consider adopting from a shelter, rescue, or someone rehoming their pet. 
                                    </p>
                                    <p class="text-caption">
                                        You can specify your preferences, such as type, breed, age, and gender, to connect with caretakers who have pets that match your needs. 
                                    </p>
                                    <v-alert  text  type="error" class="text-caption" icon="mdi-shield-alert" v-if="this.$store.getters.location == 'IN'">
                                        <p>Pet sales and adoption services are currently unavailable in India due to regulatory restrictions.</p>
                                    </v-alert>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat color="#f6f6f6" class="rounded-lg">
                                <v-card-text>
                                    <p class="text-caption">
                                        If you are interested in purchasing a pet, you can connect with breeders or individuals who are selling pets.
                                    </p>
                                    <p class="text-caption">
                                        You have the option to mention your preferences regarding the type, breed, age, and gender of the pet. This helps you find exactly the pet you’re looking for from a trusted source.
                                    </p>
                                    <v-alert  text  type="error" class="text-caption" icon="mdi-shield-alert" v-if="this.$store.getters.location == 'IN'">
                                        <p>Pet sales and adoption services are currently unavailable in India due to regulatory restrictions.</p>
                                    </v-alert>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat color="#f6f6f6" class="rounded-lg">
                                <v-card-text>
                                    <p class="text-caption">
                                        If you are a responsible breeder, you can find loving homes for the puppies or kittens you raise. 
                                    </p>
                                    <p class="text-caption">
                                        You may also offer stud services or find a breeding mate for your pets.
                                    </p>
                                    <v-alert  text  type="error" class="text-caption" icon="mdi-shield-alert" v-if="this.$store.getters.location == 'IN'">
                                        <p>Pet sales and adoption services are currently unavailable in India due to regulatory restrictions.</p>
                                    </v-alert>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat color="#f6f6f6" class="rounded-lg">
                                <v-card-text>
                                    <p class="text-caption">
                                        If you are part of an organization dedicated to rescuing and rehoming animals, this category will help you connect with potential adopters. Your goal is to find safe, loving homes for animals in need.
                                    </p>
                                    <v-alert  text  type="error" class="text-caption" icon="mdi-shield-alert" v-if="this.$store.getters.location == 'IN'">
                                        <p>Pet sales and adoption services are currently unavailable in India due to regulatory restrictions.</p>
                                    </v-alert>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat color="#f6f6f6" class="rounded-lg">
                                <v-card-text>
                                    <p class="text-caption">
                                        If you are looking to rehome your pet, you can find a new family who will care for and love them. This is for anyone who needs to find a better situation for their pet.
                                    </p>
                                    <v-alert  text  type="error" class="text-caption" icon="mdi-shield-alert" v-if="this.$store.getters.location == 'IN'">
                                        <p>Pet sales and adoption services are currently unavailable in India due to regulatory restrictions.</p>
                                    </v-alert>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>


                    <!-- <v-item-group v-model="selectedCategory">
                        <v-row>
                            <v-col cols="6" class="d-flex align-start justify-center"
                                v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="adopter">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Adopter
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking to adopt a pet from a shelter, rescue, or individual.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-start justify-center"
                                v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="buyer">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Buyer
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking to purchase a pet from a breeder or individual.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-start justify-center">
                                <v-item v-slot="{ active, toggle }" value="mate-seeker">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Mate Seeker
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking for a mate for my pet for breeding purposes.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-start justify-center"
                                v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="breeder">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Breeder
                                        </div>
                                        <div class="text-left text-caption">
                                            I responsibly breed pets and am looking for loving homes for my offspring.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-start justify-center"
                                v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="shelter">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Shelter
                                        </div>
                                        <div class="text-left text-caption">
                                            We are an organization dedicated to rescuing and rehoming animals in need.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-start justify-center"
                                v-if="this.$store.getters.location != 'IN'">
                                <v-item v-slot="{ active, toggle }" value="individual">

                                    <v-sheet @click="toggle" class="pa-3 rounded-lg mild-shadow clickable"
                                        :color="active ? 'primary' : ''" :dark="active ? true : false">

                                        <div class="text-left text-subtitle-2 font-weight-bold mb-2">
                                            Individual
                                        </div>
                                        <div class="text-left text-caption">
                                            I'm looking to rehome my pet.
                                        </div>
                                    </v-sheet>

                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group> -->

                </v-card-text>

                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="pl-8" @click="categoryConfirm = true" :disabled="(this.selectedCategory == '' || this.selectedCategory == null || (this.$store.getters.location == 'IN' && this.selectedCategory != 'mate-seeker'))" :loading="saving">CONTINUE <v-icon
                            right>mdi-chevron-right</v-icon></v-btn>

                </v-card-actions>

            </v-card>

        </v-dialog>

        <v-dialog v-model="categoryConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p v-show="selectedCategory == 'mate-seeker'">
                    Are you sure you want to continue as a <strong class="black--text">"Mate Seeker"</strong>? <br/><br/>This will allow you to find a suitable breeding partner for your pet by adding their profile.
                </p>
                <p v-show="selectedCategory == 'adopter'">
                    Are you sure you want to continue as an <strong class="black--text">"Adopter"</strong>? <br/><br/>This will enable you to search for pets to adopt and provide a new home.
                </p>
                <p v-show="selectedCategory == 'buyer'">
                    Are you sure you want to continue as a <strong class="black--text">"Buyer"</strong>? <br/><br/>This will allow you to look for pets to purchase and add to your family.
                </p>
                <p v-show="selectedCategory == 'breeder'">
                    Are you sure you want to continue as a <strong class="black--text">"Breeder"</strong>? <br/><br/>This will enable you to list your pets for breeding purposes and for sale.
                </p>
                <p v-show="selectedCategory == 'shelter'">
                    Are you sure you want to continue as a <strong class="black--text">"Shelter"</strong>? <br/><br/>This will allow you to list pets available for adoption from your organization.
                </p>
                <p v-show="selectedCategory == 'individual'">
                    Are you sure you want to continue as an <strong class="black--text">"Individual Seller"</strong>? <br/><br/>This will enable you to list pets for sale from your personal care.
                </p>

                <p class="text-caption">
                    Note: You can contact support if you’d like to change your purpose later.
                </p>
            </v-card-text>

            <v-card-actions>
                <v-btn  depressed   @click="categoryConfirm = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveCategory" :loading="saving" :disabled="saving">Yes, Proceed</v-btn>
                </v-card-actions>

        </v-card>
    </v-dialog>



        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>

export default {

    mounted() {

    },
    data() {
        return {

            selectedCategory: 'mate-seeker',
            saving: false,
            categoryConfirm: false,

            snackbar: false,
            snackbar_text: '',
        }
    },



    methods: {



        saveCategory() {

if (this.selectedCategory == '' || this.selectedCategory == null) {
    this.snackbar_text = "Please select a category";
    this.snackbar = true;
}
else {

    let category = this.selectedCategory;
    this.saving = true;

    this.$store.dispatch('setUserCategory', { category })
        .then((resp) => {

            this.saving = false;
            
            if (category === 'adopter' || category === 'buyer') {
                //this.$router.replace('adopter-questionnaire');
                this.$store.dispatch('triggerAdopterQuestionnaire', true);
                console.log('trigger');
            }
            else if (category === 'breeder') {
              this.$store.dispatch('triggerBreederQuestionnaire', true);
            }
            else if (category === 'shelter') {
              this.$store.dispatch('triggerShelterQuestionnaire', true);
            }

            this.$store.dispatch('showCategoryPop', false);
            this.categoryConfirm = false;

        })
        .catch(err => {

            this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
            this.snackbar = true;
            this.saving = false;
        });

}


}


        




    }
}
</script>