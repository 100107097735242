<template>
    <div>
        <v-dialog v-model="this.$store.getters.directConnectPop" persistent scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="450" :retain-focus="false">
    
            <v-card>
    
                <v-toolbar flat dark color="primary" max-height="56">
    
                    <v-btn icon @click.native="close">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="text-subtitle-2">Direct Connects</v-toolbar-title>
    
                    
                </v-toolbar>
    
                
    
                <v-card-text class="px-0 pb-8">
    
                    
                    <div  class="ma-6 text-center"> 
                        <p class="text-subtitl-2 font-weight-medium primary--text  mb-0" >You have <strong>{{this.$store.getters.userDetails.dconnects}}</strong> Direct Connect credits!</p>    
                    </div>
                    
    
                    <v-alert  text color="success" icon="mdi-plus-circle"   class="ma-4"> 
                        <p class="text-subtitle-2 mb-2" style="font-weight:600">Need More Connects?</p>
                        <p v-if="this.$store.getters.userDetails.country == 'IN'" class="text-caption ">Buy 3 Direct Connect credits for <span class="font-weight-bold text-subtitle-2">&#8377; 299.00</span></p>
                        <p v-else class="text-caption ">Buy 3 Direct Connect credits for <span class="font-weight-bold text-subtitle-2">$9.99</span></p>
                        <div class="d-flex justify-end">
                            <v-btn color="success" small @click="buyConnectConfirmDialog = true" :disabled="this.$store.getters.isPremium ? false : true">Buy Now</v-btn></div>
                </v-alert>
    
    
    
                    <v-row no-gutters>
                        <v-col cols="12" class="pa-4 pt-0">
    
                            <div class="text-caption pt-0 font-italic">* Direct connects can be purchased only as an add-on to our <a @click="showPremium" class="text-decoration-underline primary--text font-weight-medium">Premium Membership plans</a>.</div>
    
    
                            <div class="text-subtitle-2 black--text pt-6">What is a direct connect?</div>
                            <div class="text-caption">
                                <p> It's an exclusive feature that allows you to directly access the contact details of a member, letting you call or email them.</p>
                            </div>
    
                            <div class="text-subtitle-2  black--text ">When Should You Use It?</div>
                            <div class="text-caption">
                                <p>Consider it as your next step when:
                                <ul>
                                    <li>You've found a perfect match on the platform.</li>
                                    <li>But, you're not getting a response through messages or you want a faster way to get in touch</li>
                                </ul>
                            </p>
                            </div>
    
                            <div class="text-subtitle-2  black--text ">How to Use Direct Connect?</div>
                            <div class="text-caption"><p>Just follow these simple steps:
                                <ol>
                                    <li>Browse to the profile of the member you're interested in.</li>
                                    <li>Look for the "call" button. Click on it.</li>
                                    <li>You'll be prompted to use your Direct Connect credit. Accept, and you'll access their contact details.</li>
                                </ol>
                            </p>
                            </div>

                            <div class="text-subtitle-2  black--text ">How to Get a Direct Connect</div>
                            <div class="text-caption">
                                <p> Direct connects are available with a Premium membership, and you can purchase additional connects if you are a Premium member.</p>
                                <p class="font-italic"> Additionally, we may offer direct connects as part of various promotions. You may send a support request (from the main menu) with the subject <strong>“Free Direct Connect”</strong> to check if there are any current promotions that could reward you with free direct connects.</p>
                            </div>
                            
                         
    
                        </v-col>
                    </v-row>
    
                    
    
                    <v-card class="ma-4 mild-shadow">
                       
    
                    
                    <v-toolbar flat color="primary" dense>
                        <v-toolbar-title class="text-caption white--text font-weight-medium">My Direct Connect Usage</v-toolbar-title>
                       
                    </v-toolbar>
    
                    <v-card-text>
                    <v-row no-gutters v-if="this.$store.getters.userDetails.connects.length == 0">
                        <v-col cols="12" class="pa-12 text-center">
                            <v-icon size="120" color="#eee">mdi-link-variant-off</v-icon>
                            <div>No Direct Connect Usage</div>
                        </v-col>
                    </v-row>
    
                    <v-row class="px-4" v-for="(item,index) in this.$store.getters.userDetails.connects" :key="index" no-gutters align="center" style="border-bottom:1px solid #eee">
                        <v-col cols="3">
                            <v-icon x-small color="green"  v-if="item.action == 'Credit'">
                                mdi-plus
                            </v-icon>
                            <v-icon x-small color="red lighten-1"  v-else >
                                mdi-minus
                            </v-icon>
                            {{Math.abs(item.connects)}}
                        </v-col>
                        <v-col cols="9" class="text-caption py-2">
                            <div>{{item.created_at||new Date() | moment("D MMM, h:mm a")}}</div>
                            {{item.note}}
                        </v-col>
                    </v-row>
                </v-card-text>
    
                    </v-card>
                   
    
                </v-card-text>
                
    
            </v-card>
    
        </v-dialog>


        <v-dialog v-model="buyConnectConfirmDialog" max-width="400">
        <v-card>
            <v-toolbar flat>

                <v-toolbar-title class="text-subtitle-1">Purchase Direct Connects</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="buyConnectConfirmDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

            </v-toolbar>

            <v-card-text class="pt-4">
                <p>"Direct Connects" enable you to access the contact details of the pet owners so that you can call or message them directly. </p>
                <v-divider class="mb-4"></v-divider>
                <p class="text-caption mb-0">Note: You won't be prompted for bank/card information. You will be charged on the same account used for the Premium Membership</p>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="buyConnects" :loading="processing" :disabled="processing">Confirm Order</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    
        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    
    </div>
    </template>
    
    <script>
    import axios from "axios";
    export default {
        name: 'DirectConnectStripe',
    
        mounted() {
            
        },
    
        data() {
            return {
                buyConnectConfirmDialog: false,
                myConnects: false,
                processing: false,
                snackbar: false,
                snackbar_text: '',
    
                
    
            }
        },
    
        methods: {
            close() {
                this.$store.dispatch('showDirectConnectPop', false);
                if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("Cancel Button Clicked");
            }
            },
            showPremium() {
                this.close();
                this.$store.dispatch('showPremiumPopStripe', true);
            },
    
    
            buyConnects() {
                this.processing = true;
    
                window.gtag('event', 'purchase_init_stripe', {
                    event_category: 'Purchase',
                    event_label: 'Direct Connect',
                });
    
                axios.post('/v1/connects/buy').then((resp) => {
    
                    if (resp.data.purchase_done) {
                        this.$store.dispatch('profile').then(() => {
                            this.processing = false;
                            this.snackbar_text = "Connects Purchased";
                            this.snackbar = true;
                            this.buyConnectConfirmDialog = false;
                        });
    
                        window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});
    
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-10848052375/NZuMCN2UhqYDEJfJ4LQo',
                            'value': 9.99,
                            'currency': 'USD',
                            'transaction_id': ''
                        });
    
                        window.gtag('event', 'purchase_done_stripe', {
                            event_category: 'Purchase',
                            event_label: 'Direct Connect',
                            value: 9.99
                        });
    
                        window.fbq('track', 'Purchase', {currency: "USD", value: 9.99});
    
                    } else {
    
                        this.snackbar_text = "Error while processing payment";
                        this.snackbar = true;
                        this.processing = false;
    
                    }
    
                }).catch(err => {
                    //console.log(err.response);
                    //Bugsnag.notify(err)
                    this.processing = false;
                });
            },
    
    
        }
    }
    </script>
    