<template>
  <v-dialog v-model="show"  max-width="450" transition="dialog-transition" :retain-focus="false" persistent>

    <v-card class="petDnaReportVerificationPop">

      <v-toolbar flat color="transparent" max-height="56">

<v-spacer></v-spacer>

<v-btn icon @click.stop="show=false">
    <v-icon>mdi-close</v-icon>
</v-btn>

</v-toolbar>
<v-card-text>

      <v-row no-gutters>
      
        <v-col cols="12">
          <div class="text-subtitle-1 font-weight-bold pb-4 black--text">DNA Report Verification</div>
        </v-col>

        <div class="text-caption">
          <p>To showcase the <strong class="secondary--text">'DNA Tested'</strong> tag on your pet's profile, please upload a copy of the DNA report.</p>
          <p>This confirmation adds credibility to your pet's information on our platform. If you choose not to share this document or want to remove this requirement, please edit your pet's profile and mark 'No' for the 'DNA Tested' field.</p>
          <p>Please note that this document will remain private, and its purpose is solely for verification. Rest assured, it will be removed concurrently with your account, should you decide to discontinue our services.
        </p>
        </div>


        
        <v-col cols="12"  v-if="!pet.dna_verified">
          <div class="pa-1 primary--text text-subtitle-2 font-weight-medium pb-4">DNA Report</div>

          <v-row>
          <v-col cols="6" align="center" v-if="petDnaReport">
            <v-img :src="petDnaReport.cloud_url" cover style="border-radius:5px" aspect-ratio="1" v-if="petDnaReport.cloud_url">
              <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deletePic(petDnaReport.xid)">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-img>
          </v-col>

          <v-col cols="6" align="center" v-if="preview && !petDnaReport">
            <v-img :src="preview" cover style="border-radius:5px" aspect-ratio="1">
              <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deletePic()">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-img>
          </v-col>

          <v-col cols="6" class="pa-4" align="center" v-if="!petDnaReport && !preview">
            <v-card flat style="border-radius:5px; border: 2px dashed #adb5bd;" @click="launchDnaReportPicker" class="pt-2">
              <v-icon class="pa-8" v-if="!submitting" color="primary" size="36">mdi-image-plus</v-icon>
              <v-progress-circular v-if="submitting" class="center ma-8 pl-n5" color="primary" size="36" indeterminate></v-progress-circular>
            </v-card>
          </v-col>
          </v-row>
          <input type="file" ref="dnaReport" name="dnaReport" @change="loadDnaReport($event)" style="display:none" accept="image/png, image/jpg, image/jpeg">
          <canvas class="col-6" id="dnaCanvas"></canvas>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
        <v-spacer></v-spacer>
            <v-btn color="primary" :loading="this.submitting" @click="submit">Submit</v-btn>
        </v-card-actions>
    </v-card>


    <v-bottom-sheet v-model="dnaDeleteConfirm">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="dark darken-2" dark>
        <div class="grey--text text--lighten-1 text-body-2 mb-4">
          Are you sure you want to delete this dna report?
        </div>

        <v-btn :disabled="dnaDeleting" class="ma-1 pr-16" color="grey" plain @click="dnaDeleteConfirm=false">
          Cancel
        </v-btn>

        <v-btn :loading="dnaDeleting" class="ma-1 pl-16" color="red" plain @click="deleteDnaReport">
          Delete
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <v-snackbar v-model="snackbar" bottom>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-dialog>

</template>

<script>

export default {
  name: 'PetDnaReportVerification',
  props: ['value', 'pet'],
  mounted() {
    this.petDnaReport = this.getDnaReport(this.pet);
  },
  data() {
    return {
      petProfileVerification: null,
      petDnaReport: null,
      uploadedFile: null,

      dnaDeleteConfirm: false,
      dnaDeleting: false,
      dnaToDelete: null,
      dnaFile: null,
      dnaDialog: false,
      preview: null,

      submitting: false,
      snackbar: false,
      snackbar_text: '',
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {

      launchDnaReportPicker() {
        this.$refs.dnaReport.value = '';
        this.$refs.dnaReport.click();
      },

      loadDnaReport(event) {
          var input = event.target;
          if (input.files && input.files[0]) {
              this.uploadedFile=input.files[0];

              var reader = new FileReader();

              reader.onload = (e) => {
                this.dnaFile = e.target.result;
                this.dnaDialog = true;

                this.preview = e.target.result;
              };
              reader.readAsDataURL(input.files[0]);
          }
      },

      saveDnaReport() {
          this.submitting = true;

          let formData = new FormData();

          formData.append('id', this.pet.xid);
          formData.append('dna_report', this.uploadedFile);

          this.$store.dispatch('addDnaReport', formData)
              .then((resp) => {
                  this.$store.dispatch('getPetDetails', this.pet.xid).then((resp) => {
                    this.petDnaReport = this.getDnaReport(resp.data)
                    this.submitting = false;
                    this.show=false;
                    this.$emit('force-pet-rerender');
                  });
              })
              .catch(err => {
                  this.snackbar_text = "Error while updating";
                  this.snackbar = true;
                  this.submitting = false;
              });
      },

      getDnaReport(pet) {
          let petProfileVerification = pet.pet_profile_verification;
          let petDnaReport = petProfileVerification ? petProfileVerification.filter(item => {
            return item.type == 'dna'
          }) : null;
          petDnaReport = petDnaReport ? petDnaReport[0] : null;
          return petDnaReport;
      },

      deletePic(xid) {
        if(!xid)
        {
          this.dnaFile= null;
          this.preview = null;
        }
        else
        {
          this.dnaToDelete = xid
          this.dnaDeleteConfirm = true;
        }
      },

      deleteDnaReport() {
          this.dnaDeleting = true;

          this.$store.dispatch('deleteDnaReport', this.dnaToDelete)
              .then((resp) => {
                  this.$store.dispatch('getPetDetails', this.pet.xid).then((resp) => {
                      this.petDnaReport = this.getDnaReport(resp.data)
                      this.dnaDeleting = false;
                      this.dnaDeleteConfirm = false;
                      this.dnaFile= null;
                      this.preview = null;
                      this.$emit('force-pet-rerender');
                  });
              })
              .catch(err => {
              });
      },
      submit()
      {
          this.saveDnaReport();
      }
  }
}
</script>
