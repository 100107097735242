<template>
  <div>
      <v-app-bar app flat color="primary" dark prominent>

          <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
              <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-btn icon @click="$router.go(-1)" v-else>
              <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title class="text-h5 font-weight-light" style="letter-spacing:1px !important">Blocked Members</v-toolbar-title>

          <v-spacer></v-spacer>

          <template v-slot:img>
            <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">mdi-cancel</v-icon>
          </template>

      </v-app-bar>

      <v-row no-gutters v-if="this.$store.getters.userDetails.myBlocked.length == 0">
          <v-col cols="12" class="pa-12 text-center" >
              <v-icon size="120" color="#eee">mdi-cancel</v-icon>
              <div>No Records</div>
          </v-col>
      </v-row>

      <v-row no-gutters>
          <v-col class="col-12" md="12">
              <v-list two-line>
                  <v-list-item v-for="(item, index) in this.$store.getters.userDetails.myBlocked" :key="index"
                       style="border-bottom:1px solid #eee">
                      <v-list-item-avatar>
                          <v-img v-if="item.photo" :src="item.photo"></v-img>
                          <v-icon color="#7E0DE7" v-else size="36">$vuetify.icons.account-icon</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                            <v-btn small color="success" depressed dark @click="blockedUser=item;unblockDialog=true">Unblock</v-btn>
                        </v-list-item-action>
                  </v-list-item>
              </v-list>
          </v-col>
      </v-row>

      <v-dialog v-model="unblockDialog" max-width="400">
          <v-card>
            <v-card-title class="text-subtitle-1">Unblock Member</v-card-title>
            <v-card-text>
              <p >
                Are you sure you want to unblock {{this.blockedUser.name}}?
              </p>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="grey" @click="unblockDialog = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="unblockUser">Unblock</v-btn>
            </v-card-actions>

          </v-card>
      </v-dialog>
  </div>
</template>

<script>


export default {
  name: 'BlockedUsers',

  data() {
      return {
          blockedUser: {
              name: ''
          },
          unblockDialog: false,
      }
  },
  methods: {
      unblockUser() {
        let xid = this.blockedUser.buid;
        this.$store.dispatch('unblockUser', {
          xid
        })
            .then((resp) => {
              this.$store.dispatch('profile');
              this.overlay = false;
              this.snackbar_text = "Unblocked successfully";
              this.snackbar = true;
              this.unblockDialog = false;
            })
            .catch(err => {
              this.connecting = false;
              this.snackbar_text = "Error submitting";
              this.snackbar = true;
            });
      }
  }
}
</script>
