import Vue from 'vue'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'

Vue.use(VueCookie)


export default {
    state: {
        token: Vue.cookie.get("pm_token") || "",
        user: {
            name: "",
            is_premium: false,
            has_billing: false,
            dconnects:0,
            msg_limit:0,
            mcredits:0,
            connects: [],
            message_credits: [],
            photo: '',
            pets: [
                {
                    name:''
                }
            ],
            preferences: null
        },
    },

    getters: {
        isLoggedIn: state => !!state.token,
        userDetails: state => state.user,
        isPremium: state=> state.user.is_premium,
        billingDetails: state =>
        {
            let data = {}
            
            if(state.user.pets[0] && state.user.pets[0].name != '')
            {
                data = state.user.pets[0]
            }
            else
            {
                data = state.user.preferences
            }

            if(data)
            {
                return  {
                    name: state.user.name,
                    email: state.user.email,
                    phone: state.user.phone,
                    address: {
                        line1: data.location_description,
                        line2:'',
                        city: data.locality,
                        state: data.state,
                        country: data.country,
                        postal_code: data.zip
                    }
                }

            }
            else
            {
                return  {
                    name: state.user.name,
                    email: state.user.email,
                    phone: state.user.phone,
                    address: {
                        line1: state.user.name,
                    }
                }

            }

            
            
        }
    },

    mutations: {
        login(state, token) {
            state.token = token;
        },

        logout(state) {
            state.token = "";
            state.user = {
                name: '',
                is_premium: false,
                has_billing: false,
                dconnects:0,
                msg_limit:0,
                mcredits:0,
                connects: [],
                message_credits: [],
                photo: '',
                pets: [
                    {
                        name:''
                    }
                ],
                preferences: null
            };

        },

        profile(state, user) {
            state.user = user;
        },

        updatePet(state, payload)
        {
            let idx = state.user.pets.findIndex((pet => pet.xid === payload.xid));
            if(idx!==-1)
            {
                state.user.pets[idx] = payload;
            }
        },

        deletePet(state, payload)
        {
            let newpets = state.user.pets.filter(o => {
                return o.xid != payload;
            })
            state.user.pets = newpets;
        },

        updatePetPreferences(state, payload)
        {
            state.user.preferences = payload;
        },

        setMessageLimit(state, payload) {
            state.user.msg_limit = payload;
        },

    },
    actions: {

        login({ commit/* , dispatch */ }, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/login", credentials)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pm_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token);
                        /* dispatch('profile').then((resp) =>
                        {
                            resolve(resp);
                        }) */
                        resolve(resp.data);

                    })
                    .catch(err => {
                        Vue.cookie.delete("pm_token");
                        reject(err);
                    });
            });
        },

        signup({ commit },data) {
            
            return new Promise((resolve, reject) =>{
                //console.log(data)
                 axios
                    .post("/v1/signup", data)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pm_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token); 
                        resolve(resp.data);
                    })
                    .catch(err => {
                        //console.log(err)
                        Vue.cookie.delete("pm_token");
                        //Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        socialLogin({ commit },data) {
            
            return new Promise((resolve, reject) =>{
                //console.log(data)
                 axios
                    .post("/v1/social-login", data)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pm_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token); 
                        resolve(resp.data);
                    })
                    .catch(err => {
                        //console.log(err)
                        Vue.cookie.delete("pm_token");
                        //Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        androidLogin({ commit },data) {
    
            const token = data.token;
            Vue.cookie.set("pm_token", data.token, 30);
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + token;
            commit("login", token); 

        },


        checkPhoneExists(phone)
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/validate-phone/"+ phone)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        checkEmailExists({commit},email)
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/validate-email/"+ email)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },


        register({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/register", data)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pm_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token);
                        resolve();
                    })
                    .catch(err => {
                        Vue.cookie.delete("pm_token");
                        //Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/logout")
                    .then(resp => {
                        Vue.cookie.delete("pm_token");
                        delete axios.defaults.headers.common["Authorization"];
                        commit("logout");
                        commit('resetAppState', null, { root: true });
                        commit('resetPetState', null, { root: true });
                        resolve(resp);
                    })
                    .catch(err => {
                        Vue.cookie.delete("pm_token");
                        delete axios.defaults.headers.common["Authorization"];
                        //Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },


        profile({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/profile")
                    .then(resp => {
                        const user = resp.data;
                        commit("profile", user);
                        dispatch("getConnections");
                        dispatch("getNotifications");
                        if(user.country)
                        {
                            commit('setLocation', user.country, { root: true });
                            Vue.cookie.set("pm_location", user.country, 9999);
                        }
                        resolve(user);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },


        facebookLogin({ commit, dispatch }, fbProfile) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/login-fb", fbProfile)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pm_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token);
                        dispatch('profile').then((resp) =>
                        {
                            resolve(resp);
                        })

                    })
                    .catch(err => {
                        Vue.cookie.delete("pm_token");
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        setPetPreferences({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/preferences", data)
                    .then(resp => {
                        commit('updatePetPreferences', resp.data)
                        //commit('updatePetPreferences', resp.data, { root: true })
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deletePreferences({ commit, dispatch }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/preferences/"+xid)
                    .then(resp => {
                        dispatch('profile').then((resp) =>
                        {
                            resolve();
                        })
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deletePet({ commit, dispatch }, xid) {
            return new Promise((resolve, reject) => {
                axios
                .delete("/v1/pet/"+xid)
                    .then(resp => {
                        commit("deletePet", xid);
                        //dispatch('profile').then((resp) =>
                        //{
                            resolve();
                       // })
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getHostedBillingUrl()
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/billing-portal")
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },


        addUserAvatar({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/profile-avatar", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        dispatch('profile').then((resp) =>
                        {
                            resolve();
                        })
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        saveAccount({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/profile", data)
                    .then(resp => {
                        dispatch('profile').then((resp) =>
                        {
                            resolve();
                        })
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        checkProfileEmailExists({commit},email)
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/validate-profile-email/"+ email)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        checkProfilePhoneExists({commit},phone)
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/validate-profile-phone/"+ phone)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        verifyPhone({dispatch},data)
        {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/verify-phone", data)
                    .then(resp => {
                        dispatch('profile').then((resp) =>
                        {
                            resolve();
                        })
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        
        forgotPassEmailValidate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/forgot-password/email-validate", data)
                    .then(resp => {
                            resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        forgotPassCodeValidate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/forgot-password/code-validate", data)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pm_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token);
                        dispatch('profile').then((resp) =>
                        {
                            resolve(resp);
                        })
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        forgotPassResetPassword({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/forgot-password/reset-password", data)
                    .then(resp => {
                            resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        adopterFromId({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/adopter/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        sendEmailVerificationCode({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/verify-email/send-code", data)
                    .then(resp => {
                            resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        validateEmailVerificationCode({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/verify-email/verify-code", data)
                    .then(resp => {
                        let res = resp
                        dispatch('profile').then((resp) =>
                        {
                            resolve(res)
                        })
                            
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        sendPhoneVerificationCode({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/verify-phone/send-code", data)
                    .then(resp => {
                            resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        validatePhoneVerificationCode({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/verify-phone/verify-code", data)
                    .then(resp => {
                        let res = resp
                        dispatch('profile').then((resp) =>
                        {
                            resolve(res)
                        })
                            
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deleteAccount({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/delete-account", data)
                    .then(resp => {
                        let res = resp
                        dispatch('logout').then((resp) =>
                        {
                            resolve(res)
                        })
                            
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deleteAccountExt({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/delete-account-ext", data)
                    .then(resp => {
                        let res = resp
                        dispatch('logout').then((resp) =>
                        {
                            resolve(res)
                        })
                            
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        checkMessageLimit({commit})
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/message-limit")
                    .then(resp => {
                        commit("setMessageLimit", resp.data.limit);
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        setMessageLimit({ commit }, limit) {
            commit("setMessageLimit", limit);
        },

        updateNotification({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/notification-preference", data)
                    .then(resp => {
                        let res = resp
                        resolve(res)     
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        checkAccountActive({commit},xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/check-account-active/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        setUserLocation({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/set-location", data)
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        }); 
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        setUserCategory({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/set-category", data)
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        }); 
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getAdopterQuestionnaire({commit})
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/adopter-questionnaire")
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        submitAdopterQuestionnaire({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/adopter-questionnaire", data.formData)
                    .then(resp => {
                        if(data.formData.get('step') == 16)
                            {
                                dispatch("profile").then(resp => {
                                    resolve();
                                }); 
                            }
                            else
                            {
                                resolve();
                            }
                        
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addUserProfileVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/user-profile-video", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        }); 
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        canMessage({commit}, data)
        {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/can-message", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        getBreederQuestionnaire({commit})
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/breeder-questionnaire")
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        submitBreederQuestionnaire({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/breeder-questionnaire", data.formData)
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getShelterQuestionnaire({commit})
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/shelter-questionnaire")
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        submitShelterQuestionnaire({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/shelter-questionnaire", data.formData)
                    .then(resp => {
                        dispatch("profile").then(resp => {
                            resolve();
                        });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        validateOneWeekPremium({commit},xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/validate-week-premium/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        generateSignedUrl({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/generate-signed-url", data)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

    }
};

