<template>
 <div class="favourites pa-4">
   <v-app-bar app flat color="primary" dark prominent>

        <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="$router.go(-1)" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-h4 font-weight-light" style="letter-spacing:2px !important">Favourites</v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-slot:img>
        <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">mdi-heart</v-icon>
      </template>

        

    </v-app-bar>

    <div v-if="loading">
    <v-row  >
        <v-col cols="12" justify="center" align="center" class="my-12">
                    <img :src="$loaderImageUrl" height="100" alt="Loading..." />
                </v-col>
        <!-- <v-col cols="12" sm="6" md="4" lg="3" xl="2"  v-for="n in 6" :key="n" class="pa-2">
            <v-skeleton-loader

            type="image"></v-skeleton-loader>
        </v-col> -->
    </v-row>
    </div>
    <div v-else> 
     <v-row  v-if="this.$store.getters.favorites.length == 0">
        <v-col cols="12" class="pa-12 text-center" >
            <v-icon size="120" color="#eee">mdi-heart-broken</v-icon>
            <div>No Favorites</div>
        </v-col>
    </v-row>
    <v-row >
        <v-col cols="12" sm="6" md="4" lg="3" xl="2"   v-for="(item,index) in this.$store.getters.favorites" :key="index" class="pa-2">
            <pet-card :pet="item"></pet-card>
        </v-col>
    </v-row>
    </div>
    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
  </div>
</template>


<script>
import PetCard from './components/PetCard.vue'
  export default {
    name: 'Favourites',
    mounted() {
        if (this.$store.getters.userDetails.latitude == null || this.$store.getters.userDetails.latitude == '') {
            this.$router.replace('home');
            }

        this.getFavourites();
    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
        }
     },
    components: {
        PetCard,
    },
    methods: {

        getFavourites() {
            this.loading = true;
            this.$store.dispatch('getFavorites').then(() => {
                this.loading = false;
            })
            .catch(err => {
              //console.log(err)
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get your favourites. Please try again.";
                    this.snackbar = true;
            });
        }
    }
  }
</script>
