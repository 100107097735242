<template>
  <div class="home">
    <h1>MyPets</h1>
  </div>
</template>


<script>
  export default {
    name: 'MyPets',
  }
</script>
