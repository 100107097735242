<template>
  <div>
      <v-app-bar app flat color="primary" dark prominent>

          <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
              <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-btn icon @click="$router.go(-1)" v-else>
              <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title class="text-h4 font-weight-light"
              style="letter-spacing:2px !important">Settings</v-toolbar-title>

          <v-spacer></v-spacer>

          <template v-slot:img>
            <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">mdi-cog-outline</v-icon>
          </template>

          

      </v-app-bar>

      <v-row no-gutters justify="center">
          <v-col cols="12">

            <v-list
      subheader
    >
      <v-subheader>General</v-subheader>

      <v-list-item :to="'/account'">
        <v-list-item-avatar class="my-0">
          <v-icon>
            mdi-account-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon>
            <v-icon color="grey">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item :to="'/membership'">
        <v-list-item-avatar class="my-0">
          <v-icon>
            mdi-wallet-membership
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Membership</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon>
            <v-icon color="grey">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>


    <v-list-item :to="'/notifications'">
        <v-list-item-avatar class="my-0">
          <v-icon>
            mdi-bell-badge-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon>
            <v-icon color="grey">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      subheader

    >
      <v-subheader>Miscellaneous</v-subheader>

      <v-list-item :to="'/messages/archived'">
        <v-list-item-avatar class="my-0">
          <v-icon>
            mdi-archive-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Archived Messages</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon>
            <v-icon color="grey">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item :to="'/users/blocked'">
        <v-list-item-avatar class="my-0">
          <v-icon>
            mdi-account-cancel-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Blocked Members</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon>
            <v-icon color="grey">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

    </v-list>


    </v-col>
    </v-row>


  </div>
</template>

<script>


export default {
  name: 'Settings',

  data() {
      return {
          
      }
  },


  methods: {
      
  }
}
</script>
