<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
  

            <v-col cols="12" justify="center" align="center">
                <div>
                        <v-icon color="primary" size="80" class="pa-4">mdi-crown-outline</v-icon>
                    </div>
                    <div class="pa-8">
                        <span class="text-h6 d-block pb-4">Be a Premium Member</span>
                        <span>Try this alternate route to make a payment and unlock premium benefits.</span>
                    </div>
    
                    <div class="py-8">
    
                        <v-btn color="primary"  @click="$store.dispatch('showPremiumPopStripe', true)">
                            Subscribe Now
                        </v-btn>
                    </div>
            </v-col>
    
        </v-row>
    
     
    </v-container>
    </template>
    
    <script>
    import axios from "axios";
    import Bugsnag from '@bugsnag/js'
    export default {
        name: 'RetryPayment',
        data() {
            return {
             
            }
        },
        mounted() {
    
           
    
    
    
    
        },
        methods: {
            
    
        }
    }
    </script>
    