<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="6" class="ma-8 d-flex justify-center">
                        <v-img src='@/assets/logo/icon-192.png' max-width="100"></v-img>
                    </v-col>
                </v-row>

                <div v-if="val == 'y' && step == 0">


                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        That's great
                    </div>

                    <div class="text-center text-body-2 py-2" style="line-height:1.5rem" v-if="purpose == 'friend' || purpose == 'lover'">
                        Keeping our platform up-to-date is crucial for us, as it ensures that our community of genuine
                        users, such as yourself, do not spend valuable time reviewing profiles that have already found what
                        they're looking for.
                    </div>

                    <div class="text-center text-body-2 py-2" style="line-height:1.5rem" v-if="purpose == 'adopter' || purpose == 'buyer'">
                        As you have found a new home for your pet, would you like to remove the listing? 
                        This step is essential in ensuring our platform remains up-to-date and efficient, 
                        helping our community of genuine users avoid spending time on listings that have 
                        already been fulfilled.
                    </div>


                    <div class="text-center text-subtitle-2 font-weight-bold py-6 px-4">
                        Would you prefer unpublishing your pet's profile?
                    </div>

                    <v-row no-gutters justify="center" align="center">
                        <v-col cols="6" class="text-center">
                            <v-btn color="error" @click="unpublishPet(1)" :loading="submitting" :disabled="submitting">
                                Yes, Unpublish it
                            </v-btn>


                        </v-col>
                        <v-col cols="6" class="text-center">

                            <v-btn color="success" @click="unpublishPet(0)">
                                No, Keep it active
                            </v-btn>

                        </v-col>
                    </v-row>

                </div>

                <div v-if="unpublish == 0 && step == 1">
                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        Have a moment to share your thoughts?
                    </div>
                    <div class="text-center text-body-2 py-2" style="line-height:1.5rem">
                        Your experience with Petmeetly is important to us. Help us understand what we're doing right and
                        where we can improve.
                    </div>

                    <div class="text-center py-8 px-4">
                        <v-textarea autofocus outlined auto-grow row-height="30" rows="5"
                            placeholder="Enter your feedback here" v-model="feedback"></v-textarea>

                        <v-btn color="primary" @click="submitFeedback" :loading="submitting" :disabled="submitting">
                            Submit Feedback
                        </v-btn>
                    </div>
                </div>




                <div v-if="unpublish == 1 && step == 1">

                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        Have a moment to share your thoughts?
                    </div>

                    <div class="text-center text-body-2 py-2" style="line-height:1.5rem">
                        How was your recent experience with Petmeetly? How satisfied were you with our services?
                    </div>

                    <div class="text-center text-subtitle-2 font-weight-bold  py-6 px-4">
                        Please click the smiley that best represents your feelings.
                    </div>

                    <v-row justify="center" align="center">
                        <v-col cols="6" class="text-center">

                            <v-btn icon width="80" height="80" color="#34a853" @click="rate(5)">
                                <v-icon size="80">mdi-emoticon-happy-outline</v-icon>
                            </v-btn>



                        </v-col>
                        <v-col cols="6" class="text-center">

                            <v-btn icon width="80" height="80" color="#ee675e" @click="rate(1)">
                                <v-icon size="80">mdi-emoticon-sad-outline</v-icon>
                            </v-btn>



                        </v-col>
                    </v-row>
                </div>

                <div v-if="rating == 5 && step == 2">

                    <!--   <div class="text-center text-h4 pb-8 px-4">
                        <v-icon size="100" color="#34a853">mdi-emoticon-happy-outline</v-icon>
                    </div> -->

                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        Thank You
                    </div>

                    <div class="text-center pb-8 px-4" style="line-height:1.5rem">
                        Would you mind leaving us a review on Facebook? It would mean the world to us. Please share a few
                        words of support and encouragement.
                    </div>

                    
                   <v-divider></v-divider>

                <div class="px-4 pt-8">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-row no-gutters class="pb-4">
                                <v-col cols="2"><v-avatar color="success" size="28"><span class="white--text font-weight-bold">1</span></v-avatar></v-col>
                                <v-col cols="10"><span>It is easy. Just click the "Review us on Facebook" button</span></v-col>
                            </v-row>
                            <div class="text-center pb-8 px-4">
                        <v-btn dark color="indigo" href="https://www.facebook.com/petmeetly/reviews">
                            Review us on Facebook
                        </v-btn>
                    </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-row no-gutters class="pb-4">
                                <v-col cols="2"><v-avatar color="success" size="28"><span class="white--text font-weight-bold">2</span></v-avatar></v-col>
                                <v-col cols="10"><span>Choose "Yes" for the question "Do yo recommend Petmeetly?"</span></v-col>
                            </v-row>
                            <v-img src='@/assets/img/fb1.png'></v-img>
                        </v-col>
                        <v-col cols="12" sm="6" >
                            <v-row no-gutters >
                                <v-col cols="2"><v-avatar color="success" size="28"><span class="white--text font-weight-bold">3</span></v-avatar></v-col>
                                <v-col cols="10"><span>Please make sure to select "Public" while posting your review</span></v-col>
                            </v-row>
                            <div class="pb-4  text-caption"></div>
                            <v-img src='@/assets/img/fb2.png'></v-img>
                        </v-col>

                    </v-row>
                </div>

                <v-divider></v-divider>


                    <div class="text-center py-8 px-4">
                        <v-btn dark color="indigo" href="https://www.facebook.com/petmeetly/reviews">
                            Review us on Facebook
                        </v-btn>
                    </div>

                    <div class="text-center text-caption  pb-6 px-4">
                    Good words are like rare gems. Hard to find, but highly treasured. We look forward to yours.
                </div>

                </div>

                <div v-if="rating == 1 && step == 2">


                    <!-- <div class="text-center text-h4 pb-8 px-4">
                        <v-icon size="100" color="#ee675e">mdi-emoticon-sad-outline</v-icon>
                    </div> -->

                    <div class="text-center pb-8 px-4" style="line-height:1.5rem">
                        We're sorry to hear that your experience wasn't as expected. We value your feedback deeply. Would
                        you be willing to provide us with some suggestions for improvement, so that we can serve you better
                        next time?
                    </div>

                    <div class="text-center pb-8 px-4">
                        <v-textarea autofocus outlined auto-grow row-height="30" rows="5"
                            placeholder="Tell us how we can improve" v-model="feedback"></v-textarea>

                            <v-btn color="primary" @click="submitFeedback" :loading="submitting" :disabled="submitting">
                            Submit Feedback
                        </v-btn>
                    </div>

                </div>

                <div v-if="val == 'n'">

                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        Ok. No Worries.
                    </div>
                    <div class="text-center text-body-2 py-2" style="line-height:1.5rem" v-if="purpose == 'friend' || purpose == 'lover'">
                        We encourage you to regularly check PetMeetly, as hundreds of new pets are added to the platform
                        every day. Staying active and updated increases your chances of finding the perfect match for your
                        pet
                    </div>
                    <div class="text-center text-body-2 py-2" style="line-height:1.5rem" v-if="purpose == 'adopter' || purpose == 'lover'">
                        We encourage you to remain active on PetMeetly. As hundreds of new users seeking a pet join every day, 
                        your chances of finding a loving home for your pet are constantly increasing.
                    </div>
                    <div class="text-center py-8 px-4">
                        <v-btn dark color="primary" :to="'/login'">
                            Go to Petmeetly
                        </v-btn>
                    </div>



                </div>
                <div v-if="val == 'b'">

                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        Ok. No Problem.
                    </div>
                    <div class="text-center text-body-2 py-2">
                        Your preference has been recorded.
                        Thank you for choosing petmeetly.
                    </div>
                    <div class="text-center py-8 px-4">
                        <v-btn dark color="primary" :to="'/login'">
                            Go to Petmeetly
                        </v-btn>
                    </div>



                </div>

                <div v-if="step == 'last'">

                    <div class="text-center text-h5 font-weight-bold pb-8 px-4">
                        Thank You
                    </div>
                    <div class="text-center text-body-2 py-2">
                        You may close this window or

                    </div>
                    <div class="text-center text-body-2 py-2">
                        <v-btn dark color="primary" :to="'/login'">
                            Go back to Petmeetly
                        </v-btn>

                    </div>





                </div>


            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    </v-container>
</template>

<script>
export default {
    name: 'FoundMatchStatus',
    props: ['xid', 'purpose', 'val'],



    mounted() {

        if(this.val == 'b')
        {
            this.blockFollowup();
        }

        // this.quickReply();
    },

    data() {
        return {
            submitting: false,
            notice: '',
            unpublish: null,
            rating: null,
            step: 0,
            feedback: '',

            snackbar: false,
            snackbar_text: '',

        }
    },

    methods: {

        unpublishPet(value) {
            this.unpublish = value
            
            if(value == 1)
            {
                this.submitting = true;
                let xid = this.xid;

                this.$store.dispatch('unpublishPet', { xid })
                .then((resp) => {
                    //console.log(resp);
                    this.submitting = false;
                    this.step = 1;


                })
                .catch(err => {
                    this.submitting = false;
                    this.snackbar_text = "Oops, there is an error while unpublishing the pet's profile. Please retry or contact support";
                    this.snackbar = true;
                });

            }
            else
            {
                this.step = 1
            }
        },

        rate(value) {

            this.rating = value
            let xid = this.xid;
            let rating = this.rating;

            this.$store.dispatch('updateRating', { xid, rating })
                .then((resp) => {
                    //console.log(resp);
                    this.submitting = false;
                    this.step = 2;


                })
                .catch(err => {
                    this.submitting = false;
                    this.snackbar_text = "Oops, there is an error while saving your rating. Please retry or contact support";
                    this.snackbar = true;
                });

        },

        submitFeedback() {

            if(this.feedback == null || this.feedback.length == 0 || this.feedback == '')
            {
                this.snackbar_text = "Please provide your feedback";
                this.snackbar = true;
            }
            else
            {
            let xid = this.xid;
            let feedback = this.feedback;
            this.submitting = true;

            this.$store.dispatch('submitFeedback', { xid, feedback })
                .then((resp) => {
                    //console.log(resp);
                    this.submitting = false;
                    this.step = 'last'


                })
                .catch(err => {
                    this.submitting = false;
                    this.snackbar_text = "Oops, there is an error while submitting your feedback. Please retry or contact support";
                    this.snackbar = true;
                });
            }
        },

        blockFollowup() {
            

                let xid = this.xid;

                this.$store.dispatch('blockFollowup', { xid })
                .then((resp) => {
                    //console.log(resp);

                })
                .catch(err => {

                });


        },

        

    },

}
</script>
