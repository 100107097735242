<template>
<div class="pet" >

    <div v-if="loading">
        <v-row justify="center" no-gutters>
        <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">
        <v-skeleton-loader
          type="card,  date-picker-options, article, list-item-two-line,actions"
        ></v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-else>

        <pet-pop v-if="pet && pet.xid" v-model="showPetPop"  :pet="pet" />

    </div>

</div>
</template>

<script>

import PetPop from '../components/PetPop.vue';
export default {
    name: 'Pet',
    props: ['id','for'],

    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.replace('/login').catch(err => {});
        }
        else
        {
            this.getPetDetails();
            
            let pet_id = this.id;
            this.$store.dispatch('logView', {pet_id});
        }


        if (this.for && this.$store.getters.isLoggedIn) {


            if(this.for == 'u')
            {
                this.$store.dispatch('setMyPetID', null);
            }
            else
            {
                this.loading = true;
                this.$store.dispatch('isMyPet', this.for).then((resp) => {
                    if(resp.data)
                    {
                        this.$store.dispatch('setMyPetID', this.for);
                    }
                    else
                    {
                        this.$store.dispatch('setMyPetID', null);
                    }
                    this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                    });
                
            }

            
 
        }
    },

    components: {
        PetPop
    
    },

    watch: {
      "$store.getters.userDetails": function(oldVal,newVal) {
            this.mbu = this.$store.getters.userDetails.myBlocked.map(o => {
              return o.buid;
            });

            this.hideSection = this.mbu.includes(this.pet.user.xid)
      },
    },

     data() {
        return {
            hideSection: false,
            loading: false,
            snackbar: false,
            snackbar_text: '',
            pet: {
                user:{},
            },
            showPetPop: false,
        }
     },

     computed: {
 
    },

    methods: {

        getPetDetails() {
            this.loading = true;
            this.$store.dispatch('petFromId', this.id).then((resp) => {
                this.pet = resp.data;
                this.loading = false;
                this.showPetPop = true;


            })
            .catch(err => {
                    this.overlay = false;
                    this.snackbar_text = "Oops, Unable to get the pet";
                    this.snackbar = true;
                    this.$router.replace('/home');
            });
        },

        
    }
}
</script>
