<template>   
<div>

    <div v-if="loading">
        <v-row justify="center" no-gutters>
        <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">
        <v-skeleton-loader
          type="card,  date-picker-options, article, list-item-two-line,actions"
        ></v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-else>

        <adopter-pop v-if="adopter && adopter.xid" v-model="showAdopterPop"  :adopter="adopter" />

    </div>

</div>
</template>

<script>

import AdopterPop from '../components/AdopterPop.vue';
export default {
    name: 'Adopter',
    props: ['id','for'],

    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.replace('/login').catch(err => {});
        }
        else
        {
            this.getAdopterDetails();
        }


        if (this.for && this.$store.getters.isLoggedIn) {


            if(this.for == 'u')
            {
                this.$store.dispatch('setMyPetID', null);
            }
            else
            {
                this.loading = true;
                this.$store.dispatch('isMyPet', this.for).then((resp) => {
                    if(resp.data)
                    {
                        this.$store.dispatch('setMyPetID', this.for);
                    }
                    else
                    {
                        this.$store.dispatch('setMyPetID', null);
                    }
                    this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                    });
                
            }
 
        }
        
    },

    components: {
        AdopterPop
    
    },

     data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
            adopter: {},
            showAdopterPop: false,
        }
     },

     computed: {

      

     },

    methods: {

        getAdopterDetails() {
            this.loading = true;
            this.$store.dispatch('adopterFromId', this.id).then((resp) => {
                this.adopter = resp.data;
                this.loading = false;
                this.showAdopterPop = true;


            })
            .catch(err => {
                    this.overlay = false;
                    this.snackbar_text = "Oops, Unable to get the user details";
                    this.snackbar = true;
                    this.$router.replace('/home');
            });
        },

    }
}
</script>
