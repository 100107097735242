<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="6" class="pb-16">
                        <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                    </v-col>
                </v-row>

                <v-row no-gutters v-show="loading">
                    <v-col cols="12" justify="center" align="center" class="pt-12">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </v-col>
                </v-row>

                <div class="py-2 text-center">

                    <v-btn text color="primary" :to="'/home'">
                        <v-icon left small>mdi-arrow-left</v-icon> BACK 
                    </v-btn>
                </div>

            </v-col>
        </v-row>

    </v-container>
</template>


<script>
export default {
    name: 'PremiumPlans',
    created() {

    },
    mounted() {

            this.$store.dispatch('profile').then(() => {
                this.loading = false,
                this.$store.dispatch('showPremiumPop', true);
            });
    
    },
    data() {
        return {
            loading: true,

        }
    },
    components: {

    },
    methods: {

    }
}
</script>