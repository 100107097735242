<template>
<v-bottom-sheet v-model="this.$store.getters.cookiePolicy" persistent>
    <v-card tile dark color="primary" class="pb-2">
        <v-card-title>
        </v-card-title>
        <v-card-text class="text-caption white--text">
            We use cookies to personalize your experience. By continuing, you agree to our use of cookies as explained in our <a :href="'/privacy'" class="white--text text-decoration-underline">Privacy Policy</a>.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  color="white" class="primary--text"  @click="close">OK, Got it!</v-btn>
        </v-card-actions>
    </v-card>
</v-bottom-sheet>
</template>

<script>
export default {

    mounted() {

    },

    data() {
        return {
            
        }
    },

    methods: {
        close() {
            this.$store.dispatch('setCookiePolicy', "accept");
        },
    }
}
</script>
