<template>
  <v-dialog v-model="show"  max-width="450" transition="dialog-transition" :retain-focus="false" persistent>

    <v-card class="petProfileVerificationPop">

      <v-toolbar flat color="transparent" max-height="56">

<v-spacer></v-spacer>

<v-btn icon @click.stop="show=false">
    <v-icon>mdi-close</v-icon>
</v-btn>

</v-toolbar>
<v-card-text>

      <v-row no-gutters>
      
        <!-- Profile Video -->

        <v-col cols="12">
          <div class="text-subtitle-1 font-weight-bold pb-4 black--text">Pet Profile Video Verification</div>
        </v-col>

        <div class="text-caption">
          <p>We require profile verification to confirm that the pet is indeed currently in your care.</p>
          <p>We kindly request you to film a video with your pet that is anywhere from <strong class="secondary--text">10 to 30 seconds</strong> in length, during which you should <strong class="secondary--text"> display a piece of paper that clearly shows today's date </strong>. </p>
          <p>Please note that this video will remain private, and its purpose is solely for verification. Rest assured, it will be removed concurrently with your account, should you decide to discontinue our services.
        </p>
        </div>

        <v-alert border="left" text color="error"  v-if="!pet.video_verified" >
              <v-row align="center">
                <v-col class="grow"><h5 class="font-weight-bold red--text text--darken-1 pb-2">Important:</h5>
                  <div class="text-caption">In the video, you must <span class="font-weight-bold red--text text--darken-1">display a piece of paper that clearly shows today's date</span> along with your pet; otherwise, the video will be rejected.</div></v-col>
              </v-row>
            </v-alert>


        
        <v-col cols="12"  v-if="!pet.video_verified">
          <div class="pa-1 primary--text text-subtitle-2 font-weight-medium pb-4">Profile Video</div>

          <v-alert border="left" text color="info"  v-if="pet.video_verified == 0 && pet.verification_video_uploaded != 0" class="mt-4">
              <v-row align="center">
                <v-col class="grow text-caption">Your verification video has been uploaded successfully and will be reviewed within the next 24-48 hours.</v-col>
              </v-row>
            </v-alert>

          <v-row>
          <v-col cols="6" align="center" v-if="petProfileVideo && !reuploadVideo">
            <v-img src="/img/icons/player.png" cover style="border-radius:5px" aspect-ratio="1">
              <v-btn icon dark absolute top right class="mt-n4 mr-n4" @click="deleteVid(petProfileVideo.xid)">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-img>
          </v-col>

          <v-col cols="6" class="pa-4" align="center" v-if="!petProfileVideo || reuploadVideo">
            <v-card flat style="border-radius:5px; border: 2px dashed #adb5bd;" @click="launchProfileVideoPicker" class="pt-2">
              <v-icon class="pa-8" v-if="!submitting" color="primary" size="36">mdi-video-plus</v-icon>
              <v-progress-circular v-if="submitting" class="center ma-8 pl-n5" color="primary" size="36" indeterminate></v-progress-circular>
            </v-card>
          </v-col>
          </v-row>
          <input type="file" ref="profilevideo" name="profilevideo" @change="loadProfileVideo($event)" style="display:none" accept="video/mp4, video/quicktime">

        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submit">Submit</v-btn>
                </v-card-actions>
    
    </v-card>


    <v-bottom-sheet v-model="videoDeleteConfirm">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="dark darken-2" dark>
        <div class="grey--text text--lighten-1 text-body-2 mb-4">
          Are you sure you want to delete this video?
        </div>

        <v-btn :disabled="videoDeleting" class="ma-1 pr-16" color="grey" plain @click="videoDeleteConfirm=false">
          Cancel
        </v-btn>

        <v-btn :loading="videoDeleting" class="ma-1 pl-16" color="red" plain @click="deleteVideo">
          Delete
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <v-snackbar v-model="snackbar" bottom>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-dialog>
</template>

<script>

export default {
  name: 'PetProfileVerification',
  props: ['value', 'pet', 'reuploadVideo'],
  mounted() {
    this.petProfileVideo = this.getProfileVideo(this.pet);
  },
  data() {
    return {
      petProfileVerification: null,
      petProfileVideo: null,
      videoDeleteConfirm: false,
      videoDeleting: false,
      videoToDelete: null,

      submitting: false,
      snackbar: false,
      snackbar_text: '',
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {

      launchProfileVideoPicker() {
        this.$refs.profilevideo.value = '';
        this.$refs.profilevideo.click();
      },

      loadProfileVideo(event) {
          var input = event.target;
          if (input.files && input.files[0]) {
              let uploadedFile=input.files[0];
              let vidsize = (uploadedFile['size'] / (1024*1024)).toFixed(2);

              if (vidsize > 50) {
                  this.snackbar_text = "Video size must be less than 50MB.";
                  this.snackbar = true;
              } else {
                  this.saveVideo(uploadedFile);
              }
          }
      },

      /* saveVideo(video) {
          this.submitting = true;

          let formData = new FormData();

          formData.append('id', this.pet.xid);
          formData.append('video', video);

          this.$store.dispatch('addProfileVideo', formData)
              .then((resp) => {
                  this.$store.dispatch('getPetDetails', this.pet.xid).then((resp) => {
                    this.petProfileVideo = this.getProfileVideo(resp.data)
                    this.submitting = false;
                    this.$emit('force-pet-rerender');
                  });
              })
              .catch(err => {
                  this.snackbar_text = "Error while updating";
                  this.snackbar = true;
                  this.submitting = false;
              });
      }, */


      saveVideo(video) {
        this.submitting = true;

        const file_extension = video.name.split('.').pop(); // Get file extension
        const filename = video.name.split('.').slice(0, -1).join('.'); // Get the base filename
        const folder = 'pet_verification_videos';
        const file_type = video.type;

        this.$store.dispatch('generateSignedUrl', {
            file_extension, filename, folder, file_type
                    })
                        .then((resp) => {
                            //console.log(resp);
                            const signedUrl = resp.data.url;
                            const filePath = resp.data.path;

                            //console.log(video.type);

                            const xhr = new XMLHttpRequest()
                            xhr.open('PUT', signedUrl, true)
                            xhr.onload = () => {
                            const status = xhr.status
                            if (status === 200) {
                                //console.log("File uploaded successfully:", filePath);
                                const fileUrl = `https://storage.googleapis.com/${process.env.VUE_APP_GOOGLE_CLOUD_STORAGE_BUCKET}/${filePath}`;

                                let formData = new FormData();

                                formData.append('id', this.pet.xid);
                                formData.append('video_url', fileUrl);
                                formData.append('file_path', filePath);

                                this.$store.dispatch('addProfileVideo', formData)
                                .then((resp) => {
                                    this.$store.dispatch('getPetDetails', this.pet.xid).then((resp) => {
                                      this.petProfileVideo = this.getProfileVideo(resp.data)
                                      this.submitting = false;
                                      this.$emit('force-pet-rerender');
                                    });
                                })
                                .catch(err => {
                                    this.snackbar_text = "Error while updating";
                                    this.snackbar = true;
                                    this.submitting = false;
                                });

                            } else {
                                this.submitting = false;
                                this.snackbar_text = "Error uploading";
                                this.snackbar = true;
                            }
                            }
                            xhr.onerror = () => {
                                this.submitting = false;
                                this.snackbar_text = "Error uploading";
                                this.snackbar = true;
                            }

                            xhr.upload.onprogress = function (event) {
                                if (event.lengthComputable) {
                                    //this.video_upload_completion = (event.loaded / event.total) * 100;
                                    //console.log(this.video_upload_completion);
                                    //console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
                                }
                            };

                            xhr.setRequestHeader('Content-Type', video.type)
                            xhr.send(video)
                        })
                        .catch(err => {
                            this.submitting = false;
                            this.snackbar_text = "Error uploading";
                            this.snackbar = true;
                        });

       

        },

      getProfileVideo(pet) {
          let petProfileVerification = pet.pet_profile_verification;
          let petProfileVideo = petProfileVerification ? petProfileVerification.filter(item => {
            return item.type == 'profile_video'
          }) : null;
          petProfileVideo = petProfileVideo ? petProfileVideo[0] : null;

          return petProfileVideo;
      },

      deleteVid(xid) {
          this.videoToDelete = xid
          this.videoDeleteConfirm = true;
      },

      deleteVideo() {
          this.videoDeleting = true;

          this.$store.dispatch('deleteProfileVideo', this.videoToDelete)
              .then((resp) => {
                  this.$store.dispatch('getPetDetails', this.pet.xid).then((resp) => {
                      this.petProfileVideo = this.getProfileVideo(resp.data)
                      this.videoDeleting = false;
                      this.videoDeleteConfirm = false;
                      this.$emit('force-pet-rerender');
                  });
              })
              .catch(err => {
              });
      },
      submit()
      {
        this.show=false;
      }
  }
}
</script>
