<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center" v-if="submitting">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <div class="py-8">Processing your payment...</div>
        </v-col>
        
        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='success'">
            <div>
                    <span class="text-h5 font-weight-light" style="letter-spacing:1px !important">Congratulations!</span>
                </div>

                 <div class="pa-8 text-center">
                        <v-icon class="golden-chip flip-icon" style="border-radius:60px; padding:15px" color="#d4af37" size="60">mdi-database</v-icon>
                </div>

                <div class="text-center pb-3">
                    <div class="text-overline premium-title" style="font-size:.9em !important;">Direct Connects Credited</div>
                </div>


                <div class="py-8">

                    <v-btn color="primary" :to="'/home'">
                        Continue
                    </v-btn>
                </div>
        </v-col>


        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='failure'">
            <div>
                    <v-icon color="primary" size="80" class="pa-4">mdi-alert-circle-outline</v-icon>
                </div>
                <div class="pa-8">
                    <span class="text-h6 d-block pb-4">Payment Failed</span>
                    <span>We couldn't complete processing your payment</span>
                </div>

                <div class="py-8">

                    <v-btn color="primary"  @click="$store.dispatch('showDirectConnectPop', true)">
                        Retry
                    </v-btn>
                </div>
        </v-col>


    </v-row>

 
</v-container>
</template>

<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'TxnDcStatus',
    data() {
        return {
            id:'',
            status:'',

            submitting: true,
        }
    },
    mounted() {

        this.status = this.$route.query.status
        this.id = this.$route.query.id ? this.$route.query.id : ''

        

        if(this.status == 'success')
        {
            this.paymentCallback();
        }
        else if(this.status == 'failure')
        {

            this.submitting = false;

            if (typeof window.gtag === 'function') {
                window.gtag('event', 'purchase_failure_cashfree', {
                                event_category: 'Purchase Failure',
                                event_label: 'Purchase Failure'
                            });
            }


        }
        else
        {
            this.submitting = false;
        }

    },
    methods: {
        
        paymentCallback() {

             this.submitting = true;


             window.gtag('event', 'conversion', {
                            'send_to': 'AW-10848052375/NZuMCN2UhqYDEJfJ4LQo',
                            'value': 299,
                            'currency': 'INR',
                            'transaction_id': ''
                        });

                        window.gtag('event', 'purchase_done_cashfree', {
                            event_category: 'Purchase',
                            event_label: 'Direct Connect',
                            value: 299
                        });

                        window.fbq('track', 'Purchase', {currency: "INR", value: 299}); 

                        
                        this.$store.dispatch('profile');
                        
                        this.submitting = false;

                        setTimeout(() => {
                            this.$router.replace('/home');
                        }, 2000);


        },
        
    }
}
</script>
