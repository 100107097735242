<template>
<div>
    <v-app-bar color="primary" flat app dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <!-- <v-badge content="PREMIUM" inline :value="this.$store.getters.isPremium"> -->
        <v-toolbar-title class="pl-0">
            <v-img alt="Petmeetly Logo" class="shrink" contain src="@/assets/logo/petmeetly-text-white.png" max-width="120" max-height="35" />
        </v-toolbar-title>
        <!-- </v-badge> -->

        <v-spacer></v-spacer>

        <v-chip class="ma-1" color="purple darken-4" text-color="white" @click="$store.dispatch('showMessageCreditPop', true);" v-show="this.$store.getters.userDetails.mcredits < 0">
            <v-icon left size="20" color="lime">mdi-email-fast-outline</v-icon>
            {{this.$store.getters.userDetails.mcredits}}
        </v-chip>

        <v-chip class="ma-1" color="purple darken-4" text-color="white" @click="$store.dispatch('showDirectConnectPop', true);">
            <v-icon left size="20" color="amber">$vuetify.icons.coin-icon</v-icon>
            {{this.$store.getters.userDetails.dconnects}}
        </v-chip>

        

        <!-- <v-chip class="ma-1" color="purple darken-4" text-color="white" v-if="!this.$store.getters.isPremium" @click="msgLimitInfoDialog = true">
            <v-icon left size="20" color="amber">mdi-chat-processing</v-icon>
            {{this.$store.getters.userDetails.msg_limit}}
        </v-chip> -->

        <!--  <v-btn icon @click="$store.dispatch('showDirectConnectPop', true);">
            <v-badge color="secondary" :content="$store.getters.userDetails.dconnects" :value="$store.getters.userDetails.dconnects"  overlap >
                <v-icon>$vuetify.icons.coin-icon</v-icon>
            </v-badge>
        </v-btn> -->
        <v-btn icon :to="'/messages'" v-if="!$vuetify.breakpoint.mobile">
            <v-badge color="secondary"   :content="this.$store.getters.getTotalMessages" :value="this.$store.getters.getTotalMessages" overlap>
            <v-icon>mdi-email-outline</v-icon>
            </v-badge>
        </v-btn>

        <v-dialog fullscreen v-model="notificationDialog" v-if="$vuetify.breakpoint.mobile">
            <template v-slot:activator="{ on, attr }">
                <v-btn icon v-on="on" v-bind="attr">
                    <v-badge color="secondary" :value="$store.getters.unreadNotificationsCount" :content="$store.getters.unreadNotificationsCount" overlap>
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-card>

            <v-app-bar dark app flat color="primary">

                <v-toolbar-title class="text-subtitle-1 font-weight-medium">Notifications</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text small outlined @click="markAllRead" :loading="markingRead" :disabled="markingRead">Mark all read</v-btn>
                <v-btn icon @click="notificationDialog = false" ><v-icon>mdi-close</v-icon></v-btn>

            </v-app-bar>

            <div class="pt-12">
                <v-list three-line v-if="$store.getters.notifications.length > 0">
                    <v-list-item :key="index" v-for="(item, index) in $store.getters.notifications" style="border-bottom:1px solid #f6f6f6; min-height: 48px" :style="item.read_at ? '' : 'background-color:#ece7ff; border-left:4px solid #7E0DE7'" @click="goTo(item)">
                        <v-list-item-avatar color="primary">
                           <v-img v-if="item.data.from.avatar" :src="item.data.from.avatar"></v-img>
                            <v-icon v-else dark>mdi-email</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content class="py-0">
                            <v-list-item-subtitle class="text-caption font-weight-regular" style="line-height:1.4">{{item.data.title}}</v-list-item-subtitle>
                            <!-- <v-list-item-subtitle class="text-caption grey--text" style="font-size: 11px !important;">{{ item.created_at | moment("calendar") }}</v-list-item-subtitle> -->
                        </v-list-item-content>
                        <v-list-item-action class="align-center justify-center" style="max-width:200px">
                            <v-list-item-action-text class="text-truncate text-caption grey--text">{{item.created_at | moment("D MMM")}}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <div v-else class="text-center pa-12">
                    <v-icon size="80" color="#eee">mdi-email-open</v-icon>
                    <div class="text-caption grey--text">No Pending Notifications</div>
                </div>
            </div>
        </v-card>
        </v-dialog>

        <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-badge color="secondary" :value="$store.getters.unreadNotificationsCount" :content="$store.getters.unreadNotificationsCount" overlap>
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-sheet max-width="340" min-width="300">
                <v-toolbar flat style="border-bottom:1px solid #f6f6f6;">
                    <v-toolbar-title class="text-subtitle-2">Notifications</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text small color="primary" @click="markAllRead" :loading="markingRead" :disabled="markingRead">Mark all read</v-btn>
                </v-toolbar>

                <v-responsive max-height="320" class="overflow-y-auto">
                    <v-list three-line v-if="this.$store.getters.notifications.length > 0">
                        <v-list-item :key="index" v-for="(item, index) in this.$store.getters.notifications" style="border-bottom:1px solid #f6f6f6;" :style="item.read_at ? '' : 'background-color:#ece7ff; border-left:4px solid #7E0DE7'" @click="goTo(item)">
                            <v-list-item-avatar color="primary">
                                <v-img v-if="item.data.from.avatar" :src="item.data.from.avatar"></v-img>
                                <v-icon v-else dark>mdi-email</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-subtitle class="text-caption font-weight-regular" style="line-height:1.4">{{item.data.title}}</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-caption grey--text" style="font-size: 11px !important;">{{ item.created_at | moment("calendar") }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <div v-else class="text-center pa-12">
                        <v-icon size="80" color="#eee">mdi-email-open</v-icon>
                        <div class="text-caption grey--text">No Pending Notifications</div>
                    </div>
                </v-responsive>
                <!-- <v-btn flat tile  block color="primary">View All</v-btn> -->
            </v-sheet>
        </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary class="menu-bg" height="100vh">
        <v-list>

            <div class="pt-2 pl-4">
                <v-avatar size="64" v-if="this.$store.getters.userDetails.photo">
                    <v-img v-if="this.$store.getters.userDetails.photo" :src="this.$store.getters.userDetails.photo"></v-img>
                </v-avatar>
            </div>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="primary--text font-weight-medium">
                        {{this.$store.getters.userDetails.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">{{this.$store.getters.userDetails.email}}</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-caption">{{location}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <div class="text-center" v-if="this.$store.getters.isPremium">
                <v-chip class="golden-chip" :to="'/membership'"><v-icon  left>mdi-crown</v-icon> Premium Member</v-chip>
            </div>
            <div class="text-center py-2" v-else >
                <v-btn  small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO PREMIUM</v-btn>
            </div>
        </v-list>
        <v-divider></v-divider>
        <v-list flat>
            <v-list-item-group v-model="group" color="primary">

                <v-list-item :to="'/home'">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.home-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="'/newpet'"  v-if="this.$store.getters.userDetails.latitude  && !(this.$store.getters.userDetails.category == 'buyer' || this.$store.getters.userDetails.category == 'adopter')">
                    <v-list-item-icon>
                        <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Add Pet</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="'/favourites'">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.heart-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Favorites</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="'/messages'">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.messages-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Messages</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item :to="'/account'">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.account-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Account</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-item :to="'/settings'">
                    <v-list-item-icon>
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item v-if="this.$store.getters.userDetails.subscription_route == 'fastspring' || this.$store.getters.userDetails.subscription_route == 'stripe'" @click="billing">
                    <v-list-item-icon>
                        <v-icon>mdi-credit-card-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Billing</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-else-if="this.$store.getters.userDetails.subscription_route == 'cashfree'" :to="'/billing'">
                    <v-list-item-icon>
                        <v-icon>mdi-credit-card-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Billing</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
 -->
            </v-list-item-group>
        </v-list>

        <template v-slot:append>

            <v-list flat>

                <v-list-item-group v-model="group" color="primary">

                    <v-list-item :to="'/faq'">
                        <v-list-item-icon>
                            <v-icon>$vuetify.icons.help-icon</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>FAQ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="showContact">
                        <v-list-item-icon>
                            <v-icon>mdi-chat-question-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Support</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>$vuetify.icons.logout-icon</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>

            </v-list>

            <div class="pb-4 px-8">
                <v-row no-gutters justify="space-around" align="center">
                    <div class="text-caption grey--text">
                        <router-link class="grey--text" :to="'/terms'">Terms</router-link>
                    </div>
                    <div>|</div>
                    <div class="text-caption grey--text">
                        <router-link class="grey--text" :to="'/privacy'">Privacy</router-link>
                    </div>

                </v-row>

            </div>

        </template>
    </v-navigation-drawer>

    <v-dialog v-model="msgLimitInfoDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Message Limit</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="msgLimitInfoDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">

                <div class="text-center pb-6">
                    <v-icon color="grey lighten-2" size="80">mdi-chat-processing-outline</v-icon>
                </div>

                <div class="text-center">
                    <p>You can send upto <b>3 messages per day</b> to other members.</p>

                    <div class="text-center mt-6 mb-2 text-caption"><p>Want to send more?</p></div>

                    <div class="text-center mt-4 mb-2">
                                <v-btn color="primary" @click="showPremium">
                                    UPGRADE TO PREMIUM
                                </v-btn>
                            </div>
                    
                </div>

                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="msgLimitInfoDialog=false">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
export default {
    name: 'TopBar',

    data: () => ({
        drawer: false,
        group: null,
        markingRead: false,
        msgLimitInfoDialog: false,
        notificationDialog: false,
    }),

    watch: {
        group() {
            this.drawer = false
        },
    },

    computed: {

    location() {
      return this.$store.getters.userDetails.state ? (this.$store.getters.userDetails.locality ? this.$store.getters.userDetails.locality + ', ' : (this.$store.getters.userDetails.city ? this.$store.getters.userDetails.city + ', ' : '')) + this.$store.getters.userDetails.state : '';
    },

  },

    methods: {
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login').catch(err => {});
                })
                .catch(err => {

                });
        },

        billing() {
            this.$store.dispatch('getHostedBillingUrl').then((resp) => {
                    window.location.href = resp.url;
                })
                .catch(err => {

                });
        },

        goTo(item) {
            this.$store.dispatch('markNotificationRead', item.id).then((resp) => {
                    //console.log(resp)
                })
                .catch(err => {
                    //console.log(err)
                });

            if (item.type == 'MessageReceived' || item.type == 'LockedMessageReceived') {
                this.$router.push('/chats/'+item.data.cxid);
            }
        },

        markAllRead() {

            this.markingRead = true
            this.$store.dispatch('markAllNotificationsRead').then((resp) => {
                    this.markingRead = false
                    //console.log(resp)
                })
                .catch(err => {
                    this.markingRead = false
                    //console.log(err)
                });

        },

        showPremium() {
            this.$store.dispatch('showPremiumPop', true);
        },

        showContact() {
            this.$store.dispatch('showContactDialog', true);
        },
    }

}
</script>
