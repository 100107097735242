<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center" v-if="submitting">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <div>Processing your payment...</div>
        </v-col>

    </v-row>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</v-container>
</template>

<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'ConfirmPayment',
    data() {
        return {
            stripe: null,
            submitting: true,
            snackbar: false,
            snackbar_text: '',
        }
    },
    mounted() {
        //console.log(this.$route.query);
        if (this.$route.query.setup_intent_client_secret) {
            setTimeout(() => {
                this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
                this.retrieveSetupIntent(this.$route.query.setup_intent_client_secret)
            }, 500);

        }

    },
    methods: {
        retrieveSetupIntent(secret) {

            this.stripe.retrieveSetupIntent(secret).then((result) => {
                //console.log(result)
                this.savePaymentMethod(result.setupIntent.payment_method);
            });

        },
        savePaymentMethod(method) {
            axios.post('/v1/subscription/payment-method', {
                payment_method: method,
                billing_details: this.$store.getters.billingDetails
            }).then(() => {
                this.subscribe();
            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)
                this.submitting = false;
            });
        },

        subscribe() {
            let selectedPlan = this.$cookie.get('pm_selected_plan');
            let promoCodeId = this.$cookie.get('pm_coupon_code');
            let selectedValue = this.$cookie.get('pm_selected_value');
            axios.put('/v1/subscription/subscribe', {
                    selectedPlan,
                    promoCodeId
                })
                .then((response) => {
                    if (response.data.subscription_updated) {
                        //this.intentToken = response.data;
                        //console.log(response);

                        window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});

                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-10848052375/2kazCJmCuqYDEJfJ4LQo',
                            'value': selectedValue,
                            'currency': 'USD'
                        });

                        window.gtag('event', 'subscription', {
                            event_category: 'Subscription',
                            event_label: 'Premium Membership',
                            value: selectedValue
                        });

                        window.gtag('event', 'subscription_'+selectedPlan, {
                            event_category: 'Subscription',
                            event_label: 'Premium Membership',
                            value: selectedValue
                        });

                        window.fbq('track', 'Subscribe', {
                            currency: "USD",
                            value: selectedValue
                        }); 

                        window.twq('event', 'tw-ocimw-ociry', {
                            email_address: this.$store.getters.userDetails.email
                        });

                        window.rdt('track', 'Purchase');

                        window.pintrk('track', 'checkout', {
                            value: selectedValue,
                            currency: 'USD'
                            });

                        //this.cardNumber.clear();
                        //this.cardExpiry.clear();
                        //this.cardCvc.clear();
                        //this.paymentElement.clear();
                        this.$store.dispatch('profile');
                        this.$store.dispatch('getConversations');
                        this.submitting = false;
                        this.$router.replace('/home');
                        //this.successModel = true;

                        //setTimeout(() => this.closeSuccess(), 3000);
                    } else {
                        this.submitting = false;
                        this.snackbar_text = "Error while processing payment";
                        this.snackbar = true;
                    }

                })
                .catch(err => {
                    //console.log(err.response)
                    Bugsnag.notify(err)
                    this.submitting = false;
                });
        },
    }
}
</script>
