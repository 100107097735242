<template>
  <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'menu-bg' : 'onboard-bg'">

      <v-app-bar color="primary" flat app dark>
       

          <!-- <v-badge content="PREMIUM" inline :value="this.$store.getters.isPremium"> -->
          <v-toolbar-title class="pl-0">
              <v-img alt="Petmeetly Logo" class="shrink" contain src="@/assets/logo/petmeetly-text-white.png" max-width="120" max-height="35" @click="navigate" style="cursor: pointer;"/>
          </v-toolbar-title>
          <!-- </v-badge> -->

          <v-spacer></v-spacer>

          <v-btn text :to="'/signup'">
         SIGN UP
        </v-btn>

          </v-app-bar>

    <v-row justify="center" align="center">
      <v-col cols="12" md="8" lg="6" v-show="init">



        <v-item-group v-model="purpose">
          <v-row>
            <v-col cols="6" class="d-flex flex-column align-end justify-start">
              <v-item v-slot="{ active, toggle }" value="lover">
                <v-sheet @click="toggle" :height="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  :width="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  class="pa-2 rounded-lg mild-shadow clickable d-flex flex-column align-center justify-center"
                  :color="active ? 'primary' : ''" :dark="active ? true : false">
                  <div class="text-center pa-1">
                    <v-icon :size="$vuetify.breakpoint.xsOnly ? '52' : '64'">{{ '$vuetify.icons.lover-icon' }}</v-icon>
                  </div>
                  <div class="pa-2 text-center" :class="$vuetify.breakpoint.xsOnly ? 'text-caption px-0' : 'px-2'">
                    I'm looking for a Mating Partner for my pet
                  </div>
                </v-sheet>
              </v-item>


            </v-col>
            <v-col cols="6" class="d-flex flex-column align-start justify-start">
              <v-item v-slot="{ active, toggle }" value="friend">
                <v-sheet @click="toggle" :height="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  :width="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  class="pa-2 rounded-lg mild-shadow clickable d-flex flex-column align-center justify-center"
                  :color="active ? 'primary' : ''" :dark="active ? true : false">
                  <div class="text-center pa-1">
                    <v-icon :size="$vuetify.breakpoint.xsOnly ? '52' : '64'">{{ '$vuetify.icons.friend-icon' }}</v-icon>
                  </div>
                  <div class="pa-2 text-center" :class="$vuetify.breakpoint.xsOnly ? 'text-caption px-2' : 'px-4'">
                    I'm looking for a Playmate for my pet
                  </div>
                </v-sheet>
              </v-item>

            </v-col>
            <v-col cols="6" class="d-flex flex-column align-end justify-start">
              <v-item v-slot="{ active, toggle }" value="adopter">
                <v-sheet @click="toggle" :height="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  :width="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  class="pa-2 rounded-lg mild-shadow clickable d-flex flex-column align-center justify-center"
                  :color="active ? 'primary' : ''" :dark="active ? true : false">
                  <div class="text-center pa-1">
                    <v-icon :size="$vuetify.breakpoint.xsOnly ? '52' : '64'">{{ '$vuetify.icons.adopter-icon' }}</v-icon>
                  </div>
                  <div class="pa-2 text-center" :class="$vuetify.breakpoint.xsOnly ? 'text-caption ' : 'px-4'">
                    I want to Adopt/Buy a pet
                  </div>
                </v-sheet>
              </v-item>

            </v-col>
            <v-col cols="6" class="d-flex flex-column align-start justify-start">
              <v-item v-slot="{ active, toggle }" value="buyer">
                <v-sheet @click="toggle" :height="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  :width="$vuetify.breakpoint.xsOnly ? '150' : '200'"
                  class="pa-2 rounded-lg mild-shadow clickable d-flex flex-column align-center justify-center"
                  :color="active ? 'primary' : ''" :dark="active ? true : false">
                  <div class="text-center pa-1">
                    <v-icon :size="$vuetify.breakpoint.xsOnly ? '52' : '64'">{{ '$vuetify.icons.buyer-icon' }}</v-icon>
                  </div>
                  <div class="pa-2 text-center" :class="$vuetify.breakpoint.xsOnly ? 'text-caption ' : 'px-4'">
                    I want to Rehome/Sell a pet
                  </div>
                </v-sheet>
              </v-item>

            </v-col>
          </v-row>
        </v-item-group>

        <v-row>
          <v-col class="text-center pa-8">
            <v-btn width="200" color="primary" :disabled="purpose == '' || purpose == null" @click="nextstep">NEXT
            </v-btn>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="12" v-show="result">


        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="6" md="4" lg="3" v-for="(pet, index) in this.pets" :key="index" class="px-4 pb-2">


            <v-card class="mild-shadow rounded-lg" :to="'/signup'">


              <v-img :src="pet.avatar ? pet.avatar.photo : require('@/assets/logo/logo-bg2.jpg')"
                :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300" class="white--text align-end"
                gradient="to left bottom , rgba(0,0,0,.01) 50%, rgba(0,0,0,.7)">
                <!-- <div class="premium-tag" v-if="pet.is_premium">PREMIUM</div> -->
                <div class="price-tag" v-if="pet.price > 0">{{ Number(pet.price).toLocaleString() }} {{ pet.price_ccy ==
                  null ? '$ USD' : pet.price_ccy }}</div>
                <!--  <div class="verified-tag" v-if="pet.video_verified">Verified</div> -->

                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>



                <div class="px-2 pb-2 pl-3">

                  <div class="text-h6 font-weight-medium text-truncate">{{ pet.name }}

                  </div>

                  <div class="text-truncate text-caption">{{ pet.breed }}</div>
                  <div class="text-truncate text-caption">{{ pet.age ? pet.age.long : '' }} old, {{ pet.gender == 'M' ?
                    'Male' : 'Female' }}</div>
                  <div class="text-truncate text-caption">
                    <v-icon dark x-small>
                      mdi-map-marker-outline
                    </v-icon> {{locationFmt(pet)}}
                  </div>
                  <div class="text-truncate text-caption pt-2">
                    {{ pet.description }}
                  </div>
                </div>

              </v-img>
            </v-card>


          </v-col>
        </v-row>
        <v-row v-if="pets.length == 0">
          <v-col class="text-center pa-8">

            <div class="pb-4">Hey there!</div>
            <div>To explore the adorable pets on PetMeetly, please <router-link :to="'/signup'">create an
                account</router-link>. </div>
            <div>We'd love to have you join our community!</div>

          </v-col>
        </v-row>

        <v-row v-else>
          <v-col class="text-center pa-8 pb-16">
            <div class="pb-8"><router-link :to="'/signup'">Create an account</router-link> with PetMeetly to discover many
              more adorable pets. </div>

          </v-col>
        </v-row>





      </v-col>

      <v-col cols="12" v-show="resultAdopters">


<v-row class="d-flex justify-center">
  <v-col cols="12" sm="6" md="4" lg="3" v-for="(adopter, index) in this.adopters" :key="index" class="px-4 pb-2">


    <v-card class="mild-shadow rounded-lg" :to="'/signup'">


      <v-card-title class="pa-0 pt-3">

      <v-row no-gutters align="center">
          
      <v-col cols="10" class="pl-2">
      <v-list>
          <v-list-item two-line>
              <v-list-item-avatar style="border: 2px solid #ffffff" size="48" color="grey lighten-4" class="my-0 align-center justify-center">
                  <v-img v-if="adopter.user.photo" :src="adopter.user.photo"></v-img>
                  <v-icon color="primary" v-else size="28">$vuetify.icons.account-icon</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="black--text">
                
                  <v-list-item-title class="font-weight-medium">
                      
                      {{adopter.user.name}}
                    
                      </v-list-item-title>
                  

                  <v-list-item-subtitle class="text-caption">
                      {{locationFmt(adopter)}}
                  </v-list-item-subtitle>

              </v-list-item-content>

          </v-list-item>
      </v-list>
      </v-col>
      
      </v-row>
      </v-card-title>
      <v-card-text class="pa-0 pb-3">
      <!-- <v-card-text class="px-0 pt-0" @click="show = !show"> -->
      <div class="px-4">
          <p class="text-pre-wrap text-caption">{{adopter.description}}</p>
          <!-- <v-divider class="my-2"></v-divider> -->

          <div class="text-caption grey--text pb-1">
                  Preferences:
                  </div>
                  <div>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.pet_type.type}} </v-chip>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.age == 'B' ? 'Baby ' : (adopter.age == 'Y' ? 'Young ' : (adopter.age == 'A' ? 'Adult ' : ( adopter.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.gender == 'M' ? 'Male ' : (adopter.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.pet_size ? adopter.pet_size.size+' size':'Any size'}} </v-chip>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.pet_breed ? adopter.pet_breed.breed+' ':'Any breed'}} </v-chip>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{adopter.available_for ? 'For '+ adopter.available_for: 'For sale/adoption'}} </v-chip>
                      <v-chip x-small class="ma-1" style="height: 20px"> {{'Within ' + adopter.pref_distance + ' Miles'}} </v-chip>
                  </div>
          <!-- <v-alert text dense color="secondary" icon="mdi-information" class="text-caption">
              {{adopter.user.name}} prefers a {{adopter.age == 'B' ? 'baby ' : (adopter.age == 'Y' ? 'young ' : (adopter.age == 'A' ? 'adult ' : ( adopter.age == 'S' ? 'senior ' : '')))}}{{adopter.gender == 'M' ? 'male ' : (adopter.gender == 'F' ? 'female ' : '')}}{{adopter.breed?adopter.breed.breed+' ':''}}{{adopter.pet_type.type.toLowerCase()}}.
          </v-alert> -->
      </div>


      <v-row no-gutters class="pa-4">
          <v-col>
              <v-chip v-if="adopter.user.phone_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                      <v-icon small class="mr-2">mdi-cellphone-check</v-icon>
                                  Phone Verified</v-chip>

                  <v-chip v-if="adopter.user.email_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                      <v-icon small class="mr-2">mdi-email-check-outline</v-icon>
                                  Email Verified</v-chip>
                  <v-chip v-if="adopter.user.video_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                      <v-icon small class="mr-2">mdi-video-check-outline</v-icon>
                                  Video Verified</v-chip>
          </v-col>
      </v-row>

      </v-card-text>
    </v-card>


  </v-col>
</v-row>
<v-row v-if="adopters.length == 0">
  <v-col class="text-center pa-8">

    <div class="pb-4">Hey there!</div>
    <div>To explore potential buyers/adopters on PetMeetly, please <router-link :to="'/signup'">create an
        account</router-link>. </div>
    <div>We'd love to have you join our community!</div>

  </v-col>
</v-row>

<v-row v-else>
  <v-col class="text-center pa-8 pb-16">
    <div class="pb-8"><router-link :to="'/signup'">Create an account</router-link> with PetMeetly to discover many
      more potential buyers/adopters. </div>

  </v-col>
</v-row>





</v-col>
    </v-row>




    <v-dialog v-model="filterDialog" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable origin="bottom center"
      max-width="450" transition="dialog-transition">
      <v-card>
        <div>
          <v-toolbar flat>
            <v-toolbar-title class="toolbar-title">
              <span class="subtitle-custom"></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click.native="filterDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </div>

        <v-card-text class="pt-10 search-filter">

          <v-row>

            <v-col cols="12" class="px-4 py-0 pb-6">
              <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Type of pet</div>
              <v-item-group v-model="pet_type" mandatory @change="breed = ''">
                <v-row no-gutters>
                  <v-col cols="3" class="d-flex align-center justify-center pa-1"
                    v-for="(item, index) in this.$store.getters.petTypes" :key="index">
                    <v-item v-slot="{ active, toggle }" :value="item.xid">
                      <v-sheet @click="toggle" height="70" width="70" class="pa-2 rounded-lg mild-shadow clickable"
                        :color="active ? 'primary' : ''" :dark="active ? true : false">
                        <div class="text-center pa-1">
                          <v-icon size="28">{{ '$vuetify.icons.' + item.icon }}</v-icon>
                        </div>
                        <div class="text-center">
                          {{ item.type }}
                        </div>
                      </v-sheet>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-col>

            <v-col cols="12" class="px-4 pb-2">
              <div class="primary--text text-subtitle-1 font-weight-medium">Gender</div>
              <v-radio-group v-model="gender" row>
                <v-radio label="Male" value="M"></v-radio>
                <v-radio label="Female" value="F"></v-radio>
                <v-radio label="Any" value=""></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" class="px-4 pb-6">
              <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Breed <span
                  class="grey--text text-caption">(If you have a preference)</span></div>
              <div>

                <v-autocomplete v-model="breed" solo flat hide-no-data hide-details clearable :filter="filterObject"
                  :items="typeBreeds" item-text="breed" item-value="xid" return-object label="Select breed">

                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                </v-autocomplete>
              </div>

            </v-col>

            <v-col cols="12" class="px-4 py-0 pb-6">
              <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Location</div>

              <v-alert v-model="locationPicked" class="pl-0">
                <v-row align="center">
                  <v-col class="grow">
                    {{ location ? location.description : '' }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn icon @click="clearLocation">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>

              <div v-show="!locationPicked">

                <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat hide-no-data
                  placeholder="Please type your locality" item-text="description" item-value="placeId" clearable
                  hint="So that we can find matches closer to you" persistent-hint
                  prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails"
                  @click:clear="clearLocation"></v-autocomplete>

                <div class="py-4">
                  <v-btn text small color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                    <v-icon left>mdi-crosshairs-gps</v-icon> {{ gettingLocation ? 'Getting your location' : 'get your current location'}}
                  </v-btn>
                </div>

              </div>

            </v-col>

          </v-row>

        </v-card-text>

        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn block large color="primary" @click="resetAndFilter" :loading="searching" :disabled="searching">Search</v-btn>

          <v-spacer></v-spacer>

        </v-card-actions>

      </v-card>
    </v-dialog>



    <v-dialog v-model="filterDialogAdopters" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable origin="bottom center"
      max-width="450" transition="dialog-transition">
      <v-card>
        <div>
          <v-toolbar flat>
            <v-toolbar-title class="toolbar-title">
              <span class="subtitle-custom"></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click.native="filterDialogAdopters = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </div>

        <v-card-text class="pt-10 search-filter">

          <v-row>

            <v-col cols="12" class="px-4 py-0 pb-6">
              <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Location</div>

              <v-alert v-model="locationPicked" class="pl-0">
                <v-row align="center">
                  <v-col class="grow">
                    {{ location ? location.description : '' }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn icon @click="clearLocation">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>

              <div v-show="!locationPicked">

                <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat hide-no-data
                  placeholder="Please type your locality" item-text="description" item-value="placeId" clearable
                  hint="So that we can find matches closer to you" persistent-hint
                  prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails"
                  @click:clear="clearLocation"></v-autocomplete>

                <div class="py-4">
                  <v-btn text small color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                    <v-icon left>mdi-crosshairs-gps</v-icon> {{ gettingLocation ? 'Getting your location' : 'get your current location'}}
                  </v-btn>
                </div>

              </div>

            </v-col>

          </v-row>

        </v-card-text>

        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn block large color="primary" @click="resetAndFilterAdopters" :loading="searching" :disabled="searching">Search</v-btn>

          <v-spacer></v-spacer>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" bottom>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-fab-transition>
      <v-btn v-show="result" color="primary" fab large bottom left fixed @click="filterDialog = !filterDialog">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn v-show="resultAdopters" color="primary" fab large bottom left fixed @click="filterDialogAdopters = !filterDialogAdopters">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-container>
</template>


<script>
import gmapsInit from '../plugins/gmaps';
import _ from 'lodash';
export default {
  name: 'Landing',
  async mounted() {

    if (this.$store.getters.isLoggedIn) {
      this.$router.replace('home');
    }

    const google = await gmapsInit();
    //this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.service = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();

    if (this.$store.getters.petTypes.length == 0) {
      this.getStaticData();
    }

  },

  data() {
    return {

      snackbar: false,
      snackbar_text: '',
      purpose: '',
      init: true,
      result: false,
      resultAdopters: false,
      pets: [],
      adopters: [],
      filterDialog: false,
      filterDialogAdopters: false,
      searching: false,
      breed: '',
      dataLoading: false,
      gender: '',
      pet_type: '',
      location: {
        description: '',
        placeId: '',
        state: '',
        country: '',
        xip: '',
        locality: '',
      },
      searchResults: [],
      service: null,
      geocoder: null,
      sessionToken: null,
      search: null,
      cordinates: null,
      locationPicked: false,

      gettingLocation: false,
    }

  },

  computed: {


    

    typeBreeds() {
      let petTypes = this.$store.getters.petTypes;
      let selectedPetType = this.pet_type;

      let filteredItem = petTypes.filter(item => {
        return item.xid == selectedPetType
      });

      if (filteredItem.length > 0)
        return filteredItem[0].breeds;
      else
        return [];
    },

    breedName() {
      return this.breed ? _.truncate(this.breed.breed, {
        'length': 20,
      }) : ''
    },
    blockedByUser() {
      return this.$store.getters.userDetails.meBlocked ? this.$store.getters.userDetails.meBlocked.map(o => {
        return o.bbid;
      }) : [];
    },
  },

  watch: {

    search(val) {
      if (val != '' && val !== null) {
        if ((val.length > 3) && (val.length % 2 == 0)) {
          this.service.getPlacePredictions({
            input: val,
            //sessionToken: this.sessionToken,
            types: ['geocode']
          }, this.displaySuggestions)
        }
      }
    }

  },

  methods: {

    locationFmt(pet) {
      return (pet.locality ? pet.locality : (pet.city ? pet.city : pet.state)) + (pet.distance > 0 ? ' (' + Math.round(pet.distance) + ' Miles)' : '')
    },

    navigate() {
      window.location.href = "https://petmeetly.com";
    },

    getStaticData() {
      this.dataLoading = true;
      this.$store.dispatch('getStaticData').then(() => {
        this.dataLoading = false;
      })
        .catch(err => {
          //console.log(err);
          this.dataLoading = false;
          this.snackbar_text = "Oops, there is an error loading details for the page";
          this.snackbar = true;
        });
    },

    filterObject(item, queryText, itemText) {
      return (
        item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    nextstep() {
      if (this.purpose != 'buyer') {

        this.filterDialog = true
      }
      else {
        this.filterDialogAdopters = true
      }
    },

    resetAndFilter() {

      if (!this.location || this.location.description == '') {
        this.snackbar_text = "Please pick your location";
        this.snackbar = true;
      } else {

        this.pets = [];
        this.searching = true;

        let breed = JSON.stringify(this.breed)
        let gender = this.gender
        let pet_type = this.pet_type
        let purpose = this.purpose
        let location = JSON.stringify(this.location)
        let cordinates = JSON.stringify(this.cordinates)

        this.$store.dispatch('publicSearch', {
          breed,
          gender,
          pet_type,
          purpose,
          location,
          cordinates,
        }).then((resp) => {

          this.filterDialog = false;
          this.pets = resp;
          this.result = true;
          this.init = false;
          this.searching = false;


        });
      }

    },


    resetAndFilterAdopters() {

    if (!this.location || this.location.description == '') {
      this.snackbar_text = "Please pick your location";
      this.snackbar = true;
    } else {

      this.adopters = [];
      this.searching = true;

      let location = JSON.stringify(this.location)
      let cordinates = JSON.stringify(this.cordinates)

      this.$store.dispatch('publicSearchAdopters', {
        location,
        cordinates,
      }).then((resp) => {

        this.filterDialogAdopters = false;
        this.adopters = resp;
        this.resultAdopters = true;
        this.init = false;
        this.searching = false;


      });
    }

    },

    clearLocation() {

      //console.log('clear location');
      this.location = {
        description: '',
        placeId: '',
        state: '',
        country: '',
        zip: '',
        locality: '',
      };
      this.cordinates = null;
      this.searchResults = [];
      this.locationPicked = false;

      //this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    },

    /* MapsInit() {
    console.log('Initializing map');
    this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    this.service = new window.google.maps.places.AutocompleteService();
    this.geocoder = new window.google.maps.Geocoder();
    }, */
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => ({
        description: prediction.description,
        placeId: prediction.place_id
      }))
    },

    getPlaceDetails() {
      if (this.location && this.location.placeId != '') {
        //console.log(this.location.placeId);
        this.geocoder.geocode({
          placeId: this.location.placeId
        }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {

            let res = results[0];

            for (var i = 0; i < res.address_components.length; i++) {
              var addressType = res.address_components[i].types[0];
              var addressType2 = res.address_components[i].types[1];

              if (addressType == 'administrative_area_level_1')
                this.location.state = res.address_components[i].long_name;
              else if (addressType == 'country')
                this.location.country = res.address_components[i].short_name;
              else if (addressType == 'postal_code')
                this.location.zip = res.address_components[i].long_name;
              else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                this.location.locality = res.address_components[i].long_name;

            }

            this.cordinates = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            };

            this.locationPicked = true;
            //console.log(this.cordinates);
            //this.closeFieldDialog();

          }
        });
      }
    },

    async getCurrentLocation() {
      this.gettingLocation = true;
      try {

        let location = await this.getLocation();

        //console.log(location);
        this.cordinates = {
          lat: location.coords.latitude,
          lng: location.coords.longitude
        };

        this.geocoder.geocode({
          location: this.cordinates
        }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {

            //console.log(results[0]);
            this.gettingLocation = false;

            let res = results[0];

            for (var i = 0; i < res.address_components.length; i++) {
              var addressType = res.address_components[i].types[0];
              var addressType2 = res.address_components[i].types[1];

              if (addressType == 'administrative_area_level_1')
                this.location.state = res.address_components[i].long_name;
              else if (addressType == 'country')
                this.location.country = res.address_components[i].short_name;
              else if (addressType == 'postal_code')
                this.location.zip = res.address_components[i].long_name;
              else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                this.location.locality = res.address_components[i].long_name;

            }

            this.location.description = results[0].formatted_address;
            this.location.placeId = results[0].place_id;

            this.searchResults = [{
              description: results[0].formatted_address,
              placeId: results[0].place_id
            }];

            this.locationPicked = true;

            //console.log(this.searchResults);

            //this.closeFieldDialog();

          }
        });

      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }

    },

    async getLocation() {

      return new Promise((resolve, reject) => {

        if (!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });

      });
    },

  }
}
</script>
