<template>
<v-list-item v-if="item.parties[0].user.xid == $store.getters.userDetails.xid" @click.stop="chat">

                        <v-badge color="#d4af37" icon="mdi-crown" offset-y="25" offset-x="30" class="mt-2" :value="(item.parties[1].pet && item.parties[1].pet.is_premium) || item.parties[1].user.is_premium">
                            <v-list-item-avatar v-if="item.parties[1].pet" class="ml-0 justify-center" color="primary">
                                <v-img v-if="item.parties[1].pet.avatar" :src="item.parties[1].pet.avatar.photo"></v-img>
                                <v-icon dark v-else size="24">{{item.parties[1].pet.pet_type ? '$vuetify.icons.'+item.parties[1].pet.pet_type.icon : 'mdi-paw'}}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-avatar v-else class="ml-0 justify-center" color="primary">
                                <v-img v-if="item.parties[1].user.photo" :src="item.parties[1].user.photo"></v-img>
                                <v-icon dark v-else size="24">$vuetify.icons.account-icon</v-icon>
                            </v-list-item-avatar>
                        </v-badge>

                        <v-list-item-content class="py-4" v-if="item.parties[1].pet">
                            <v-list-item-title class="font-weight-medium" v-html="item.parties[1].pet.name"></v-list-item-title>
                            <v-list-item-subtitle class="text-caption secondary--text" v-html="item.parties[1].pet.breed"></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.parties[0].pet" class="text-caption" v-html="'For '+item.parties[0].pet.name"></v-list-item-subtitle>
                            
                            <v-alert border="left" text dense color="error" class="mt-2 mb-0 mr-2" v-if="item.parties[1].user.banned">
                                   <span class="text-caption font-weight-medium">Account Banned</span>
                            </v-alert>
                            <v-alert border="left" text dense  class="mt-2 mb-0 mr-2" v-else-if="item.parties[1].user.deleted">
                                   <span class="text-caption font-weight-medium">Account Closed</span>
                            </v-alert>
                            
                            
                        </v-list-item-content>
                        <v-list-item-content class="py-4" v-else>
                            <v-list-item-title class="font-weight-medium" v-html="item.parties[1].user.name"></v-list-item-title>
                            <v-list-item-subtitle v-if="item.parties[0].pet" class="text-caption" v-html="'For '+item.parties[0].pet.name"></v-list-item-subtitle>

                            <v-alert border="left" text dense color="error" class="mt-2 mb-0 mr-2" v-if="item.parties[1].user.banned">
                                   <span class="text-caption font-weight-medium"><v-icon size="14" color="red">mdi-cancel</v-icon> Account Banned</span>
                            </v-alert>
                            <v-alert border="left" text dense  class="mt-2 mb-0 mr-2" v-else-if="item.parties[1].user.deleted">
                                   <span class="text-caption font-weight-medium">Account Closed</span>
                            </v-alert>

                        </v-list-item-content>

                        <v-list-item-action class="align-center justify-center" style="max-width:200px">
                            <v-chip v-if="item.unread_messages_count > 0" color="green" small dark style="padding:4px 8px; line-height:1; min-width:24px; text-align:center; display:inline-block">{{item.unread_messages_count}}</v-chip>
                            <v-list-item-action-text class="pt-1 text-truncate">{{item.updated_at | moment("D MMM YY")}}</v-list-item-action-text>
                        </v-list-item-action>

                        <!-- <chat v-model="showChat" :pet="item.parties[1].pet" :user="item.parties[1].user" :conversation="item"></chat> -->
                        <chat v-if="$store.getters.chatModel && $store.getters.activeChatIdentifier === chatIdentifier" :pet="item.parties[1].pet" :user="item.parties[1].user" :conversation="item" :identifier="chatIdentifier" :key="componentKey" />
                        <!-- <component :is="selectedComponent" v-model="showChat" :pet="item.parties[1].pet" :user="item.parties[1].user" :conversation="item" :key="componentKey"></component>  -->

                    </v-list-item>

                    <v-list-item v-else @click.stop="chat">

                        <v-badge color="#d4af37" icon="mdi-crown" offset-y="25" offset-x="30" class="mt-2" :value="(item.parties[0].pet && item.parties[0].pet.is_premium) || item.parties[0].user.is_premium">
                            <v-list-item-avatar v-if="item.parties[0].pet" class="ml-0 justify-center" color="primary">
                                <v-img v-if="item.parties[0].pet.avatar" :src="item.parties[0].pet.avatar.photo"></v-img>
                                <v-icon dark v-else size="24">{{item.parties[0].pet.pet_type ? '$vuetify.icons.'+item.parties[0].pet.pet_type.icon : 'mdi-paw'}}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-avatar v-else class="ml-0 justify-center" color="primary">
                                <v-img v-if="item.parties[0].user.photo" :src="item.parties[0].user.photo"></v-img>
                                <v-icon dark v-else size="24">$vuetify.icons.account-icon</v-icon>
                            </v-list-item-avatar>
                        </v-badge>

                        <v-list-item-content class="py-4" v-if="item.parties[0].pet">
                            <v-list-item-title class="font-weight-medium" v-html="item.parties[0].pet.name"></v-list-item-title>
                            <v-list-item-subtitle class="text-caption secondary--text" v-html="item.parties[0].pet.breed"></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.parties[1].pet" class="text-caption" v-html="'For '+item.parties[1].pet.name"></v-list-item-subtitle>

                            <v-alert border="left" text dense color="error" class="mt-2 mb-0 mr-2" v-if="item.parties[0].user.banned">
                                   <span class="text-caption font-weight-medium">Account Banned</span>
                            </v-alert>
                            <v-alert border="left" text dense  class="mt-2 mb-0 mr-2" v-else-if="item.parties[0].user.deleted">
                                   <span class="text-caption font-weight-medium">Account Closed</span>
                            </v-alert>

                        </v-list-item-content>
                        <v-list-item-content class="py-4" v-else>
                            <v-list-item-title class="font-weight-medium" v-html="item.parties[0].user.name"></v-list-item-title>
                            <v-list-item-subtitle v-if="item.parties[1].pet" class="text-caption" v-html="'For '+item.parties[1].pet.name"></v-list-item-subtitle>

                            <v-alert border="left" text dense color="error" class="mt-2 mb-0 mr-2" v-if="item.parties[0].user.banned">
                                   <span class="text-caption font-weight-medium">Account Banned</span>
                            </v-alert>
                            <v-alert border="left" text dense  class="mt-2 mb-0 mr-2" v-else-if="item.parties[0].user.deleted">
                                   <span class="text-caption font-weight-medium">Account Closed</span>
                            </v-alert>

                        </v-list-item-content>

                        <v-list-item-action class="align-center justify-center" style="max-width:200px">
                            <v-chip v-if="item.unread_messages_count > 0" color="green" small dark style="padding:4px 8px; line-height:1; min-width:24px; text-align:center; display:inline-block">{{item.unread_messages_count}}</v-chip>
                            <v-list-item-action-text class="pt-1 text-truncate">{{item.updated_at | moment("D MMM YY")}}</v-list-item-action-text>
                        </v-list-item-action>

                        <!-- <chat v-model="showChat" :pet="item.parties[0].pet" :user="item.parties[0].user" :conversation="item"></chat>  -->
                        <chat v-if="$store.getters.chatModel && $store.getters.activeChatIdentifier === chatIdentifier" :pet="item.parties[0].pet" :user="item.parties[0].user" :conversation="item" :identifier="chatIdentifier" :key="componentKey" />
                        <!-- <component :is="selectedComponent" v-model="showChat" :pet="item.parties[0].pet" :user="item.parties[0].user" :conversation="item" :key="componentKey"></component> -->

                    </v-list-item>

</template>

<script>
import Chat from './Chat.vue'
export default {
    name: 'ConversationRow',
    props: ['item'],
    components: {
        Chat
    },
    data() {
        return {
            showChat: false,
            selectedComponent: '',
            key: '',
            chatIdentifier: `chat-${this.item.xid || Math.random().toString(36).substring(7)}`, // Generate a unique identifier
            componentKey: 0
        }
    },
    methods: {
        chat()
        {
            this.$store.dispatch('showChatModel', true);
            this.$store.dispatch('setActiveChatIdentifier', this.chatIdentifier);
            this.componentKey++;
        }
    }

}
</script>
