<template>

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M1960 4625 c-182 -25 -396 -86 -551 -157 l-67 -30 -51 35 c-102 71
-225 101 -348 87 -102 -12 -148 -29 -263 -99 -155 -94 -179 -106 -305 -156
-153 -60 -188 -80 -253 -149 -78 -82 -113 -167 -120 -283 -3 -59 0 -110 8
-140 52 -193 228 -356 418 -388 23 -4 42 -12 42 -18 -1 -7 -5 -57 -10 -112
-31 -344 82 -655 315 -874 57 -53 71 -61 105 -61 30 0 43 6 59 26 36 46 28 68
-61 162 -126 133 -181 225 -226 377 -55 184 -45 435 25 643 127 382 470 713
905 876 242 90 438 120 728 113 283 -8 470 -48 698 -148 479 -211 791 -584
868 -1033 22 -129 15 -317 -15 -431 -112 -421 -497 -677 -1160 -771 -150 -21
-566 -30 -743 -15 -254 22 -505 74 -673 141 -96 38 -137 39 -164 4 -27 -35
-27 -75 2 -102 41 -39 280 -119 452 -152 98 -19 91 -11 98 -103 6 -81 29 -176
62 -251 15 -35 17 -84 16 -420 -1 -210 3 -407 8 -439 19 -114 89 -208 191
-256 47 -22 69 -26 145 -26 75 0 98 4 140 25 70 34 126 89 161 157 28 56 29
63 32 231 3 140 6 172 18 172 9 0 14 -11 14 -28 0 -45 40 -191 70 -259 38 -84
96 -144 173 -178 166 -76 367 -1 446 165 21 45 26 70 26 136 l0 80 180 13 c99
7 199 15 223 18 l42 5 0 -98 c0 -181 25 -265 104 -345 23 -23 66 -54 96 -68
47 -22 69 -26 145 -26 75 0 98 4 140 25 70 34 126 89 161 157 l29 58 3 289 3
289 41 46 c114 130 168 291 170 503 0 59 4 108 9 108 17 0 117 -22 194 -42
161 -43 260 -25 338 61 55 61 69 103 65 192 -6 137 -70 207 -233 253 -142 39
-296 65 -459 78 l-151 12 -77 62 c-42 34 -98 75 -125 90 -97 55 -94 51 -82 82
47 122 73 384 52 533 l-9 66 57 12 c125 25 271 124 337 229 44 68 82 189 82
259 0 112 -43 230 -114 310 -56 64 -109 96 -247 151 -149 59 -179 74 -312 155
-123 76 -168 94 -269 107 -127 16 -235 -10 -341 -80 l-61 -41 -84 36 c-175 76
-366 127 -575 155 -106 14 -430 11 -547 -5z m-832 -247 c28 -11 52 -23 52 -27
0 -3 -13 -14 -29 -24 -124 -73 -339 -276 -432 -407 -69 -97 -132 -213 -175
-324 l-39 -98 -43 6 c-101 14 -211 95 -267 199 -22 40 -30 68 -33 127 -4 67
-1 83 22 133 38 80 107 138 203 173 113 40 265 114 366 179 48 30 100 60 115
66 82 32 168 31 260 -3z m2437 21 c66 -12 104 -30 203 -93 93 -60 177 -101
314 -155 140 -55 194 -96 235 -178 74 -153 1 -343 -167 -432 -33 -18 -81 -34
-108 -38 l-48 -5 -18 58 c-82 264 -305 551 -573 738 -40 27 -73 53 -73 57 0
10 79 42 123 50 23 3 45 7 48 8 3 0 32 -4 64 -10z m432 -1886 c21 -14 20 -15
-27 -62 -54 -54 -81 -102 -134 -241 -43 -109 -71 -151 -119 -174 -64 -30 -212
-17 -323 28 l-58 24 84 42 c187 94 327 206 429 343 l63 86 32 -16 c17 -9 41
-22 53 -30z m207 -184 c58 -78 103 -175 126 -271 28 -117 28 -295 1 -391 -21
-78 -36 -99 -45 -69 -10 32 -68 47 -104 28 -16 -8 -32 -23 -36 -31 -3 -9 -6
-199 -6 -421 0 -377 -1 -407 -19 -442 -67 -131 -240 -135 -313 -7 -23 40 -23
41 -23 430 0 215 3 402 8 417 18 65 100 128 167 128 73 0 108 96 53 140 -24
19 -37 21 -84 17 -118 -10 -224 -87 -275 -201 -27 -59 -28 -67 -32 -272 l-4
-212 -102 -11 c-55 -6 -166 -14 -245 -18 l-144 -6 5 47 c3 27 26 88 53 144 42
85 48 107 52 177 4 65 1 91 -17 137 -28 75 -96 150 -168 185 -50 24 -69 28
-147 28 -78 0 -97 -4 -147 -28 -71 -35 -130 -100 -185 -204 -49 -91 -99 -239
-108 -315 -10 -83 -7 -81 -77 -54 -148 55 -260 124 -355 217 -75 74 -121 141
-157 229 -26 62 -56 214 -45 225 3 2 50 0 105 -5 150 -14 520 -12 684 5 172
17 276 35 422 71 l107 26 41 -32 c97 -77 303 -135 456 -128 174 7 255 75 337
282 59 145 87 190 134 215 21 11 40 20 43 21 3 0 23 -23 44 -51z m359 -10 c93
-14 271 -55 322 -74 73 -27 97 -107 50 -163 -36 -43 -62 -46 -172 -18 -54 14
-138 32 -187 41 -49 8 -90 16 -91 18 -1 1 -10 27 -20 57 -10 30 -28 76 -42
103 l-23 47 45 0 c26 0 79 -5 118 -11z m-1585 -638 c112 -57 130 -146 58 -289
-24 -48 -49 -111 -56 -141 -17 -70 -8 -189 20 -266 14 -40 20 -75 17 -103 -9
-87 -89 -156 -179 -155 -95 1 -151 61 -194 208 -36 121 -38 300 -6 418 24 87
92 234 130 279 54 64 140 85 210 49z m-1018 -365 c48 -47 162 -121 242 -159
l68 -32 0 -178 c0 -145 -3 -184 -16 -210 -23 -46 -44 -66 -88 -89 -79 -40
-180 -6 -228 77 -22 39 -23 48 -26 333 -2 160 0 292 4 292 5 0 24 -15 44 -34z"/>
<path d="M1495 3715 c-23 -22 -25 -32 -25 -108 0 -70 3 -89 21 -111 28 -35 80
-36 114 -1 23 22 25 32 25 110 0 78 -2 88 -25 110 -15 16 -36 25 -55 25 -19 0
-40 -9 -55 -25z"/>
<path d="M2906 3719 c-25 -19 -26 -25 -26 -113 0 -84 2 -95 23 -114 25 -23 66
-28 97 -12 36 19 53 138 30 207 -19 52 -79 68 -124 32z"/>
<path d="M2200 3603 c-72 -6 -156 -25 -190 -43 -66 -34 -130 -122 -130 -178 0
-12 -25 -32 -76 -61 -94 -54 -176 -136 -217 -221 -27 -55 -32 -75 -32 -140 0
-63 4 -82 27 -121 51 -86 169 -167 269 -184 32 -6 43 -13 58 -43 67 -131 239
-201 419 -173 128 19 214 73 262 164 22 41 27 44 91 61 118 30 215 107 254
199 19 46 21 142 5 194 -33 98 -131 205 -252 273 -54 31 -64 41 -69 70 -16 99
-93 165 -216 189 -60 12 -157 18 -203 14z m184 -175 c42 -10 94 -54 80 -68
-23 -22 -182 -84 -214 -83 -38 1 -210 76 -210 92 0 25 62 60 117 66 32 4 63 8
68 10 15 5 114 -6 159 -17z m-408 -216 c5 -5 54 -28 109 -52 86 -38 110 -44
170 -44 63 0 81 5 184 52 l114 52 50 -28 c67 -36 144 -106 167 -153 31 -60 27
-112 -13 -152 -66 -65 -170 -95 -255 -73 -44 11 -73 26 -175 90 -49 31 -119
26 -170 -13 -110 -84 -209 -103 -319 -61 -60 22 -113 72 -124 116 -17 66 70
181 183 242 65 35 65 35 79 24z m334 -491 c34 -22 74 -43 91 -46 38 -9 33 -24
-17 -52 -78 -44 -224 -38 -282 12 -15 13 -25 25 -22 27 3 2 33 17 68 33 34 16
70 37 79 47 9 10 18 18 19 18 2 0 31 -18 64 -39z"/>
</g>
</svg>


<!-- <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
stroke="currentColor" fill="currentColor">
<path d="M2970 4720 c-75 -12 -163 -45 -230 -88 -76 -48 -547 -520 -598 -599
-62 -97 -76 -162 -77 -343 0 -145 2 -161 29 -244 55 -168 160 -292 311 -367
63 -31 96 -41 153 -46 58 -5 72 -9 70 -21 -2 -9 -57 -47 -123 -85 -959 -554
-1320 -779 -1462 -912 -121 -111 -218 -276 -265 -450 -25 -90 -34 -277 -19
-374 10 -62 30 -135 56 -203 6 -15 -18 -17 -297 -20 -283 -3 -306 -4 -348 -24
-62 -29 -105 -67 -138 -123 -24 -41 -27 -56 -27 -141 0 -85 3 -100 27 -141 33
-56 76 -94 138 -123 45 -21 48 -21 1270 -21 674 0 1237 3 1252 8 35 9 94 61
114 98 21 42 18 125 -8 178 -27 58 -160 190 -215 215 -24 10 -43 24 -43 29 0
23 72 137 106 166 53 47 75 53 221 58 l132 6 3 -309 c3 -309 3 -309 28 -354
17 -29 41 -53 70 -70 l45 -25 400 0 c397 0 400 0 442 23 55 29 92 85 100 150
8 71 -29 145 -116 232 -71 71 -156 119 -255 144 -33 9 -61 18 -63 20 -5 4 22
241 33 298 3 17 20 36 47 54 61 40 183 160 233 230 174 243 220 573 119 856
-53 148 -127 260 -245 371 l-80 75 0 180 0 180 328 5 c363 5 373 6 516 75 95
46 192 117 240 175 46 57 177 268 233 378 42 83 45 94 41 149 -3 47 -10 67
-34 96 -49 61 -84 74 -199 74 -133 0 -264 16 -333 40 -90 32 -153 81 -337 260
-178 174 -222 206 -345 246 -49 15 -102 18 -450 20 -217 1 -420 -2 -450 -6z
m893 -227 c58 -28 96 -60 235 -196 180 -176 224 -211 335 -262 68 -31 197 -65
250 -65 17 0 27 -10 40 -39 10 -21 38 -57 62 -80 l44 -41 -59 -97 c-75 -126
-143 -193 -242 -243 -115 -57 -150 -62 -470 -58 l-283 3 -51 27 c-55 29 -121
97 -130 135 -6 22 -10 23 -111 23 l-105 0 6 -27 c17 -68 69 -158 126 -217 l60
-63 0 -279 1 -279 45 -35 c68 -52 188 -184 226 -249 78 -131 103 -232 95 -390
-12 -258 -121 -443 -353 -601 l-81 -55 -38 -302 c-20 -166 -34 -306 -30 -312
3 -6 21 -11 39 -11 64 0 209 -31 260 -55 55 -27 104 -68 131 -109 l17 -26
-341 0 -341 0 0 470 0 470 -100 0 -100 0 0 -95 0 -95 -133 0 c-90 0 -150 -5
-183 -15 -27 -8 -49 -15 -50 -15 -1 0 -4 46 -7 102 -7 121 -40 245 -93 351
-41 81 -108 178 -151 219 l-29 28 -68 -69 -69 -69 47 -57 c59 -72 107 -161
137 -250 21 -61 24 -89 24 -220 0 -179 -16 -242 -101 -401 -53 -100 -64 -134
-44 -134 6 0 58 -22 116 -50 112 -52 170 -93 205 -141 l21 -30 -528 4 c-591 4
-575 2 -730 78 -184 90 -316 245 -386 455 -20 60 -23 88 -23 214 1 132 3 152
28 224 65 186 158 298 355 429 122 81 775 473 1179 707 l303 175 0 90 0 90 76
40 c145 76 256 221 290 376 10 49 13 179 14 562 l0 499 298 -4 297 -3 68 -32z
m-865 -480 l-3 -508 -28 -61 c-30 -64 -96 -136 -157 -170 -137 -78 -318 -52
-432 61 -88 87 -123 201 -116 387 6 171 4 168 301 466 139 140 272 265 295
279 45 27 107 52 130 53 10 0 12 -101 10 -507z m-1978 -3333 l88 -91 -428 3
c-412 3 -429 4 -450 23 -35 31 -42 62 -25 100 9 18 26 37 38 43 14 7 142 11
355 11 l333 1 89 -90z"/>
<path d="M3854 4249 c-41 -12 -94 -72 -95 -107 -5 -114 46 -172 148 -172 44 0
58 5 88 31 87 76 56 218 -54 249 -41 11 -45 11 -87 -1z"/>
</g>
</svg> -->
 <!--  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M231.6 16.18l16.7 120.02 73.8 20.5c37.3-11.2 78.5-18.2 102.3-43.6 9.7-10.3 17.2-24.78 9.1-37.92l-75.3 2.22-14.6-31.79h-74.7c-7.7-11.71-22.8-20.46-37.3-29.43zm5.7 145.22c-46.9 19.8-110.1 146.3-111.8 276.5-34.02-58.1-24.9-122.6-2.9-202.6C55.31 287 4.732 448.4 133.1 486.9H346s-6.3-21.5-14.1-28.9c-12.7-12-48.2-20.2-48.2-20.2 27.8-39.2 33.5-71.7 38.6-103.9 4.5 59.8 40.7 126.8 57.4 153h76.5s4.6-15.9.2-21.5c-10.9-13.8-51.3-11.9-51.3-11.9-31.1-107.2-46.3-260.2-90-273.2-21.7-6.5-54.3-14.1-77.8-18.9z"></path></svg>
 --></template>

<script>
export default {
  name: 'dog-icon'
}
</script>
