<template>
  <div>
  <v-dialog v-model="this.$store.getters.verifyProfilePop" persistent  max-width="450" :retain-focus="false">
  
      <v-card>
  
        <v-toolbar flat color="transparent" max-height="56">
  
  <v-spacer></v-spacer>
  
  <v-btn icon @click.native="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
  
  </v-toolbar>
  <v-card-text>
  
        <v-row no-gutters>
        
          <!-- Profile Video -->
  
          <v-col cols="12">
            <div class="text-subtitle-1 font-weight-bold pb-4 black--text">User Video Verification</div>
          </v-col>
  
          <div class="text-caption">
            <p>To ensure the safety and security of our community, we kindly request you to complete a short video verification.</p>
            <p class="black--text font-weight-bold">Please record and upload a short selfie video clearly stating your full name, purpose and today's date</p>
            
          </div>

          <v-alert border="left" text color="info"  v-if="this.$store.getters.userDetails.video_verified == 0 && this.$store.getters.userDetails.video_uploaded_at != null" class="mt-4">
              <v-row align="center">
                <v-col class="grow text-caption">Your verification video has been uploaded successfully and will be reviewed within the next 24-48 hours.</v-col>
              </v-row>
            </v-alert>

           

            <v-row v-if="this.$store.getters.userDetails.video_uploaded_at == null">
      

          <v-col cols="6" class="pa-4" align="center">
            <v-card flat style="border-radius:5px; border: 2px dashed #adb5bd;" @click="launchProfileVideoPicker" class="pt-2">
              <v-icon class="pa-8" v-if="!submitting" color="primary" size="36">mdi-video-plus</v-icon>
              <v-progress-circular v-if="submitting" class="center ma-8 pl-n5" color="primary" size="36" indeterminate></v-progress-circular>
            </v-card>
          </v-col>
          </v-row>
          <input type="file" ref="profilevideo" name="profilevideo" @change="loadProfileVideo($event)" style="display:none" accept="video/mp4, video/quicktime">

  
       
          <div class="text-caption mt-8">
            <p><b>Your privacy is important to us.</b> This video is solely for verification purposes and will not be shared or used for any other purpose.</p>
          </div>
          
         
        </v-row>
      </v-card-text>
  
      <v-card-actions>
                      <v-spacer></v-spacer>
                      <!-- <v-btn color="primary" v-if="profileVideo" @click="submit">Continue</v-btn> -->
                  </v-card-actions>
      
      </v-card>
  
  

      <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  
    </v-dialog>
</div>
  </template>
  
  <script>
  
  export default {

    mounted() {
      
    },
    data() {
      return {

        profileVideo: null,

  
        submitting: false,
        snackbar: false,
        snackbar_text: '',
      }
    },
  

  
    methods: {

      close() {
            this.$store.dispatch('showVerifyProfilePop', false);
        },
  
        launchProfileVideoPicker() {
          this.$refs.profilevideo.value = '';
          this.$refs.profilevideo.click();
        },
  
        loadProfileVideo(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                let uploadedFile=input.files[0];
                let vidsize = (uploadedFile['size'] / (1024*1024)).toFixed(2);
  
                if (vidsize > 50) {
                    this.snackbar_text = "Video size must be less than 50MB.";
                    this.snackbar = true;
                } else {
                    this.saveVideo(uploadedFile);
                }
            }
        },
  
        saveVideo(video) {
            this.submitting = true;
  
            let formData = new FormData();
  
            formData.append('video', video);
  
            this.$store.dispatch('addUserProfileVideo', formData)
                .then((resp) => {

                      this.submitting = false;

                      this.snackbar_text = "Profile verification video uploaded";
                      this.snackbar = true;

                      this.$store.dispatch('showVerifyProfilePop', false)

                })
                .catch(err => {
                    this.snackbar_text = "Error while uploading";
                    this.snackbar = true;
                    this.submitting = false;
                });
        },
  

  
        
    }
  }
  </script>
  