<template>
<v-dialog v-model="installPrompt" persistent max-width="450" :retain-focus="false">

    <v-card>

        <v-toolbar flat color="transparent" max-height="56">

            <v-spacer></v-spacer>

            <v-btn icon @click.native="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-toolbar>

        <v-card-text class="text-center">
            <div>
            <v-img src='@/assets/logo/icon-196.png' max-width="90" class="mx-auto"></v-img>
            </div>
            <div class="text-h5 py-4">Install Petmeetly</div>
            <div class="pb-4">
                Install Petmeetly on your device for quick and easy access when you're on the go.
            </div>
            <!-- <div>
                Just tap <v-icon color="blue">mdi-export-variant</v-icon>, then "Add to Home Screen"
            </div> -->
            <v-row>
                <v-col cols="12" sm="6">
                    <a href="https://apps.apple.com/app/apple-store/id6478047015?pt=118572154&ct=Web%20App%20Referral&mt=8" target="_blank">
                    <v-img src='@/assets/logo/appstore-256x256.png' max-width="180"  class="mx-auto"></v-img>
                </a>
                </v-col>
                 <v-col cols="12" sm="6">
                    <a href="https://play.google.com/store/apps/details?id=com.petmeetly" target="_blank">
                    <v-img src='@/assets/logo/googleplay-badge-256.png' max-width="200" class="mx-auto"></v-img>
                    </a>
                </v-col>
            </v-row>
        </v-card-text>
        

    </v-card>

</v-dialog>
</template>

          
<script>
import Bowser from 'bowser';
export default {

    mounted() {

            //If the device is iOs and mobile
            //If have pets / preferences
            //If the last prompt was >= 14 days back
            //if logged in
            //If standalone


            const browser = Bowser.getParser(window.navigator.userAgent);
            const osName = browser.getOS().name.toLowerCase();

            if (osName === 'ios' || osName === 'macos') {
            this.deviceType = 'Apple';
            } else if (osName === 'android') {
                this.deviceType = 'Android';
            } else {
                this.deviceType = 'Other';
            }

                let today =  this.$moment()
                let lastPrompt = this.$moment((this.$cookie.get("pm_install_prompt_last_seen") ? this.$cookie.get("pm_install_prompt_last_seen") : "1/1/2022"),'M/D/YYYY')
                let days = today.diff(lastPrompt, 'days')


                let has_pets = ((this.$store.getters.userDetails.pets.length > 0 && this.$store.getters.userDetails.pets[0].name != '') || (this.$store.getters.userDetails.preferences && this.$store.getters.userDetails.preferences.active == 1)) ? true : false

                //console.log(has_pets);

                if(this.$store.getters.isLoggedIn && has_pets && days > 14)
                {
                    this.installPrompt = true
                }
                else
                {
                    this.installPrompt = false
                }

            


    },

    data() {
        return {
            installPrompt: false,
            deviceType: '',
            
        }
    },

    methods: {
        close() {
            this.$cookie.set("pm_install_prompt_last_seen", this.$moment().format('M/D/YYYY'), 9999);
            this.installPrompt = false
        },
    }
    
}
</script>
