<template>
<v-container fluid style="background-color:#f6f6f6">

    <div v-if="fetching">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else>

        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in pets" :key="index">

                <v-card class="mild-shadow rounded-lg" @click="go2app">

                    <v-img :src="item.avatar ? item.avatar.photo : require('@/assets/logo/logo-bg2.jpg')" :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300" class="white--text align-end" gradient="to left bottom , rgba(0,0,0,.01) 50%, rgba(0,0,0,.7)">

                        <div class="price-tag" v-if="item.price > 0">{{Number(item.price).toLocaleString()}} {{ item.price_ccy == null ? '$ USD' : item.price_ccy }}</div>

                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>

                        <div class="px-2 pb-2">

                            <div class="text-h6 font-weight-medium text-truncate">{{item.name}}
                                <v-icon v-if="item.pedigree_certified" class="white--text">mdi-seal-variant</v-icon>
                            </div>
                            <div class="text-truncate text-caption">{{item.breed}}</div>
                            <div class="text-truncate text-caption">{{item.age ? item.age.long : ''}} old, {{item.gender == 'M' ? 'Male' : 'Female'}}</div>

                        </div>

                    </v-img>

                </v-card>

            </v-col>

        </v-row>

    </div>

</v-container>
</template>

<script>
import axios from "axios";

export default {
    name: 'PetsFeedMain',
    props: ['page'],
    mounted() {
        this.fetchPets();

    },
    components: {

    },

    data() {
        return {
            fetching: true,
            pets: [],

        }
    },

    methods: {

        fetchPets() {
            this.fetching = true;
            axios.get('/v1/pets-feed-main/' + this.page).then((resp) => {
                    console.log(resp.data)
                    this.pets = resp.data;
                    this.fetching = false;
                })
                .catch(err => {
                    console.log(err);
                    this.fetching = false;
                });
        },

        go2app()
        {
            console.log('clicked');
            window.open("https://app.petmeetly.com/home");
        }
    }

}
</script>
