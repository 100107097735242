<template>


<!-- 
    
    
    
    
    THIS FILE IS NOT USED 
    
    
    
    
    
    -->


<div class="pet" style="margin-top:-56px">

    <v-app-bar flat color="transparent" app>

        <v-btn icon dark style="background-color:rgba(0,0,0,0.25);" :to="'/home'">
            <v-icon>mdi-home</v-icon>
        </v-btn>

        <!-- <v-btn icon dark style="background-color:rgba(0,0,0,0.25);" :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon dark style="background-color:rgba(0,0,0,0.25);" @click="$router.go(-1)" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
 -->
        <v-spacer></v-spacer>

    </v-app-bar>

    <v-row justify="center" no-gutters>
        <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">

            <hooper :wheelControl="false" :itemsToShow="1" style="height:300px !important">

                <slide v-for="n in 5" :key="n">
                    <v-card :tile="$vuetify.breakpoint.xs?true:false" >
                        <v-img :src="'https://picsum.photos/id/'+Math.floor(Math.random() * 100)+'/300/300'" :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300" max-width="475">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-card>

                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>

        </v-col>
    </v-row>

    <v-row justify="center" no-gutters>
        <v-col cols="12" sm="6" md="4" lg="3" class="justify-center">

            <v-row no-gutters class="mt-6 px-4">
                <v-col cols="10">
                    <h2>Name</h2>
                    <div class="text-caption">Breed Name</div>

                </v-col>
                <v-col cols="2" class="text-right">

                    <v-btn icon>
                        <v-icon color="pink" v-if="(Math.floor(Math.random() * 10)%2==0)">
                            mdi-heart
                        </v-icon>
                        <v-icon v-else color="#dddddd">
                            mdi-heart-outline
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>

            <v-row no-gutters class="mt-2 px-2" align="center" justify="center">

                <v-col class="pa-2">
                    <v-sheet class="text-center pa-2 rounded-lg" outlined>
                        <div class="font-weight-bold">Male</div>
                        <div class="text-caption-small grey--text">Sex</div>
                    </v-sheet>
                </v-col>
                <v-col class="pa-2">
                    <v-sheet class="text-center pa-2 rounded-lg" outlined>
                        <div class="font-weight-bold">4 Yrs 2 Mos</div>
                        <div class="text-caption-small grey--text">Age</div>
                    </v-sheet>
                </v-col>
                <v-col class="pa-2">
                    <v-sheet class="text-center pa-2 rounded-lg" outlined>
                        <div class="font-weight-bold">31 lbs</div>
                        <div class="text-caption-small grey--text">Weight</div>
                    </v-sheet>
                </v-col>

            </v-row>

            <v-row no-gutters>
                <v-col class="pa-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </v-col>
            </v-row>

        </v-col>
    </v-row>

    <v-footer color="white" app padless class="justify-center">
        <v-sheet class="soft-shadow">
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>Dominic</v-list-item-title>
                        <v-list-item-subtitle class="text-caption">
                            <v-icon x-small>
                                mdi-map-marker-outline
                            </v-icon> New York City, New York
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>

                        <v-item-group>

                            <v-item class="mr-4">
                                <v-btn icon>
                                    <v-icon>mdi-phone-outline</v-icon>
                                </v-btn>
                            </v-item>

                            <v-item>
                                <v-btn icon>
                                    <v-icon>mdi-email-outline</v-icon>
                                </v-btn>

                            </v-item>

                        </v-item-group>

                        <!--  <v-btn-toggle
          mandatory
        >
          <v-btn>
            <v-icon>mdi-format-align-left</v-icon>
          </v-btn>
          <v-btn>
            <v-icon>mdi-format-align-center</v-icon>
          </v-btn>

        </v-btn-toggle> -->
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-sheet>
    </v-footer>

    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</div>



</template>

<script>
import {
    Hooper,
    Slide,
    Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    name: 'Pet',
    props: ['slug'],

    mounted() {
        this.getPetDetails();
    },

    components: {
        Hooper,
        Slide,
        HooperPagination
    },

     data() {
        return {
            overlay: false,
            snackbar: false,
            snackbar_text: '',
        }
     },

    methods: {

        getPetDetails() {
            this.overlay = true;
            this.$store.dispatch('petFromSlug', this.slug).then((resp) => {
                this.pet = resp.data;
                this.overlay = false;

            })
            .catch(err => {
                    this.overlay = false;
                    this.snackbar_text = "Oops, Unable to get the pet";
                    this.snackbar = true;
                    this.$router.replace('/home');
            });
        }
    }
}
</script>
