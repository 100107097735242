<template>
<div>
    <v-card  @click.stop="openPetPop" class="mild-shadow rounded-lg" >
        <v-overlay
            :absolute="true"
            :value="overlay"
        >
          <v-btn class="ml-1"  v-if="unblockUserButton" @click="unblockDialog=true;" rounded depressed color="success"><v-icon small >mdi-block-helper</v-icon>&nbsp;Unblock</v-btn>
        </v-overlay>
        <v-img :src="pet.avatar ? pet.avatar.photo : require('@/assets/logo/logo-bg2.jpg')" :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300" class="white--text align-end rounded-lg" gradient="to left bottom , rgba(0,0,0,.01) 50%, rgba(0,0,0,.7)" :class="overlay?'greyscale':''">
            <div class="premium-tag" v-if="pet.is_premium">PREMIUM</div>
            <div class="price-tag" v-if="pet.price > 0">{{ Number(pet.price).toLocaleString() }} {{ pet.price_ccy == null ? '$ USD' : pet.price_ccy }}</div>
            <div class="verified-tag" v-if="pet.video_verified">Verified</div>
            <!-- <v-img :src="'https://picsum.photos/id/'+Math.floor(Math.random() * 100)+'/300/300'" lazy-src="https://picsum.photos/id/11/300/300" cover max-height="300" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1) 30%, rgba(0,0,0,.8)">
 -->
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </template>

            <!-- <div style="position:absolute; top:0px; left:0px; width:100%; height:40px; background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);">
            </div> -->

            <!-- <v-icon dark style="position: absolute; top:8px; left:4px;" size="42" v-show="!pet.is_premium">
                mdi-crown-outline
            </v-icon> -->

            <v-btn fab text small dark absolute top right class="mt-8 mr-n1" @click.stop.prevent="markFavourite" :loading="favLoading" :style="pet.is_favorite ? 'background-color:rgba(0,0,0,0.20);' : 'background-color:rgba(0,0,0,0.20);'">
                <v-icon v-if="pet.is_favorite" color="red">
                    mdi-heart
                </v-icon>
                <v-icon v-else>
                    mdi-heart-outline
                </v-icon>
            </v-btn>

            <v-btn fab text small dark icon absolute top right class="mt-8 mr-12" style="background-color:rgba(0,0,0,0.20);">
                <v-badge color="secondary"  v-if="pet.conversation && $store.getters.getConversation(pet.conversation.xid)" :content="this.$store.getters.getConversation(pet.conversation.xid).unread_messages_count" :value="this.$store.getters.getConversation(pet.conversation.xid).unread_messages_count" overlap>
                    <v-icon>
                        mdi-chat-outline
                    </v-icon>
                </v-badge>
                <v-icon v-else>mdi-chat-outline</v-icon>
            </v-btn>

            <div class="px-2 pb-2">
                <!-- <v-icon dark size="28" v-show="!pet.is_premium">
                mdi-crown
            </v-icon> -->
                <!-- <v-badge bordered color="primary" icon="mdi-crown" :value="pet.is_premium" inline> -->
                    <div class="text-h6 font-weight-medium text-truncate">{{pet.name}} 
                        <v-icon small v-if="pet.purebred" class="white--text">mdi-water-outline</v-icon>
                        <v-icon small v-if="pet.pedigree_verified" class="white--text">mdi-seal-variant</v-icon> 
                        <v-icon small v-if="pet.dna_verified" class="white--text">mdi-dna</v-icon>
                    </div>
                <!-- </v-badge> -->
                <div class="text-truncate text-caption">{{pet.breed}}</div>
                <div class="text-truncate text-caption">{{pet.age ? pet.age.long : ''}} old, {{pet.gender == 'M' ? 'Male' : 'Female'}}</div>
                <div class="text-truncate text-caption">
                    <v-icon dark x-small>
                        mdi-map-marker-outline
                    </v-icon> {{location}}
                </div>
            </div>

        </v-img>
    </v-card>

    <v-dialog v-model="unblockDialog" max-width="400">
      <v-card>
        <v-card-title class="text-subtitle-1">Unblock Member</v-card-title>
        <v-card-text>
          <p >
            Are you sure you want to unblock {{pet.user.name}}?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="grey" @click="unblockDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="unblockUser">Submit</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  <pet-pop v-model="showPetPop" :pet="pet" />
</div>
</template>

<script>
import PetPop from './PetPop.vue'
export default {
    name: 'PetCard',
    props: ['pet'],
    data() {
        return {
            mbu: [],
            mbb: [],
            overlay: false,
            favLoading: false,
            showPetPop: false,

            unblockUserButton: false,
            unblockDialog: false,
            unblockUserXid: '',
        }
    },

    components: {
    PetPop
  },
  mounted() {
    this.mbb = this.$store.getters.userDetails.meBlocked.map(o => {
      return o.bbid;
    });

    this.mbu = this.$store.getters.userDetails.myBlocked.map(o => {
      return o.buid;
    });

    this.overlay = this.mbb.includes(this.pet.user.xid) || this.mbu.includes(this.pet.user.xid);
    this.unblockUserButton = this.mbu.includes(this.pet.user.xid)
  },
  watch: {
    "$store.getters.userDetails": function() {
      this.mbb = this.$store.getters.userDetails.meBlocked.map(o => {
        return o.bbid;
      });

      this.mbu = this.$store.getters.userDetails.myBlocked.map(o => {
        return o.buid;
      });


      this.overlay = this.mbb.includes(this.pet.user.xid) || this.mbu.includes(this.pet.user.xid);
      this.unblockUserButton = this.mbu.includes(this.pet.user.xid)
    },
  },
  computed: {

        location()
        {
            return (this.pet.locality ? this.pet.locality : (this.pet.city ? this.pet.city : this.pet.state)) +  (this.pet.distance > 0 ? ' ('+ Math.round(this.pet.distance) +' Miles)' :'')
        }
    },

    methods: {
        openPetPop()
        {
            if (this.overlay) {
                return false;
            }

            this.showPetPop=true;
            let pet_id = this.pet.xid;
            this.$store.dispatch('logView', {pet_id});
        },

        markFavourite() {
            if (this.overlay) {
                return false;
            }

            let for_id = this.$store.getters.myPetID == null ? 'user' : this.$store.getters.myPetID;

            if (typeof (this.pet.fav_for) !== 'undefined') {
                for_id = this.pet.fav_for
            }

            let pet_id = this.pet.xid;

            this.favLoading = true;
            this.$store.dispatch('markFavorite', {
                    for_id,
                    pet_id,
                }).then(() => {
                    this.favLoading = false;
                })
                .catch(err => {
                    this.favLoading = false;
                    //this.snackbar_text = "Oops, we encountered an error";
                    //this.snackbar = true;
                });

        },
      unblockUser()
      {
        let xid = this.pet.user.xid;

        this.$store.dispatch('unblockUser', {
          xid
        })
            .then((resp) => {
              this.$store.dispatch('profile');
              this.overlay = false;
              this.snackbar_text = "Unblocked successfully";
              this.snackbar = true;
              this.unblockDialog = false;
            })
            .catch(err => {
              this.connecting = false;
              this.snackbar_text = "Error submitting";
              this.snackbar = true;
            });
      }

    }

}
</script>

