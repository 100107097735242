<template>
  <v-bottom-navigation
    grow
    app
    color="primary"
  >
    <v-btn :to="'/home'">
      <span style="font-size:11px; padding-top:3px;">Home</span>
      <v-icon>{{$route.name == 'Home' ? '$vuetify.icons.home-icon' : '$vuetify.icons.home-icon' }}</v-icon>
    </v-btn>

    <!-- <v-btn :to="'/search'">
      <span>Search</span>
      <v-icon>{{$route.name == 'Search' ? '$vuetify.icons.search-icon' : '$vuetify.icons.search-icon' }}</v-icon>
    </v-btn> -->

    <v-btn :to="'/favourites'">
      <span style="font-size:11px; padding-top:3px;">Favorites</span>
      <v-icon>{{$route.name == 'Favourites' ? '$vuetify.icons.heart-icon' : '$vuetify.icons.heart-icon' }}</v-icon>
    </v-btn>

    <v-btn :to="'/messages'">
      
      <span style="font-size:11px; padding-top:3px;">Messages</span>
      <v-badge color="secondary"   :content="this.$store.getters.getTotalMessages" :value="this.$store.getters.getTotalMessages" overlap>
      <v-icon>{{$route.name == 'Messages' ? '$vuetify.icons.messages-icon' : '$vuetify.icons.messages-icon' }}</v-icon>
      </v-badge>
      
    </v-btn>

    <v-btn :to="'/settings'">
      <span style="font-size:11px; padding-top:3px;">Settings</span>
      <v-icon>{{$route.name == 'Settings' ? 'mdi-cog-outline' : 'mdi-cog-outline' }}</v-icon>
    </v-btn>
   

  </v-bottom-navigation>
</template>

<script>
  export default {
    data: () => ({ value: 0 }),


  }
</script>