<template>

<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></g></svg>

<!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17z"></path></g></svg> -->
<!-- <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M424 2169 c-40 -20 -44 -48 -44 -323 l0 -264 -55 -26 c-105 -52 -167
-154 -167 -276 0 -122 62 -224 167 -276 l55 -26 0 -264 c0 -174 4 -271 11
-288 15 -31 59 -50 98 -41 59 13 61 22 61 321 l0 272 54 26 c63 31 120 90 149
155 28 64 28 178 0 243 -29 65 -94 132 -153 157 l-50 22 0 273 c0 246 -2 276
-17 295 -21 25 -78 35 -109 20z m106 -764 c35 -18 65 -59 74 -102 9 -41 -22
-115 -58 -138 -93 -62 -216 4 -216 115 0 104 109 172 200 125z"/>
<path d="M1162 2159 c-204 -79 -257 -358 -98 -513 26 -24 66 -52 91 -62 l45
-17 0 -573 0 -573 26 -20 c34 -27 74 -27 108 0 l26 20 0 573 0 573 45 17 c57
22 130 93 158 154 31 67 31 197 0 264 -67 145 -247 216 -401 157z m194 -174
c61 -40 81 -122 44 -183 -57 -94 -183 -94 -240 0 -73 120 78 261 196 183z"/>
<path d="M2053 2170 c-12 -5 -26 -18 -32 -29 -7 -13 -11 -157 -11 -424 l0
-405 -55 -26 c-144 -71 -210 -253 -145 -404 26 -61 111 -144 165 -162 l35 -12
0 -137 c0 -152 7 -174 61 -186 39 -9 83 10 98 41 6 14 11 79 11 153 l0 128 51
23 c226 106 228 446 3 556 l-54 26 0 399 c0 270 -4 406 -11 422 -18 40 -68 56
-116 37z m107 -1041 c68 -39 92 -118 56 -187 -46 -89 -176 -96 -234 -14 -33
46 -36 91 -10 141 39 76 116 101 188 60z"/>
</g>
</svg> -->
  </template>

<script>
export default {
name: 'filter-icon'
}
</script>