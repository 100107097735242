<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center" v-if="submitting">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <div class="py-8">Processing your payment...</div>
        </v-col>
        
        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='premium success'">
            <div>
                    <span class="text-h5 font-weight-light" style="letter-spacing:1px !important">Congratulations!</span>
                </div>

                 <div class="pa-8 text-center">
                        <v-icon class="golden-chip flip-icon" style="border-radius:60px; padding:15px" color="#d4af37" size="60">mdi-crown</v-icon>
                </div>

                <div class="text-center pb-3">
                    <div class="text-overline premium-title" style="font-size:.9em !important;">Premium Membership Activated</div>
                </div>


                <div class="py-8">

                    <v-btn color="primary" :to="'/home'">
                        Continue
                    </v-btn>
                </div>
        </v-col>

        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='direct connect success'">
            <div>
                    <span class="text-h5 font-weight-light" style="letter-spacing:1px !important">Congratulations!</span>
                </div>

                 <div class="pa-8 text-center">
                        <v-icon size="60" color="amber">$vuetify.icons.coin-icon</v-icon>
                </div>

                <div class="text-center pb-3">
                    <span class="text-overline  primary--text">Direct Connects Credited</span>
                </div>


                <div class="py-8">

                    <v-btn color="primary" :to="'/home'">
                        Continue
                    </v-btn>
                </div>
        </v-col>


        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='failure'">
            <div>
                    <v-icon color="primary" size="80" class="pa-4">mdi-alert-circle-outline</v-icon>
                </div>
                <div class="pa-8">
                    <span class="text-h6 d-block pb-4">Payment Failed</span>
                    <span>We couldn't complete processing your payment</span>
                </div>

                <div class="py-8">

                    <v-btn color="primary"  @click="$store.dispatch('showPremiumPopStripe', true)">
                        Retry with a different gateway
                    </v-btn>
                </div>
        </v-col>

    </v-row>

 
</v-container>
</template>

<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'OrderStatus',
    data() {
        return {
            order_id:'',
            submitting: true,
            status:'',
        }
    },
    mounted() {

        this.order_id = this.$route.query.orderId;

        this.submitting = true;

        let order_id = this.order_id

        axios.get('/v1/fastspring/order/'+order_id).then((resp) => {

                if(resp.data.order_completed == true)
                {
                    if(resp.data.product == 'direct-connects')
                    {
                        this.status = 'direct connect success'

                        window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});

                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-10848052375/NZuMCN2UhqYDEJfJ4LQo',
                            'value': 9.99,
                            'currency': 'USD',
                            'transaction_id': ''
                        });

                        window.gtag('event', 'purchase_done_fastspring', {
                            event_category: 'Purchase',
                            event_label: 'Direct Connect',
                            value: 9.99
                        });

                        window.fbq('track', 'Purchase', {currency: "USD", value: 9.99}); 

                        
                        this.$store.dispatch('profile');
                        
                        setTimeout(() => {
                                this.$router.replace('/home');
                            }, 2000); 
                    }

                    else if(resp.data.product == 'premium-membership-w')
                    {
                        this.status = 'premium success'

                        window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});

                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-10848052375/NZuMCN2UhqYDEJfJ4LQo',
                            'value': 4.99,
                            'currency': 'USD',
                            'transaction_id': ''
                        });

                        window.gtag('event', 'purchase_done_fastspring', {
                            event_category: 'Purchase',
                            event_label: 'One Week Premium',
                            value: 4.99
                        });

                        window.fbq('track', 'Purchase', {currency: "USD", value: 4.99}); 

                        
                        this.$store.dispatch('profile');
                        this.$store.dispatch('getConversations');
                        
                        setTimeout(() => {
                                this.$router.replace('/home');
                            }, 2000); 
                    }

                    else
                    {
                        this.status = 'premium success';

                        let selectedPlan = this.$cookie.get('pm_selected_plan');
                        let selectedValue = this.$cookie.get('pm_selected_value');

                        window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});

                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-10848052375/2kazCJmCuqYDEJfJ4LQo',
                            'value': selectedValue,
                            'currency': 'USD'
                        });

                        window.gtag('event', 'subscription', {
                            event_category: 'Subscription',
                            event_label: 'Premium Membership',
                            value: selectedValue
                        });

                        window.gtag('event', 'subscription_fastspring_'+selectedPlan, {
                            event_category: 'Subscription',
                            event_label: 'Premium Membership',
                            value: selectedValue
                        });

                        window.fbq('track', 'Subscribe', {
                            currency: "USD",
                            value: selectedValue
                        }); 

                        window.twq('event', 'tw-ocimw-ociry', {
                            email_address: this.$store.getters.userDetails.email
                        });

                        window.pintrk('track', 'checkout', {
                            value: selectedValue,
                            currency: 'USD'
                        });

                        window.rdt('track', 'Purchase');

                        

                        this.$store.dispatch('profile');
                        this.$store.dispatch('getConversations');

                        setTimeout(() => {
                            this.$router.replace('/home');
                        }, 2000); 
                    }
                    
                    
                }
                else
                {
                    this.status = 'failure'

                    window.gtag('event', 'subscription_failure_fastspring', {
                                event_category: 'Subscription Failure',
                                event_label: 'Subscription Failure'
                            });
                }

                this.submitting = false;
                
            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)
                this.submitting = false;
            });




    },
    methods: {
        

    }
}
</script>
