<template>
    <v-dialog v-model="$store.getters.chatModel" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable max-width="450"
        transition="dialog-transition" @click:outside="close" :retain-focus="false">

        <v-card>
            <v-toolbar flat dark color="primary" max-height="56">

                <v-btn icon @click.stop="close">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <div>
                    <v-avatar size="38" v-if="pet" @click.stop="toggleDetails">
                        <v-img v-if="pet.avatar" :src="pet.avatar.photo"></v-img>
                        <v-icon v-else>{{ pet.pet_type ?
        '$vuetify.icons.' + pet.pet_type.icon : 'mdi-paw' }}</v-icon>
                    </v-avatar>
                    <v-avatar size="38" v-else @click.stop="toggleDetails">
                        <v-img v-if="(user.photo && ($store.getters.userDetails.is_premium || user.is_premium || user.category == 'adopter' || user.category == 'buyer'))" :src="user.photo"></v-img>
                        <v-icon v-else-if="!user.photo" size="24">$vuetify.icons.account-icon</v-icon>
                        <v-img v-else :src="require('@/assets/img/profile.jpg')"></v-img>
                    </v-avatar>
                </div>


                <v-toolbar-title class="text-subtitle-2 font-weight-medium pl-2 mt-1" @click="toggleDetails">
                    {{ pet ? pet.name : user.name }}

                    <div class="text-caption" v-if="pet && ($store.getters.userDetails.is_premium || pet.user.is_premium)">c/o {{ pet.user.name }}</div>
                    <div class="text-caption blurred-text white-shadow" v-else-if="pet">c/o First Name</div>
                </v-toolbar-title>

                <v-spacer></v-spacer>


                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense nav>

                        <v-list-item @click="showDetails = true">
                            <v-list-item-icon class="mr-1">
                                <v-icon small>mdi-eye-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pr-4">
                                <v-list-item-title>Show Details</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="unarchiveDialog = true" v-if="showUnarchive()">
                            <v-list-item-icon class="mr-1">
                                <v-icon small>mdi-archive-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pr-4">
                                <v-list-item-title>Unarchive</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="archiveDialog = true" v-else>
                            <v-list-item-icon class="mr-1">
                                <v-icon small>mdi-archive-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pr-4">
                                <v-list-item-title>Archive</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>


                    </v-list>
                </v-menu>

            </v-toolbar>
            <div no-gutters class="soft-shadow"
                style="display: flex; flex-direction: column; max-height: 70%; width: 100%; position: absolute; top: 57px; z-index: 1; left:0; right:0; overflow: hidden;">
                <!-- Content section: This section will grow and shrink as needed and will scroll if content overflows -->
                <div style="flex-grow: 1; overflow-y: auto;">
                    <v-expand-transition>
                        <v-card v-show="showDetails" flat>
                            <v-card-text class="pa-0">
                                <div>
                                    <pet-details :pet="pet" :ownerPetId="ownerPetId" v-if="pet"></pet-details>
                                    <user-details :user="user" v-if="!pet"></user-details>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                </div>

                <!-- Bottom section: This section will not grow, ensuring the button stays at the bottom -->
                <div style="flex-shrink: 0;">
                    <v-btn tile color="#fff" depressed block v-show="showDetails" @click="showDetails = false">
                        <v-icon small color="grey">mdi-chevron-double-up</v-icon> <small style="color: grey">HIDE
                            DETAILS</small> <v-icon small color="grey">mdi-chevron-double-up</v-icon>
                    </v-btn>
                </div>

            </div>

            <v-alert border="left" text color="error" class="ma-2" v-if="pet && !pet.active">
                <v-row align="center">
                    <v-col class="grow">
                        <h5 class="font-weight-bold red--text text--darken-1 pb-2">Not Available:</h5>
                        <div class="text-caption">The pet owner has removed the post; the pet is no longer available.
                        </div>
                    </v-col>
                </v-row>
            </v-alert>



            <v-alert v-else text dense border="left" type="error" class="text-caption ma-2" icon="mdi-shield-alert">
                <b>Be Alert:</b> Beware of scammers. Watch out for potential scams and never engage in financial
                transactions without verifying the other party. <a href="javascript:void(0);"
                    @click="reportSupportDialog = true"
                    class="red--text font-weight-bold text-decoration-underline">Report</a> suspicious activity
                immediately.
                Keep our community safe!
            </v-alert>
            <v-card-text style="height:600px;overflow-y: auto" v-if="loading">

                <v-row>
                    <v-col cols="12" justify="center" align="center" class="mt-5">
                        <!-- <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular> -->
                        <img :src="$loaderImageUrl" height="100" alt="Loading..." />
                    </v-col>

                </v-row>

            </v-card-text>
            <v-card-text v-else style="height:600px;overflow-y: auto" ref='messageList' class="pt-4">
                <div v-if="localConversation && $store.getters.getConversation(localConversation.xid)" :key="key">
                    <v-row v-for="(item, index) in $store.getters.getConversation(localConversation.xid).messages"
                        :key="index" :justify="item.fuid == $store.getters.userDetails.xid ? 'end' : 'start'">
                        <v-col cols="10">
                            <div class="text-caption pa-1" v-show="item.fuid != $store.getters.userDetails.xid">
                                {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}</div>

                            <v-sheet v-if="item.message == 'LOCKED'" color="#edf0f5"
                                class="rounded-tl-0 rounded-xl pa-4 justify-center align-center d-flex flex-column">
                                <v-skeleton-loader class="mx-auto mb-2" boilerplate width="100%"
                                    type="sentences"></v-skeleton-loader>
                                <v-btn rounded small depressed color="success" @click="triggerViewMessage(item.xid)">
                                    <v-icon left>mdi-email-open</v-icon> View Message
                                </v-btn>
                            </v-sheet>

                            <v-sheet v-else :color="item.fuid == $store.getters.userDetails.xid ? 'primary' : '#edf0f5'"
                                :dark="item.fuid == $store.getters.userDetails.xid"
                                :class="item.fuid == $store.getters.userDetails.xid ? 'rounded-br-0 rounded-xl pa-4' : 'rounded-tl-0 rounded-xl pa-4'">
                                <span class="text-pre-wrap" v-if="item.message_type == 'text'">{{ item.message }}</span>
                                <v-img v-else class="ml-auto rounded-xl" max-height="200px" :src="item.photo.photo"
                                    cover aspect-ratio="1" @click="showPictureModal(item.photo.photo)"></v-img>
                                <v-alert dark @click="showPremium"
                                    v-if="item.message.indexOf('##***Contact Details***##') !== -1 && item.fuid != $store.getters.userDetails.xid"
                                    color="#2196f3" prominent dense icon="mdi-lock-outline"
                                    class="text-caption mt-2 mb-n2 mx-n2 rounded-xl">Upgrade to Premium to reveal
                                    contact details.</v-alert>
                                <v-alert @click="showPremium"
                                    v-if="item.message.indexOf('##***Contact Details***##') !== -1 && item.fuid == $store.getters.userDetails.xid"
                                    color="purple darken-4" prominent dense icon="mdi-lock-outline"
                                    class="text-caption mt-2 mb-n1 mx-n2 rounded-xl">Upgrade to Premium Membership to
                                    share contact details.</v-alert>
                                <v-alert @click="showPremium" v-if="!item.verified" prominent dense icon="mdi-lock-outline"
                                    color="purple darken-4"
                                    class="text-caption mt-2 mb-n2 mx-n2 rounded rounded-l-xl">{{ pet ?
        pet.name :
        user.name }} can't view this message. Upgrade to Premium Membership for seamless
                                    conversations.
                                    <div class="text-right"><v-btn class="mt-2 mb-1" small color="primary">UPGRADE NOW</v-btn></div>
                                </v-alert>
                            </v-sheet>

                            <div class="text-caption text-right pa-1"
                                v-show="item.fuid == $store.getters.userDetails.xid">
                                {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}

                                <span class="pl-2" v-if="item.verified && !item.read" @click="legendDialog = true">
                                    <v-icon small color="green">
                                        mdi-check
                                    </v-icon>
                                </span>
                                <span class="pl-2" v-if="item.read" @click="legendDialog = true">
                                    <v-icon small color="blue">
                                        mdi-check-all
                                    </v-icon>
                                </span>

                            </div>
                        </v-col>
                    </v-row>

                </div>

            </v-card-text>
            <v-card-actions style="display:grid">

                <div class="text-center" v-if="user.banned">
                    <v-chip color="error">Account has been banned</v-chip>
                </div>

                <div class="text-center" v-else-if="user.deleted">
                    <v-chip>Account has been closed</v-chip>
                </div>

                <div v-else>
                    <div class="mb-2" v-if="!localConversation">
                        <div v-if="pet">
                            <v-chip color="primary" outlined class="mr-2 mb-2"
                                @click="quickMessage('i_am_interested')">I'm
                                interested</v-chip>
                            <v-chip color="primary" outlined class="mr-2 mb-2"
                                @click="quickMessage('is_it_available')">Is
                                {{ pet.gender == 'M' ? 'he' : 'she' }}
                                available?</v-chip>
                        </div>
                        <div v-else>
                            <v-chip color="primary" outlined class="mr-2 mb-2"
                                @click="quickMessage('are_you_interested')">Are you
                                interested?</v-chip>
                        </div>

                    </div>
                    <v-form ref="form" @submit.prevent="sendMessage"
                        v-if="!hideSendMessage() && !blockedByUsersMessageTxt && !blockedUsersMessageTxt">
                        <v-textarea rows="1" v-model="message" auto-grow solo placeholder="Send message..." type="text"
                            color="primary" hide-details flat background-color="#edf0f5"
                            class="rounded-xl chat-compose-input" append-icon="mdi-send" @click:append="sendMessage"
                            :append-outer-icon="appendOuter" @click:append-outer="launchAttachImage" :disabled="sending"
                            :loading="sending">

                        </v-textarea>

                        <input type="file" ref="messageImage" name="messageImage" @change="loadImage($event)"
                            style="display:none" accept="image/png, image/jpg, image/jpeg">

                        <v-dialog v-model="cropDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                            <v-card color="black">
                                <v-toolbar flat dark color="black">
                                    <v-btn icon @click="cropDialog = false" color="grey">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>

                                    <v-btn icon color="white" @click="holdPictureMessage" :loading="psubmitting"
                                        :disabled="psubmitting">
                                        <v-icon>mdi-check</v-icon>
                                    </v-btn>

                                </v-toolbar>
                                <v-card-text class="pa-0">
                                    <cropper ref="cropper" :src="pictureFile" :canvas="{
        height: 600,
        width: 800
    }" :stencil-props="{
        aspectRatio: 8 / 6,
        movable: true,
        scalable: true,
    }" image-restriction="stencil" />

                                </v-card-text>
                            </v-card>
                        </v-dialog>

                    </v-form>

                    <v-dialog v-model="pictureDialog" max-width="400">
                        <v-card>
                            <v-toolbar flat>
                                <v-toolbar-title class="text-subtitle-1">Add to Profile?</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="pictureDialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>

                            <v-card-text>
                                <p>
                                    Would you like to attach this picture to your pet's profile?
                                </p>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text color="grey" @click="sendPictureMessage(0)"
                                    :loading="msubmitting">No</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="sendPictureMessage(1)" :loading="psubmitting">Yes</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <div class="text-center" v-if="blockedUsersMessageTxt">
                        <v-chip class="ma-2">{{ blockedUsersMessageTxt }}</v-chip>
                        <v-btn @click="unblockDialog = true;" outlined rounded small color="success"
                            style="background-color: #eef9e9;"><v-icon
                                small>mdi-block-helper</v-icon>&nbsp;Unblock</v-btn>
                    </div>
                    <div class="text-center" v-else-if="blockedByUsersMessageTxt">
                        <v-chip>{{ blockedByUsersMessageTxt }}</v-chip>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="unblockDialog" max-width="400">
            <v-card>
                <v-card-title class="text-subtitle-1">Unblock Member</v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to unblock {{ user.name }}?
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="unblockDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="unblockUser">Submit</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="archiveDialog" max-width="400">
            <v-card>
                <v-card-title class="text-subtitle-1">Archive Conversation</v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to archive this conversation?
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="archiveDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="archiveConversation(conversation)" :loading="archiving">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="unarchiveDialog" max-width="400">
            <v-card>
                <v-card-title class="text-subtitle-1">Unarchive Conversation</v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to unarchive this conversation?
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="unarchiveDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="unarchiveConversation(conversation)" :loading="archiving">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="legendDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Message Status</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="legendDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">
                    <!-- <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="orange">mdi-timer-sand</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">
                            <div class="orange--text">Pending</div> <span class="text-caption">Message queued for manual
                                review.</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="!this.$store.getters.isPremium">
                        <v-col>
                            <v-alert text dense class="text-caption text-center mb-0 pa-4">Premium Membership ensures your
                                messages are prioritized and delivered instantly.
                                <div class="text-center mt-3 mb-2">
                                    <v-btn small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon>
                                        UPGRADE TO PREMIUM</v-btn>
                                </div>
                            </v-alert>
                        </v-col>
                    </v-row> -->

                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="green">mdi-check</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">
                            <div class="green--text">Delivered</div> <span class="text-caption">Message has been
                                delivered
                                but not yet read.</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="blue">mdi-check-all</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">
                            <div class="blue--text">Read</div> <span class="text-caption">Message has been read by the
                                other
                                party.</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="legendDialog = false">OK</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>



        <v-dialog v-model="msgLimitDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">You hit a limit!</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="msgLimitDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">

                    <div class="text-center pb-6">
                        <v-icon color="grey lighten-2" size="80">mdi-chat-alert-outline</v-icon>
                    </div>

                    <div class="text-center">
                        <p class="text-subtitle-2 font-weight-bold">You have reached your daily quota of 3 messages.
                        </p>
                        <p class="text-caption">Please wait till tomorrow to send more messages or join our <a
                                @click="showPremium">Premium Membership</a> program for unlimited messages.</p>
                        <div class="text-center mt-3 mb-2">
                            <v-btn small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon>
                                UPGRADE TO
                                PREMIUM</v-btn>
                        </div>
                    </div>



                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="msgLimitDialog = false">OK</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>


        <v-dialog v-model="profileVerificationReqDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Profile Verification Pending</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="profileVerificationReqDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>

                    <div>

                        <p class="text-caption pb-4">To ensure a safe and trustworthy experience for all users, please
                            complete your profile verification to connect with other members.</p>

                        <v-alert dense text type="success" class="text-caption"
                            v-if="this.verifications.email_verified">
                            Email Address Verified
                        </v-alert>

                        <v-alert dense text type="error" class="text-caption" v-else  style="cursor: pointer"
                                @click="openVerifyContact">
                                <v-row align="center">
                                    <v-col class="text-caption" cols="11">
                                        Email Address Not Verified
                                    </v-col>
                                    <v-col class="ustify-end" cols="1">
                                        <v-icon class="float-right" color="red">mdi-chevron-right</v-icon>
                                    </v-col>
                                </v-row>
                        </v-alert>

                        <v-alert dense text type="success" class="text-caption"
                            v-if="this.verifications.phone_verified">
                            Phone Verified
                        </v-alert>

                        <v-alert dense text type="error" class="text-caption" v-else  style="cursor: pointer"
                                @click="openVerifyContact">
                                <v-row align="center">
                                    <v-col class="text-caption" cols="11">
                                        Phone Not Verified
                                    </v-col>
                                    <v-col class="ustify-end" cols="1">
                                        <v-icon class="float-right" color="red">mdi-chevron-right</v-icon>
                                    </v-col>
                                </v-row>
                        </v-alert>

                        <div
                            v-if="this.$store.getters.userDetails.category == 'adopter' || this.$store.getters.userDetails.category == 'buyer'">

                            <v-alert dense text type="success" class="text-caption"
                                v-if="this.verifications.questionnaire_verified == 'verified'">
                                Profile Questionnaire Verified
                            </v-alert>

                            <v-alert dense text type="info" class="text-caption"
                                v-else-if="this.verifications.questionnaire_verified == 'submitted'">
                                Profile Questionnaire Pending Verification
                            </v-alert>

                            <v-alert dense text type="error" class="text-caption" v-else style="cursor: pointer"
                                @click="$router.replace('/adopter-questionnaire');">
                                <v-row align="center">
                                    <v-col class="text-caption" cols="11">
                                        Profile Questionnaire to be Submitted
                                    </v-col>
                                    <v-col class="ustify-end" cols="1">
                                        <v-icon class="float-right" color="red">mdi-chevron-right</v-icon>
                                    </v-col>
                                </v-row>


                            </v-alert>

                        </div>

                        <div
                            v-if="(this.$store.getters.userDetails.category == 'adopter' || this.$store.getters.userDetails.category == 'buyer') && this.verifications.video_verified">

                            <v-alert dense text type="success" class="text-caption"
                                v-if="this.verifications.video_verified == 'verified'">
                                Profile Video Verified
                            </v-alert>

                            <v-alert dense text type="info" class="text-caption"
                                v-else-if="this.verifications.video_verified == 'submitted'">
                                Profile Video Pending Verification
                            </v-alert>

                            <v-alert dense text type="error" class="text-caption" v-else style="cursor: pointer"
                                @click="openVerifyProfile">
                                <v-row align="center">
                                    <v-col class="text-caption" cols="11">
                                        Profile Video to be Submitted
                                    </v-col>
                                    <v-col class="ustify-end" cols="1">
                                        <v-icon class="float-right" color="red">mdi-chevron-right</v-icon>
                                    </v-col>
                                </v-row>
                                
                            </v-alert>

                        </div>

                    </div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="profileVerificationReqDialog = false">OK</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>


        <v-dialog v-model="noContactDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Cannot Share Contact</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="noContactDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">

                    <div class="text-center pb-6">
                        <v-icon color="grey lighten-2" size="80">mdi-phone-off-outline</v-icon>
                    </div>

                    <div class="text-center">
                        <p>Contact details can only be shared if you're a premium member or if you're communicating with
                            one.</p>

                        <div class="text-center mt-8 mb-2">
                            <v-btn small class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon>
                                UPGRADE TO
                                PREMIUM</v-btn>
                        </div>

                    </div>


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="noContactDialog = false">OK</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="premiumReqDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="premiumReqDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>

                    <div class="text-center">
                        <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                            large>mdi-crown</v-icon>

                    </div>

                    <div class="text-center pt-8">
                        <p>For full messaging access, consider our Premium Membership.</p>

                        <p>Upgrading to our Premium Membership allows you to freely send and receive messages with any
                            member on Petmeetly</p>

                        <div class="text-center my-8">
                            <v-btn class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                                PREMIUM</v-btn>
                        </div>

                        <p class="text-caption">Note: You can communicate with any <strong class="black--text">Premium
                                Member</strong> without upgrading.</p>
                    </div>


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="premiumReqDialog = false">OK</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="viewMessageDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="viewMessageDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>

                    <div class="text-center">
                        <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                            large>mdi-crown</v-icon>

                    </div>

                    <div class="text-center pt-8">
                        <p>For full messaging access, consider our Premium Membership.</p>

                        <p>Upgrading to our Premium Membership allows you to freely send and receive messages with any
                            member on Petmeetly</p>

                        <div class="text-center my-8">
                            <v-btn class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                                PREMIUM</v-btn>
                        </div>

                        <div v-show="this.$store.getters.userDetails.mcredits > 0">
                        <v-divider class="mb-4"></v-divider>
                        <div>
                            <p class="text-caption">Or you may unlock this message using message credits</p>
                        </div>

                        <div>
                            <v-btn small color="primary" :loading="unlocking"
                                :disabled="unlocking || this.$store.getters.userDetails.mcredits < 1"
                                @click="unlockConfirm = true">UNLOCK THIS MESSAGE</v-btn>
                        </div>

                        <p class="text-caption pt-2">You have <strong class="black--text">{{
        this.$store.getters.userDetails.mcredits }}</strong> message credit<span
                                v-show="this.$store.getters.userDetails.mcredits != 1">s</span>. 
                                <!-- <span
                                style="cursor:pointer" class="info--text"
                                @click="$store.dispatch('showMessageCreditPop', true);">Get Credits.</span> -->
                        </p>
                    </div>
                    </div>


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="viewMessageDialog = false">OK</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="unlockConfirm" max-width="400">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to use a message credit to Unlock this message
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="unlockConfirm = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="unlockMessage" :loading="unlocking" :disabled="unlocking">Yes</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>


        <v-dialog v-model="reportSupportDialog" max-width="400">
            <v-card>

                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Report suspicious activity</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="reportSupportDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">
                    <p>
                        Please forward all details and any reasons for suspicion of fraudulent activity to our support
                        team
                        at <strong>support@petmeetly.com</strong>
                    </p>
                    <p>
                        Our team will thoroughly investigate your report and take the appropriate action as needed.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="reportSupportDialog = false">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="showPicture" fullscreen scrollable>
            <v-card>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showPicture = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-img :src="pictureUrl" contain></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import PetDetails from "./ChatPetDetail.vue";
import UserDetails from "./ChatUserDetail.vue";

import {
    Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';

export default {
    name: 'Chat',
    props: ['value', 'pet', 'user', 'conversation', 'identifier', 'source'],
    mounted() {
        //console.log('Chat mounted');
        //console.log(this.conversation);
        //Check for

        this.$root.$on('close-chat', this.closeChat);

        if (this.conversation) {
            this.getMessages();
        }

        if (this.localConversation && this.localConversation.parties) {
            if (this.localConversation.parties[0].user.xid == this.$store.getters.userDetails.xid && this.localConversation.parties[0].pet) {
                this.appendOuter = 'mdi-image-plus';
                this.ownerPetId = this.localConversation.parties[0].pid;
            } else if (this.localConversation.parties[1].user.xid == this.$store.getters.userDetails.xid && this.localConversation.parties[1].pet) {
                this.appendOuter = 'mdi-image-plus';
                this.ownerPetId = this.localConversation.parties[1].pid;
            }
        }

        /* List of users blocked by the current user */
        let mbu = this.$store.getters.userDetails.myBlocked.map(o => {
            return o.buid;
        });

        this.blockedUsersMessage(mbu);
        this.blockedByUsersMessage();
        this.checkCanMessage();
    },
    components: {
        PetDetails,
        UserDetails,
        Cropper
    },

    updated() {
        setTimeout(() => {
            this.scrollToEnd();

        }, 300);
    },

    computed: {
        show: {
            get() {
                if (this.value && this.conversation && this.conversation.unread_messages_count > 0) {
                    this.$store.dispatch('markRead', this.conversation.xid);
                }
                return this.value
            },
            set(value) {
                if (value === false) {
                    this.$store.dispatch('sortConversations');
                }
                this.$emit('input', value)
            }
        },
        blockedByUsers() { /* List of users blocked the current user */
            return this.$store.getters.userDetails.meBlocked ? this.$store.getters.userDetails.meBlocked.map(o => {
                return o.bbid;
            }) : [];
        },

        blockedUsers() { /* List of users blocked by the current user */
            return this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
                return o.buid;
            }) : [];
        }
    },
    data() {
        return {
            archiveDialog: false,
            unarchiveDialog: false,
            showPicture: false,
            pictureUrl: '',
            ownerPetId: '',
            appendOuter: '',
            msubmitting: false,
            psubmitting: false,
            pictureDialog: false,
            pictureFile: false,
            uploadedFile: null,
            unblockDialog: false,
            unblockUserXid: '',
            blockedUsersMessageTxt: '',
            blockedByUsersMessageTxt: '',
            showDetails: false,
            legendDialog: false,
            msgLimitDialog: false,
            noContactDialog: false,
            reportSupportDialog: false,
            premiumReqDialog: false,
            viewMessageDialog: false,
            message: '',
            blockLoading: false,
            loading: false,
            sending: false,
            snackbar: false,
            snackbar_text: '',
            messages: [],
            localConversation: this.conversation ? this.$store.getters.getConversation(this.conversation.xid) : null,
            key: '',
            cropDialog: false,
            message_xid: null,
            unlocking: false,
            unlockConfirm: false,
            archiving: false,
            profileVerificationReqDialog: false,

            verifications: {
                email_verified: false,
                phone_verified: false,
                questionnaire_verified: null,
                video_verified: null
            },
            canMessage: true,

        }
    },
    watch: {
        "$store.getters.userDetails": function () {
            this.forceUpdateMessage();
        },
    },
    beforeDestroy() {
        // Clean up the event listener
        // console.log('beforeDestroy')
        this.$root.$off('close-chat', this.closeChat);
    },
    methods: {
        showPictureModal(picUrl) {
            this.pictureUrl = picUrl;
            this.showPicture = true;
        },
        launchAttachImage() {
            this.$refs.messageImage.click();
        },
        loadImage(event) {
            var input = event.target;

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.pictureFile = e.target.result;
                    this.cropDialog = true;
                    //this.uploadedFile=input.files[0];
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        forceUpdateMessage() {
            this.hideSendMessage();

            let mbu = this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
                return o.buid;
            }) : [];
            this.blockedUsersMessage(mbu);
            this.blockedByUsersMessage();


        },
        hideSendMessage() {

            /* If the other party in in any of these lists */
            return this.blockedByUsers.includes(this.user.xid) || this.blockedUsers.includes(this.user.xid);

            /* if(this.localConversation) {
                if (this.localConversation.parties[0].user.xid == this.$store.getters.userDetails.xid) {
                  return this.blockedByUser.includes(this.localConversation.parties[1].user.xid);
                } else {
                  return this.blockedByUser.includes(this.localConversation.parties[0].user.xid);
                }
            } */
        },

        blockedUsersMessage(mbu) {
            this.blockedUsersMessageTxt = mbu.includes(this.user.xid) ? 'You have blocked ' + this.user.name : '';
            this.unblockUserXid = this.user.xid;
        },
        blockedByUsersMessage() {
            this.blockedByUsersMessageTxt = this.blockedByUsers.includes(this.user.xid) ? 'You have been blocked by the user' : '';
        },
        toggleDetails() {
            this.showDetails = !this.showDetails
        },

        getMessages() {

            if (!this.conversation.xid) {
                return false;
            }

            this.loading = true;
            this.$store.dispatch('getMessages', this.conversation.xid).then((resp) => {
                let cid = this.conv_id;

                setTimeout(() => {
                    this.key = (new Date()).getTime();
                    this.loading = false;
                    this.scrollToEnd();
                    if (this.conversation && this.conversation.unread_messages_count > 0) {
                        this.$store.dispatch('markRead', this.conversation.xid);
                    }
                }, 300);
            })
                .catch(err => {
                    //console.log(err);
                    this.loading = false;
                });

        },

        holdPictureMessage() {
            this.psubmitting = true;
            const { canvas } = this.$refs.cropper.getResult();
            if (canvas) {
                canvas.toBlob((blob) => {
                    // Convert blob to File object
                    this.uploadedFile = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });
                    this.psubmitting = false;
                    this.cropDialog = false;
                    this.pictureDialog = true;


                }, "image/jpeg");
            }
        },

        sendPictureMessage(publicUpload) {

            if (this.canMessage == false) {
                if (this.$store.getters.userDetails.banned == 1) {
                    this.snackbar_text = "Error sending message...";
                    this.snackbar = true;
                }
                else {
                    this.profileVerificationReqDialog = true;
                }
            }
            else {

                if (this.psubmitting == false && this.msubmitting == false) {
                    if (publicUpload) {
                        this.psubmitting = true;
                    } else {
                        this.msubmitting = true;
                    }

                    let fuid = this.$store.getters.userDetails.xid;
                    let tuid = this.user.xid;

                    let formData = new FormData();

                    formData.append('fuid', fuid);
                    formData.append('tuid', tuid);
                    formData.append('photo', this.uploadedFile);
                    formData.append('pet_id', this.ownerPetId);
                    formData.append('public', publicUpload);

                    if (this.localConversation) {
                        //Add message to exisitng conversation

                        let cid = this.localConversation.xid;

                        formData.append('cid', this.localConversation.xid);

                        this.$store.dispatch('sendPictureMessage', formData)
                            .then((resp) => {
                                if (publicUpload) {
                                    this.psubmitting = false;
                                } else {
                                    this.msubmitting = false;
                                }
                                this.message = '';
                                this.pictureDialog = false;
                                setTimeout(() => {
                                    this.scrollToEnd();
                                }, 300);
                            })
                            .catch(err => {
                                console.log(err);
                                this.psubmitting = false;
                                this.msubmitting = false;
                                this.message = '';
                                this.pictureDialog = false;
                                if(err.response.data.message)
                                {
                                    this.snackbar_text = "Error sending message: "+err.response.data.message;
                                }
                                else
                                {
                                    this.snackbar_text = "Error sending message...";
                                }
                                
                                this.snackbar = true;
                            });
                    } else {
                        //Create new conversation
                        /*if (this.$store.getters.myPetID) {
                            formData.append('fpid', this.$store.getters.myPetID);
                        }
    
                        if (this.pet) {
                            formData.append('tpid', this.pet ? this.pet.xid : null);
                        }
    
                        this.$store.dispatch('newPictureConversation', formData)
                        .then((resp) => {
                            this.localConversation = resp;
                            this.psubmitting = false;
                            this.message = '';
                            this.pictureDialog = false;
                            this.scrollToEnd();
                        })
                        .catch(err => {
                            this.psubmitting = false;
                        });*/
                    }
                }
            }
        },

        checkCanMessage() {

            let cxid = this.localConversation ? this.localConversation.xid : null;

            this.$store.dispatch('canMessage', { cxid })
                .then((resp) => {
                    this.canMessage = resp.data.all_verified;
                    this.verifications = resp.data;
                })
                .catch(err => {

                });
        },


        sendMessage() {



            if (this.canMessage == false) {
                if (this.$store.getters.userDetails.banned == 1) {
                    this.snackbar_text = "Error sending message...";
                    this.snackbar = true;
                }
                else {
                    this.profileVerificationReqDialog = true;
                }
            }
            else {


                if (this.message == '' || this.message.length < 9) {

                    this.snackbar_text = "Your message is too short";
                    this.snackbar = true;

                } else {

                    if (this.$store.getters.userDetails.is_premium == 1 || this.user.is_premium == 1) {
                        //this.message = this.message
                    } else {
                        /* var emailExp = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/img;
                        var phoneExp = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img;
    
                        var msg = this.message.replace(emailExp, '##***Contact Details***##');
                        msg = msg.replace(phoneExp, '##***Contact Details***##');
    
                        if (msg.indexOf('##***Contact Details***##') !== -1) {
                            this.noContactDialog = true;
                            return;
    
                        } */

                    }

                    if (this.sending == false) {
                        this.sending = true;

                        /* this.$store.dispatch('checkMessageLimit')
                            .then((resp) => {
                                var msg_limit = resp.limit
                                if (msg_limit <= 0) {
                                    this.msgLimitDialog = true
                                    this.sending = false;
                                    return;
    
                                } else { */

                        if (this.localConversation) {
                            //Add message to exisitng conversation

                            let fuid = this.$store.getters.userDetails.xid;
                            let tuid = this.user.xid;
                            let cid = this.localConversation.xid;
                            let message = this.message;

                            this.$store.dispatch('sendMessage', {
                                fuid,
                                tuid,
                                cid,
                                message
                            })
                                .then((resp) => {
                                    this.sending = false;
                                    this.message = '';
                                    setTimeout(() => {
                                        this.scrollToEnd();
                                    }, 300);
                                    //this.$store.dispatch('setMessageLimit', msg_limit - 1);
                                })
                                .catch(err => {
                                    //console.log(err);
                                    this.sending = false;
                                });

                        } else {
                            //Create new conversation

                            let fuid = this.$store.getters.userDetails.xid;
                            let tuid = this.user.xid;
                            let fpid = this.$store.getters.myPetID;
                            let tpid = this.pet ? this.pet.xid : null;
                            let message = this.message;

                            this.$store.dispatch('newConversation', {
                                fuid,
                                tuid,
                                fpid,
                                tpid,
                                message
                            })
                                .then((resp) => {
                                    this.localConversation = resp;
                                    this.sending = false;
                                    this.message = '';
                                    this.scrollToEnd();
                                    //this.$store.dispatch('setMessageLimit', msg_limit - 1);
                                })
                                .catch(err => {
                                    //console.log(err);
                                    this.sending = false;
                                });

                        }
                        /*     }
                        })
                        .catch(err => {
                            this.sending = false;
                        }); */

                    }

                }
            }

        },
        showSubscription() {

        },
        scrollToEnd() {
            var container = this.$refs.messageList;
            if (container)
                container.scrollTop = container.scrollHeight;

        },

        goToPet(xid) {
            this.$router.push({
                name: 'Pet',
                params: {
                    id: xid
                }
            })
        },

        showPremium() {
            this.premiumReqDialog = false;
            this.$store.dispatch('showPremiumPop', true);
        },
        unblockUser() {
            let xid = this.unblockUserXid;

            this.$store.dispatch('unblockUser', {
                xid
            })
                .then((resp) => {
                    this.$store.dispatch('profile');
                    this.snackbar_text = "Unblocked successfully";
                    this.snackbar = true;
                    this.unblockDialog = false;
                })
                .catch(err => {
                    this.connecting = false;
                    this.snackbar_text = "Error submitting";
                    this.snackbar = true;
                });
        },

        triggerViewMessage(mxid) {
            this.message_xid = mxid
            this.viewMessageDialog = true

        },
        unlockMessage() {
            let mxid = this.message_xid
            let cxid = this.conversation.xid;
            this.unlocking = true;
            this.unlockConfirm = false;

            this.$store.dispatch('unlockMessage', {
                mxid, cxid
            })
                .then((resp) => {
                    this.$store.dispatch('profile');
                    this.getMessages();
                    this.snackbar_text = resp.data.message;
                    this.snackbar = true;
                    this.viewMessageDialog = false;
                    this.unlocking = false;
                })
                .catch(err => {
                    this.unlocking = false;
                    this.snackbar_text = "Error unlocking";
                    this.snackbar = true;
                });

        },

        quickMessage(msg) {

            if (this.canMessage == false) {
                if (this.$store.getters.userDetails.banned == 1) {
                    this.snackbar_text = "Error sending message...";
                    this.snackbar = true;
                }
                else {
                    this.profileVerificationReqDialog = true;
                }
            }
            else {

                this.sending = true;

                let fuid = this.$store.getters.userDetails.xid;
                let tuid = this.user.xid;
                let fpid = this.$store.getters.myPetID;
                let tpid = this.pet ? this.pet.xid : null;
                let message = msg;

                this.$store.dispatch('newConversation', {
                    fuid,
                    tuid,
                    fpid,
                    tpid,
                    message
                })
                    .then((resp) => {
                        this.localConversation = resp;
                        this.sending = false;
                        this.message = '';
                        this.scrollToEnd();
                        //this.$store.dispatch('setMessageLimit', msg_limit - 1);
                    })
                    .catch(err => {
                        //console.log(err);
                        this.sending = false;
                    });
            }
        },
        archiveConversation(item) {
            //this.show = false
            this.archiving = true;
            this.$store.dispatch('archiveConversation', item.xid).then((resp) => {
                this.archiveDialog = false;
                this.archiving = false;
                this.close();

            });
        },
        unarchiveConversation(item) {
            this.archiving = true;
            this.$store.dispatch('unarchiveConversation', item.xid).then((resp) => {
                this.archiveDialog = false;
                this.archiving = false;
                this.close();
            });
        },
        showUnarchive() {
            if (this.localConversation) {
                return this.localConversation.parties[0].archived && this.localConversation.parties[0].uid == this.$store.getters.userDetails.xid
                    || this.localConversation.parties[1].archived && this.localConversation.parties[1].uid == this.$store.getters.userDetails.xid
            }
        },
        /* close() {
            this.$store.dispatch('showChatModel', false);
            this.$store.dispatch('sortConversations');
            this.$destroy();
            //console.log('Destroy')
        }, */
        openVerifyProfile()
        {
            this.close();
            this.$store.dispatch('showVerifyProfilePop', true)
        },
        openVerifyContact()
        {
            this.close();
            this.$store.dispatch('showVerifyContactPop', true)
        },
        closeChat(identifier) {
            if (this.identifier === identifier) {
                this.$store.dispatch('showChatModel', false);
                this.$store.dispatch('setActiveChatIdentifier', null);
            }
        },
        close() {
            this.$store.dispatch('showChatModel', false);
            this.$store.dispatch('setActiveChatIdentifier', null);
            this.$store.dispatch('sortConversations');
        }

    }
}
</script>
<style>
.showbtn {
    margin: auto;
    top: -25px;
    z-index: 0
}

.hidebtn {
    margin: auto;
    bottom: -23px;
    z-index: 0;
    position: sticky;
    left: 0;
    right: 0
}

.zindex1 {
    z-index: 1
}
</style>

<style scoped lang="scss">
::v-deep .v-alert--prominent .v-alert__icon.v-icon {
    font-size: 20px !important;
    height: 36px;
    min-width: 36px;
}
</style>