<template>

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="128.000000pt" height="128.000000pt" viewBox="0 0 128.000000 128.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M545 1267 c-69 -19 -128 -50 -159 -87 -28 -35 -32 -45 -15 -55 6 -4
26 9 44 29 50 55 113 78 228 84 224 11 336 -70 337 -240 0 -64 -1 -68 -23 -68
-31 0 -123 28 -168 50 -19 10 -53 37 -76 60 l-40 42 -50 -50 c-52 -52 -127
-87 -208 -99 -38 -5 -44 -3 -50 17 -4 12 -5 47 -3 78 2 45 0 57 -12 57 -21 0
-32 -79 -20 -145 7 -35 7 -56 1 -58 -27 -9 -60 -45 -66 -71 -8 -35 16 -87 44
-96 20 -6 31 -33 31 -76 0 -14 13 -46 30 -72 33 -52 24 -61 -22 -22 -19 16
-42 25 -65 25 -30 0 -46 -10 -107 -70 -79 -77 -80 -86 -17 -125 28 -18 30 -24
24 -50 -11 -48 -8 -147 6 -185 53 -139 34 -133 406 -137 298 -4 300 -4 339 19
60 35 85 92 93 210 4 72 3 101 -7 113 -19 23 -30 8 -30 -41 l0 -44 -60 0 -60
0 0 -110 0 -110 -115 0 c-63 0 -115 3 -115 8 0 4 7 16 15 26 8 11 15 33 15 49
0 15 4 37 10 47 13 24 15 66 6 116 l-7 42 47 7 c58 8 91 40 100 95 7 40 8 41
38 34 40 -8 85 -31 113 -57 21 -18 23 -19 29 -3 8 22 -17 49 -69 74 l-39 19
27 17 c26 17 27 17 62 -15 43 -37 128 -79 162 -79 22 0 23 3 18 38 -14 86 -40
147 -77 184 -21 21 -47 38 -59 38 -12 0 -21 6 -21 15 0 8 6 15 14 15 23 0 56
50 56 85 0 30 -39 85 -61 85 -4 0 -5 37 -2 83 10 148 -44 242 -165 288 -71 26
-233 35 -307 16z m177 -287 c51 -39 133 -72 210 -85 36 -7 36 -7 40 -63 7
-104 -7 -220 -29 -250 -35 -48 -77 -64 -173 -70 -89 -5 -89 -5 -125 27 -45 39
-91 42 -133 6 l-30 -25 -32 23 c-68 45 -75 65 -78 211 l-4 132 66 13 c79 16
172 59 205 95 14 14 26 26 28 26 1 0 26 -18 55 -40z m-392 -186 c0 -40 -2 -45
-15 -34 -18 15 -19 34 -5 61 16 29 20 23 20 -27z m708 -7 c-5 -52 -28 -45 -28
8 0 42 2 46 16 35 9 -8 14 -25 12 -43z m13 -157 c22 -12 52 -67 67 -122 9 -33
8 -38 -7 -38 -24 0 -107 54 -120 78 -8 15 -7 29 4 56 16 38 25 42 56 26z
m-727 -113 c16 -12 12 -18 -43 -70 -33 -31 -66 -57 -73 -57 -7 0 -24 7 -37 16
l-23 16 53 54 c56 56 87 67 123 41z m335 -41 l53 -54 -25 -17 c-34 -22 -53
-15 -112 45 -41 42 -46 51 -35 65 25 30 63 18 119 -39z m-120 -49 c31 -29 64
-58 73 -63 18 -10 57 -138 46 -150 -4 -3 -21 5 -38 20 -23 19 -43 26 -72 26
-25 0 -49 8 -65 20 -33 25 -73 25 -106 0 -14 -11 -39 -20 -57 -20 -37 0 -85
-23 -99 -47 -20 -36 -24 15 -6 81 13 47 24 67 44 79 14 8 46 36 71 61 42 44
47 46 99 46 52 0 57 -2 110 -53z m251 19 c0 -25 -7 -40 -26 -55 -38 -30 -64
-28 -36 2 26 28 27 40 6 58 -14 12 -14 14 3 20 41 16 53 11 53 -25z m-330
-201 c0 -10 -10 -15 -30 -15 -20 0 -30 5 -30 15 0 10 10 15 30 15 20 0 30 -5
30 -15z m-76 -37 c35 -22 36 -25 30 -68 -11 -89 -93 -125 -151 -67 -60 60 -20
145 74 156 6 0 28 -9 47 -21z m224 -14 c51 -58 12 -148 -65 -148 -80 0 -125
111 -65 161 36 31 97 24 130 -13z m382 9 c0 -54 -53 -153 -81 -153 -5 0 -9 38
-9 85 l0 85 45 0 c36 0 45 -3 45 -17z m-545 -153 c3 -5 -1 -10 -9 -10 -9 0
-16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z"/>
<path d="M507 854 c-12 -12 -8 -61 7 -73 19 -16 38 10 34 46 -3 27 -26 42 -41
27z"/>
<path d="M800 841 c-14 -27 -13 -37 6 -56 12 -12 19 -13 30 -4 17 14 18 51 2
67 -17 17 -26 15 -38 -7z"/>
<path d="M593 703 c-8 -21 39 -63 72 -63 52 0 110 49 86 73 -8 8 -17 4 -34
-11 -29 -27 -62 -28 -84 -2 -21 24 -32 25 -40 3z"/>
<path d="M294 335 c-8 -21 2 -45 20 -45 7 0 16 7 20 15 8 21 -3 45 -20 45 -8
0 -17 -7 -20 -15z"/>
<path d="M527 335 c-9 -22 3 -46 22 -43 10 2 16 13 16 28 0 28 -29 40 -38 15z"/>
<path d="M280 175 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16 -13z"/>
<path d="M336 133 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M262 114 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"/>
<path d="M550 175 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M506 133 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M572 114 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"/>
</g>
</svg>


</template>

<script>
export default {
name: 'buyer-icon'
}
</script>