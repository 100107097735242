<template>

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M373 4496 c-164 -40 -303 -171 -353 -334 -19 -62 -20 -95 -20 -1041
0 -1047 -2 -1004 50 -1116 31 -65 150 -184 215 -215 106 -49 127 -52 407 -57
l266 -5 4 -242 3 -242 27 -41 c36 -54 85 -76 159 -71 l58 4 394 297 393 297
775 0 c426 0 811 4 856 10 214 24 389 182 432 388 8 37 11 347 11 1000 0 1043
2 1004 -62 1124 -40 76 -120 156 -196 196 -123 66 -14 62 -1772 61 -1241 -1
-1606 -3 -1647 -13z m3271 -360 c70 -53 66 2 66 -1016 0 -773 -2 -925 -14
-948 -22 -42 -67 -79 -111 -91 -27 -7 -307 -11 -881 -11 l-841 0 -35 -24 c-19
-13 -149 -110 -289 -216 l-254 -192 -6 73 c-4 41 -7 113 -8 160 -1 94 -17 131
-74 173 -27 20 -43 21 -385 26 -337 5 -359 6 -392 26 -19 11 -45 35 -57 54
l-23 33 0 934 c0 1006 -2 960 52 1010 12 12 34 26 48 32 16 6 566 9 1596 8
l1571 -2 37 -29z"/>
<path d="M1069 3567 c-55 -37 -79 -81 -79 -147 0 -72 27 -116 90 -148 l45 -22
910 2 910 3 37 23 c80 50 101 171 43 248 -51 67 1 64 -1008 64 l-914 0 -34
-23z"/>
<path d="M1390 2859 c-63 -25 -100 -85 -100 -162 0 -60 23 -104 73 -141 28
-21 37 -21 648 -24 602 -2 620 -2 658 18 57 28 84 72 88 139 5 74 -24 127 -87
159 l-44 22 -606 -1 c-377 0 -614 -4 -630 -10z"/>
<path d="M4575 3473 c-28 -7 -82 -50 -96 -76 -36 -66 -28 -150 19 -200 37 -39
67 -53 131 -60 35 -4 65 -15 87 -32 67 -51 64 -9 64 -811 0 -715 0 -727 -21
-760 -11 -18 -35 -42 -53 -53 -32 -20 -48 -21 -357 -21 -186 0 -343 -5 -369
-11 -47 -11 -93 -46 -115 -89 -7 -14 -15 -79 -19 -144 -3 -66 -8 -121 -10
-123 -2 -2 -132 77 -289 176 l-286 179 -103 7 c-57 4 -263 5 -458 3 -350 -3
-355 -3 -392 -26 -106 -66 -109 -227 -4 -291 32 -20 48 -21 434 -21 l400 0
367 -230 c202 -127 390 -242 418 -257 108 -54 221 -3 247 113 5 21 10 114 10
206 l0 167 288 3 287 3 78 37 c110 53 186 128 240 237 l42 84 3 790 c2 702 1
796 -14 846 -49 172 -196 309 -371 346 -68 15 -120 17 -158 8z"/>
</g>
</svg>


  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path fill="none" d="M0 0h24v24H0z"></path><path fill-rule="nonzero" d="M10 14.676v-.062c0-2.508 2.016-4.618 4.753-5.233C14.389 7.079 11.959 5.2 8.9 5.2 5.58 5.2 3 7.413 3 9.98c0 .969.36 1.9 1.04 2.698.032.038.083.094.152.165a3.568 3.568 0 0 1 1.002 2.238 3.612 3.612 0 0 1 2.363-.442c.166.026.302.046.405.06A7.254 7.254 0 0 0 10 14.675zm.457 1.951a9.209 9.209 0 0 1-2.753.055 19.056 19.056 0 0 1-.454-.067 1.612 1.612 0 0 0-1.08.212l-1.904 1.148a.806.806 0 0 1-.49.117.791.791 0 0 1-.729-.851l.15-1.781a1.565 1.565 0 0 0-.439-1.223 5.537 5.537 0 0 1-.241-.262C1.563 12.855 1 11.473 1 9.979 1 6.235 4.537 3.2 8.9 3.2c4.06 0 7.403 2.627 7.85 6.008 3.372.153 6.05 2.515 6.05 5.406 0 1.193-.456 2.296-1.229 3.19-.051.06-.116.13-.195.21a1.24 1.24 0 0 0-.356.976l.121 1.423a.635.635 0 0 1-.59.68.66.66 0 0 1-.397-.094l-1.543-.917a1.322 1.322 0 0 0-.874-.169c-.147.023-.27.04-.368.053-.316.04-.64.062-.969.062-2.694 0-4.998-1.408-5.943-3.401zm6.977 1.31a3.325 3.325 0 0 1 1.676.174 3.25 3.25 0 0 1 .841-1.502c.05-.05.087-.09.106-.112.489-.565.743-1.213.743-1.883 0-1.804-1.903-3.414-4.4-3.414-2.497 0-4.4 1.61-4.4 3.414s1.903 3.414 4.4 3.414c.241 0 .48-.016.714-.046.08-.01.188-.025.32-.046z"></path></g></svg>
 --></template>

<script>
export default {
name: 'messages-icon'
}
</script>