<template>
    <div>
        <v-app-bar app flat color="primary" dark prominent>

            <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn icon @click="$router.go(-1)" v-else>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title class="text-h4 font-weight-light"
                style="letter-spacing:2px !important">Notifications</v-toolbar-title>

            <v-spacer></v-spacer>

            <template v-slot:img>
                <v-icon size="180" class="pl-8 float-left" color="#fff" style="opacity:.15">mdi-bell-badge-outline</v-icon>
            </template>

        </v-app-bar>

        <v-row no-gutters justify="center">
            <v-col cols="12" sm="6" md="4" lg="3">

                
                        <v-row no-gutters class="pt-8">
                            <v-col class="d-flex align-center  pl-4" cols="8">
                                <div>Match Notifications</div>
                            </v-col>
                            <v-col class="d-flex align-center justify-end pa-0 pr-4" cols="4">
                                <v-select style="font-size:14px !important" :items="items" item-text="label"
                                    item-value="value" v-model="notifications_freq" mandatory solo outlined flat dense hide-details
                                    :loading="saving" @change="saveNotificationFreq"></v-select>
                            </v-col>
                        </v-row>
                   

            </v-col>
        </v-row>


        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        

    </div>
</template>

<script>

export default {
    name: 'Notifications',

    async mounted() {
   
    },


    data() {
        return {
            items: [{
                label: 'Daily',
                value: 'daily'
            },
            {
                label: 'Weekly',
                value: 'weekly'
            },
            {
                label: 'Off',
                value: 'off'
            }
            ],
     
            notifications_freq: '',
        
            snackbar: false,
            snackbar_text: '',

            saving: false,
            submitting: false,

        }
    },
  


    methods: {
        


        saveNotificationFreq() {

            this.saving = true;
            let notifications_freq = this.notifications_freq

            this.$store.dispatch('saveAccount', {
                notifications_freq,
            })
                .then(() => {
                    this.saving = false;
                    this.snackbar_text = "Notification updated";
                    this.snackbar = true;
                })
                .catch(err => {
                    this.snackbar_text = "Error while updating";
                    this.snackbar = true;
                    this.saving = false;
                });

        },


    }
}
</script>
