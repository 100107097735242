<template>
<v-dialog v-model="this.$store.getters.premiumPop" persistent scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="450" :retain-focus="false">

    <v-card>


        <v-card-text class="pt-6">

          

                

                <div class="text-center">
                    <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37" large>mdi-crown</v-icon>
                    <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Unlock Premium Benefits</div>
                </div>

                <div class="pa-4 mt-4 mb-8" style="background-color: rgba(0, 31, 63, 0.05); border-radius:10px;">
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Send/Receive unlimited messages</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Share contact details</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Direct connect credits</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Stand out with our Premium tag</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4">Get Featured on Social Media</v-col>
                    </v-row>
                </div>

                <v-item-group v-model="selectedPlan">
                        <v-row>
                            <v-col cols="6" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="M">
                                    
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            1 Month
                                        </div>
                                        <div class="text-center text-h6 pa-4 font-weight-bold">
                                           &#8377; 499.00
                                        </div>
                                       <!--  <div class="text-center text-caption">
                                            +2 connects
                                        </div> -->
                                    </v-sheet>
                                   
                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="Q">
                                    <v-badge right offset-x="80" offset-y="7" style="font-weight:500;" color="orange" overlap content=" Most Popular ">
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            3 Months
                                        </div>
                            
                                        <div class="text-center">
                                           <span class="text-caption font-weight-bold" :class="active ? 'cyan--text text--lighten-2' : 'secondary--text'">SAVE 25%</span>
                                        </div>
                                        
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold">
                                           &#8377; 1119.00
                                        </div>
                                        
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'">
                                             &#8377; 1497.00
                                        </div>
                                        <!-- <div class="text-center text-caption">
                                            +3 connects
                                        </div> -->
                                    </v-sheet>
                                    </v-badge>
                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="S">
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            6 Months
                                        </div>

                                        <div class="text-center">
                                           <span class="text-caption font-weight-bold" :class="active ? 'cyan--text text--lighten-2' : 'secondary--text'">SAVE 40%</span>
                                        </div>
                                        
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold">
                                           &#8377; 1799.00
                                        </div>
                                        
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'">
                                             &#8377; 2994.00
                                        </div>

                                        <!-- <div class="text-center text-caption">
                                            +4  connects
                                        </div> -->
                                    </v-sheet>
                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-center justify-center">

            
                              <v-item v-slot="{ active, toggle }" value="A">
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            12 Months
                                        </div>
                                        <div class="text-center">
                                            <span class="text-caption font-weight-bold" :class="active ? 'cyan--text text--lighten-2' : 'secondary--text'">SAVE 50%</span>
                                           
                                        </div>
                                        
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold">
                                           &#8377; 2999.00
                                        </div>
                                        
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'">
                                             &#8377; 5988.00
                                        </div>
                                        <!-- <div class="text-center text-caption">
                                            +5 connects
                                        </div> -->
                                    </v-sheet>
                                </v-item>
                            </v-col>

                        </v-row>
                    </v-item-group>

                    <v-row>
                        <v-col>
                            <span class="grey--text text-caption">Membership will not be auto-renewed</span>
                        </v-col>
                       
                    </v-row>

        </v-card-text>
        <v-card-actions>
 
            <v-btn small text color="grey" @click.native="close">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" class="pl-8" @click="checkOut" :loading="submitting">TRY FREE FOR 14 DAYS<v-icon right>mdi-chevron-right</v-icon></v-btn> -->
            <v-btn color="primary" class="pl-8" @click="checkOut" :loading="submitting">NEXT <v-icon right>mdi-chevron-right</v-icon></v-btn>
                        
        </v-card-actions>
    </v-card>

          

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-dialog>
</template>

<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'PremiumCheckoutCF',

    data() {
        return {
 
            selectedPlan: 'Q',
            selectedValue:'1119',

            submitting: false,
            valid: true,
            snackbar: false,
            snackbar_text: '',
            complete: false,
            stripeError: '',
            promoCode: '',
            promoCodeId: '',

            stripe: '',
            elements: '',
            card: '',
            intentToken: '',
            addPaymentStatus: 0,
            addPaymentStatusError: '',
            paymentElement: '',
            paymentElementComplete: false,
            paymentElementReady: false,

            cardNumber: '',
            cardExpiry: '',
            cardCvc: '',

            cardNumberComplete: false,
            cardExpiryComplete: false,
            cardCvcComplete: false,

            showCouponDialog: false,
            validatingCoupon: false,

            amtMonth: 499,
            amtQuarter: 1119,
            amtSemiannual: 1799,
            amtAnnual: 2999,


        }
    },



    methods: {
        close() {
            this.$cookie.set("pm_premium_prompt_last_seen", this.$moment().format('M/D/YYYY'), 9999);
            this.$store.dispatch('showPremiumPop', false);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("Cancel Button Clicked");
            }
        },

        checkOut()
        {

            //if phone number is not available, prompt for that as cashfree require that to initiate a subscription

            if(!this.$store.getters.userDetails.email_verified || !this.$store.getters.userDetails.phone_verified)
            {
                this.$store.dispatch('showVerifyContactPop', true)
            }

            else
            {




            this.submitting = true;
            let product = this.selectedPlan;

            if (this.selectedPlan == 'A') {
                this.selectedValue = this.amtAnnual;
            } else if (this.selectedPlan == 'S') {
                this.selectedValue = this.amtSemiannual
            } else if (this.selectedPlan == 'Q') {
                this.selectedValue = this.amtQuarter
            } else {
                this.selectedValue = this.amtMonth
                product = this.selectedPlan = 'M'
            }

            this.$cookie.set('pm_selected_plan', this.selectedPlan, 1);
            this.$cookie.set('pm_selected_value', this.selectedValue, 1);


            const cashfree = window.Cashfree({
                mode: "production" //sandbox or production
            });

            axios.post('/v1/order-cf/create', {product}).then((resp) => {

                window.gtag('event', 'premium_init_cashfree', {
                    event_category: 'Premium Purchase',
                    event_label: 'Premium Membership',
                    value: this.selectedValue
                });

                //console.log(resp);

                let checkoutOptions = {
                    paymentSessionId: resp.data.payment_session_id,
                    redirectTarget: "_self" //optional (_self or _blank)
                }

                cashfree.checkout(checkoutOptions)


            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)
                this.submitting = false;
            });


        }

         

        },
        
    }

}
</script>
