<template>
<div>
      <div class="ml-2" v-if="user.xid != this.$store.getters.userDetails.xid && !blockedUser.includes(user.xid)">
        <v-btn @click="blockDialog=true;"   text  small color="error" style="background-color: #feeced; "><v-icon small >mdi-block-helper</v-icon>&nbsp;Block</v-btn>
      </div>

      <div class="ml-2" v-if="user.xid != this.$store.getters.userDetails.xid && blockedUser.includes(user.xid)">
        <v-btn @click="unblockDialog=true;" text   small color="success" style="background-color: #eef9e9;"><v-icon small >mdi-block-helper</v-icon>&nbsp;Unblock</v-btn>
      </div>

    <v-dialog v-model="blockDialog" max-width="400">
      <v-card>
        <v-card-title class="text-subtitle-1">Block Member</v-card-title>
        <v-card-text>
          <p >
            Please explain why you would like to block ({{ user.name }}).
          </p>
          <v-textarea autofocus outlined  auto-grow row-height="30" rows="5" placeholder="Your reason for blocking (*required)" v-model="blockMessage"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="grey" @click="blockDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="blockUser" :loading="blockLoading" :disabled="blockLoading">Submit</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="unblockDialog" max-width="400">
      <v-card>
        <v-card-title class="text-subtitle-1">Unblock Member</v-card-title>
        <v-card-text>
          <p >
            Are you sure you want to unblock {{user.name}}?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="grey" @click="unblockDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="unblockUser" :loading="unblocking" :disabled="unblocking">Submit</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" bottom>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  export default {
    name: 'BlockUser',
    props: ['value', 'user'],
    mounted() {
      //console.log('mounted PetPop')
    },
    data() {
      return {
        blockDialog: false,
        blockLoading: false,
        blockMessage: '',
        unblockDialog: false,
        unblocking: false,
        snackbar: false,
        snackbar_text: '',
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      blockedUser() {
        return this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
            return o.buid;
        }) : [];
      }
    },
    methods: {
      blockUser()
      {
        let xid = this.user.xid
        let reason = this.blockMessage

        if(reason == '')
        {
          this.snackbar_text = "Please provide a reason";
          this.snackbar = true;
        }
        else
        {
        this.blockLoading = true;
        this.$store.dispatch('blockUser', {
          xid, reason
        })
            .then((resp) => {
              this.$store.dispatch('profile');
              this.blockLoading = false;
              this.snackbar_text = "Blocked successfully";
              this.snackbar = true;
              this.blockDialog = false;
              this.blockMessage = '';
            })
            .catch(err => {
              this.connecting = false;
              this.blockLoading = false;
              this.snackbar_text = "Error submitting";
              this.snackbar = true;
            });
          }
      },
      unblockUser()
      {
        let xid = this.user.xid

        this.unblocking = true

        this.$store.dispatch('unblockUser', {
          xid
        })
            .then((resp) => {
              this.$store.dispatch('profile');
              this.snackbar_text = "Unblocked successfully";
              this.snackbar = true;
              this.unblocking = false;
              this.unblockDialog = false;
            })
            .catch(err => {
              this.connecting = false;
              this.snackbar_text = "Error submitting";
              this.snackbar = true;
              this.unblocking = false;
            });
      }
    }
  }
</script>
