<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="128.000000pt" height="128.000000pt" viewBox="0 0 128.000000 128.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
stroke="currentColor" fill="currentColor">
<path d="M293 987 c-293 -292 -293 -292 -293 -335 0 -74 48 -132 109 -132 18
0 19 -9 23 -213 3 -235 6 -248 71 -288 28 -17 48 -19 172 -17 l140 3 5 176 c6
220 6 219 122 219 118 0 118 0 118 -208 0 -117 4 -172 12 -180 8 -8 54 -12
143 -12 149 0 185 12 217 71 16 29 18 59 18 240 l0 206 39 7 c60 9 91 52 91
124 l0 57 -288 287 -287 288 -60 0 -60 0 -292 -293z m634 -59 c150 -150 273
-281 273 -290 0 -23 -17 -32 -75 -38 l-50 -5 -5 -238 c-6 -288 0 -277 -136
-277 l-84 0 0 128 c-1 154 -9 196 -49 236 -31 31 -33 31 -149 34 -104 3 -121
1 -150 -18 -52 -32 -62 -69 -62 -235 l0 -145 -90 0 c-136 0 -130 -11 -130 264
0 123 -3 231 -6 240 -4 11 -19 16 -48 16 -42 0 -86 22 -86 43 0 17 542 557
560 557 8 0 138 -123 287 -272z"/>
</g>
</svg>
  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zM6 19h12V9.157l-6-5.454-6 5.454V19zm6-4a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"></path></g></svg> -->
  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256m368-77V64h-48v69"></path></svg> -->
</template>

<script>
export default {
name: 'home-icon'
}
</script>