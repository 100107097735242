<template>
  <v-dialog v-model="show"  max-width="450" transition="dialog-transition" :retain-focus="false" persistent>

    <v-card class="petProfileCompletenessPop">

      <v-toolbar flat color="transparent" max-height="56">

<v-spacer></v-spacer>

<v-btn icon @click.stop="show=false">
    <v-icon>mdi-close</v-icon>
</v-btn>

</v-toolbar>
<v-card-text>

      <v-row no-gutters>
      
        <!-- Profile Video -->

        <v-col cols="12">
          <div class="text-subtitle-1 font-weight-bold pb-4 black--text">{{ pet.name }}'s Profile</div>
          <v-progress-linear :value="profilePercentage" :color="progressColor" :background-color="progressColorBg" rounded height="24" >
                   <span class="float-start ml-2 text-caption font-weight-medium white--text" style="width:100%; letter-spacing: 1px !important;">PROFILE COMPLETENESS</span>
                   <span class="float-end mr-2 text-caption font-weight-bold" >{{ profilePercentage }}%</span>
                </v-progress-linear>

          
        </v-col>

        <div class="py-3 text-caption">
          Providing comprehensive details of your pet enhances your chances of connecting with interested members.
        </div>

        <v-col cols="12" class="mt-2">

          <v-alert border="left" text color="success" icon="mdi-check-circle"  v-if="pet.video_verified == 0 && pet.verification_video_uploaded == 0 && ['lover', 'friend'].includes(pet.purpose)" class="mt-4">
          <p class="text-subtitle-2 mb-2" style="font-weight:600">Boost Your Pet's Profile!</p>
          <p class="text-caption ">Elevate your pet's profile with a "verified" tag by completing profile verification</p>
          <div class="d-flex justify-end"><v-btn color="success" small @click="showPetProfileVerificationPop = true">
            VERIFY NOW
          </v-btn></div>
        </v-alert>

        <v-alert border="left" text color="error" icon="mdi-alert"  v-if="pet.video_verified == 0 && pet.verification_video_uploaded == 0 && ['adopter', 'buyer'].includes(pet.purpose)" class="mt-4">
          <p class="text-subtitle-2 mb-2" style="font-weight:600">Verification Required</p>
          <p class="text-caption">Your pet's profile must be verified to proceed.</p>
          <div class="d-flex justify-end"><v-btn color="error" small @click="showPetProfileVerificationPop = true">
            VERIFY NOW
          </v-btn></div>
        </v-alert>

        <v-alert border="left" text color="info"  v-if="pet.video_verified == 0 && pet.verification_video_uploaded != 0" class="mt-4">
          <v-row align="center" class="pb-4">
            <v-col class="grow text-caption">Your verification video has been uploaded successfully and will be reviewed within the next 24-48 hours.</v-col>
          </v-row>
              <div class="d-flex justify-end"><v-btn color="info" small @click="showPetProfileVerificationPop = true;reuploadVideo=true">
                RE-UPLOAD
              </v-btn></div>
        </v-alert>


        <v-alert border="left" text color="success" icon="mdi-seal-variant"   v-if="pet.pedigree_certified == 1 && pet.pedigree_verified == 0 && pet.pedigree_uploaded == 0" class="mt-4">
          <p class="text-subtitle-2 mb-2" style="font-weight:600">Enable "Pedigree Certified" Tag</p>
          <p class="text-caption ">Enhance credibility by uploading your pet's pedigree certificate.</p>
          <div class="d-flex justify-end"><v-btn color="success" small @click="showPedigreeCertificateDialog = true">
            UPLOAD
          </v-btn></div>
        </v-alert>


        <v-alert border="left" text color="info" v-if="pet.pedigree_certified == 1 && pet.pedigree_verified == 0 && pet.pedigree_uploaded != 0" class="mt-4">
          <v-row align="center">
            <v-col class="grow text-caption">Pedigree Certificate has been uploaded successfully and will be reviewed within the next 24-48 hours.</v-col>
          </v-row>
        </v-alert>



        <v-alert border="left" text color="success" icon="mdi-dna"   v-if="pet.dna_tested == 1 && pet.dna_verified == 0 && pet.dna_uploaded == 0" class="mt-4">
          <p class="text-subtitle-2 mb-2" style="font-weight:600">Enable "DNA Tested" Tag</p>
          <p class="text-caption ">Prove your pet's heritage and enhance trust by uploading the DNA report.</p>
          <div class="d-flex justify-end"><v-btn color="success" small @click="showDnaReportDialog = true">
            UPLOAD
          </v-btn></div>
        </v-alert>


        <v-alert border="left" text color="info"   v-if="pet.dna_tested == 1 && pet.dna_verified == 0 && pet.dna_uploaded != 0" class="mt-4">
          <v-row align="center">
            <v-col class="grow text-caption">DNA Report has been uploaded successfully and will be reviewed within the next 24-48 hours.</v-col>
          </v-row>
        </v-alert>


        <v-alert border="left" text color="info" icon="mdi-text" v-if="!(pet.extra_info_status == 'submitted' || pet.extra_info_status == 'verified')" class="mt-4">
            <p class="text-subtitle-2 mb-2" style="font-weight:600">Pet Profile Information</p>
            <p class="text-caption ">Provide few more details about your pet.</p>
            <div class="d-flex justify-end"><v-btn color="info" small @click="showExtraInfoDialog = true; show = false;">
              UPDATE
            </v-btn></div>
          </v-alert>


          <v-alert border="left" text color="info" icon="mdi-text" v-if="!profileCompleteness.detailed_description" class="mt-4">
            <p class="text-subtitle-2 mb-2" style="font-weight:600">Detailed Profile Description</p>
            <p class="text-caption ">Provide a comprehensive description of your pet, covering everything from their personality traits to favorite activities, in at least 300 characters.</p>
            <div class="d-flex justify-end"><v-btn color="info" small @click="editPet(pet.xid)">
              UPDATE
            </v-btn></div>
          </v-alert>

          <v-alert border="left" text color="info" icon="mdi-image" v-if="!profileCompleteness.profile_photo" class="mt-4">
            <p class="text-subtitle-2 mb-2" style="font-weight:600">Add a profile photo</p>
            <p class="text-caption ">Choose a favorite photo that best captures your pet's personality as their profile picture.</p>
            <div class="d-flex justify-end"><v-btn color="info" small @click="editPet(pet.xid)">
              UPLOAD
            </v-btn></div>
          </v-alert>

          <v-alert border="left" text color="info" icon="mdi-image-multiple" v-if="profileCompleteness.profile_photo && !profileCompleteness.photo" class="mt-4">
            <p class="text-subtitle-2 mb-2" style="font-weight:600">Add more photos of your pet</p>
            <p class="text-caption ">Upload additional photos to showcase your pet's character, habits, and memorable moments.</p>
            <div class="d-flex justify-end"><v-btn color="info" small @click="editPet(pet.xid)">
              UPLOAD
            </v-btn></div>
          </v-alert>

          <v-alert border="left" text color="info" icon="mdi-video-outline" v-if="!profileCompleteness.video" class="mt-4">
            <p class="text-subtitle-2 mb-2" style="font-weight:600">Add a video of your pet</p>
            <p class="text-caption ">Share a video to give a lively glimpse into your pet's daily life, unique tricks, or playful antics.</p>
            <div class="d-flex justify-end"><v-btn color="info" small @click="editPet(pet.xid)">
              UPLOAD
            </v-btn></div>
          </v-alert>

            
        </v-col>
      </v-row>
    </v-card-text>

    </v-card>

    <v-snackbar v-model="snackbar" bottom>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <pet-profile-verification-pop v-if="isMounted" :reuploadVideo=reuploadVideo v-model="showPetProfileVerificationPop" :pet="pet" @force-pet-rerender="forcePetRender"/>
    <pet-pedigree-certificate-verification-pop v-if="isMounted" v-model="showPedigreeCertificateDialog" :pet="pet" @force-pet-rerender="forcePetRender"/>
    <pet-dna-report-verification-pop v-if="isMounted" v-model="showDnaReportDialog" :pet="pet"  @force-pet-rerender="forcePetRender"/>
    <pet-extra-info-pop v-if="isMounted" v-model="showExtraInfoDialog" :pet="pet"  @force-pet-rerender="forcePetRender"/>

  </v-dialog>

</template>

<script>

import PetProfileVerificationPop from "./PetProfileVerificationPop.vue";
import PetDnaReportVerificationPop from "./PetDnaReportVerificationPop.vue";
import PetPedigreeCertificateVerificationPop from "./PetPedigreeCertificateVerificationPop.vue";
import PetExtraInfoPop from "./PetExtraInfoPop.vue";

export default {
  name: 'PetProfileVerification',
  components: {
    PetPedigreeCertificateVerificationPop, 
    PetDnaReportVerificationPop, 
    PetProfileVerificationPop,
    PetExtraInfoPop
  },
  props: ['value', 'pet'],
  mounted() {
      this.isMounted = true;
      /* this.progressColor = this.pet.profile_completeness.total_percentage > 80 ? '#56ca02' :
            this.pet.profile_completeness.total_percentage > 60 ? '#54bdff' : '#f9aa16';
        
        this.progressColorBg = this.pet.profile_completeness.total_percentage > 80 ? '#e7f7e0' :
            this.pet.profile_completeness.total_percentage > 60 ? '#e3f3ff' : '#fff3e0';

      this.profilePercentage = this.pet.profile_completeness ? this.pet.profile_completeness.total_percentage : 0; */
  },
  data() {
    return {
      showPetProfileVerificationPop: false,
      showPedigreeCertificateDialog: false,
      showExtraInfoDialog: false,
      showDnaReportDialog: false,
      isMounted: false,
      reuploadVideo: false,

      /* progressColor: '#f9aa16',
      progressColorBg: '#fff3e0',
      profilePercentage: 0, */
      showProfileCompletenessDialog: false,
      profileCompleteness: this.pet.profile_completeness,

      submitting: false,
      snackbar: false,
      snackbar_text: '',
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    profilePercentage() {
      return this.pet.profile_completeness ? this.pet.profile_completeness.total_percentage : 0;
    },
    progressColor() {
      return this.profilePercentage > 80 ? '#56ca02' :
        this.profilePercentage > 60 ? '#54bdff' : '#f9aa16';
    },
    progressColorBg() {
      return this.profilePercentage > 80 ? '#e7f7e0' :
        this.profilePercentage > 60 ? '#e3f3ff' : '#fff3e0';
    }
  },

  methods: {
      forcePetRender() {
        // Force the  component to re-render
          this.$emit('force-pet-rerender');
      },
      editPet(xid) {
        this.$router.push({
          name: 'EditPet',
          params: {
            id: xid
          }
        })
      },
  }
}
</script>
