<template>
  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M463.46 37.008l-30.694 50.738-7.043-2.28c-27.146-8.797-71.525-7.15-97.6.11L321.22 87.5l-28.68-48.543c-33.63 69.254-32.264 117.56-14.79 148.574 18.71 33.208 57.378 49.09 99.117 48.574 48.743-.606 88.968-19.665 107.035-54.194 16.918-32.332 15.684-80.456-20.443-144.902zM323.935 137.594c18.45.1 29.36 15.338 31.462 36.644-37.11 17.91-53.963 3.398-61.173-26.545 11.382-7.063 21.324-10.144 29.71-10.1zm109.26 0c8.385-.045 18.328 3.036 29.71 10.1-7.21 29.942-24.064 44.454-61.174 26.544 2.104-21.306 13.014-36.545 31.463-36.644zm-293.553 50.96c-1.226-.01-2.446-.003-3.66.018-30.175.536-56.142 10.59-75.743 26.574-43.444 35.43-57.27 100.752-12.824 166.192 20.293 33.995 44.432 54.24 70.797 64.187 32.85 12.395 66.655 8.823 99.94 4.114 33.284-4.71 65.854-10.63 96.896-8.42 31.04 2.212 62.09 10.18 90.505 41.165 19.374 21.125 46.887-1.627 23.82-24.156-35.024-34.207-72.527-47.42-109.377-50.04-36.85-2.62-72.2 4.698-104.207 9.228-32.007 4.53-60.272 6.552-84.558-2.61-14.39-5.43-28.308-14.802-41.55-31.142h351.744c13.673-52.293 14.867-106.368 1.873-142.072-19.765 8.49-42.412 12.9-66.2 13.197h-.002c-29.85.37-59.458-6.925-82.907-22.823-4.647 3.012-9.407 6.23-14.292 9.685l-5.734 4.057-5.49-4.382c-46.63-37.2-91.028-52.48-129.03-52.773z"></path></svg> -->

  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M1013 4955 c-108 -29 -199 -97 -245 -181 -109 -202 -64 -575 120
-998 l39 -90 -38 -80 c-48 -104 -84 -212 -111 -334 -29 -132 -32 -406 -5 -532
54 -250 158 -446 337 -634 l90 -94 -48 -41 c-99 -82 -185 -215 -232 -360 l-32
-98 -37 7 c-102 21 -195 78 -253 156 -117 156 -177 194 -300 193 -121 0 -218
-57 -268 -155 -21 -42 -25 -62 -25 -144 0 -110 12 -139 113 -270 171 -222 451
-371 729 -387 80 -5 92 -8 102 -27 6 -13 12 -112 13 -251 3 -225 4 -231 29
-286 34 -73 96 -134 168 -167 46 -22 71 -26 141 -27 71 0 94 4 141 26 72 34
134 96 167 168 21 44 28 75 30 146 l4 90 76 -2 c43 -2 118 -6 168 -9 l92 -6 4
-81 c5 -100 35 -173 99 -236 149 -149 399 -126 519 48 45 65 60 120 60 220 l0
79 29 4 29 4 4 -100 c4 -120 31 -189 98 -256 66 -66 138 -94 240 -95 71 0 94
4 141 26 72 34 134 96 168 168 26 56 26 56 31 371 l5 315 38 79 c46 97 84 222
100 335 l13 84 109 24 c165 36 397 117 544 188 149 73 265 144 280 174 27 49
0 106 -54 117 -24 4 -47 -5 -115 -46 -387 -235 -849 -350 -1405 -350 -998 0
-1767 404 -1955 1025 -35 116 -44 181 -44 320 -1 224 39 385 145 600 82 166
171 287 324 441 235 237 497 396 828 505 451 149 953 149 1404 0 337 -111 621
-286 851 -526 225 -234 362 -482 428 -775 12 -54 17 -122 17 -245 0 -153 -2
-180 -26 -270 -35 -131 -109 -280 -191 -385 -86 -111 -101 -142 -84 -178 16
-33 59 -55 92 -46 49 12 176 181 251 333 90 183 120 323 120 556 -1 232 -40
411 -134 610 l-37 80 39 90 c186 429 229 811 114 1007 -49 85 -133 144 -244
173 -75 20 -255 19 -369 0 -198 -35 -457 -125 -682 -239 l-72 -36 -83 24
c-218 66 -435 97 -692 97 -257 0 -474 -31 -692 -97 l-82 -24 -78 39 c-228 114
-477 201 -677 236 -110 19 -303 19 -373 0z m458 -183 c126 -34 304 -96 400
-142 l61 -29 -98 -49 c-82 -42 -156 -85 -295 -175 -13 -8 -31 0 -85 39 -133
94 -331 188 -486 229 -43 11 -68 23 -68 32 0 27 87 101 137 117 81 26 290 15
434 -22z m3320 21 c55 -18 139 -88 139 -116 0 -9 -25 -21 -67 -32 -156 -41
-356 -136 -492 -233 -50 -36 -67 -43 -80 -35 -140 90 -213 133 -295 175 l-99
50 85 38 c118 53 334 125 448 149 124 26 288 27 361 4z m172 -345 c-10 -139
-57 -326 -125 -502 l-35 -90 -58 80 c-32 43 -114 135 -181 204 l-123 125 72
47 c119 76 237 131 377 174 41 12 76 23 77 23 0 1 -1 -27 -4 -61z m-3987 26
c102 -33 265 -110 345 -164 35 -24 65 -44 66 -45 1 0 -53 -57 -121 -125 -67
-69 -149 -161 -181 -204 l-58 -79 -35 89 c-18 49 -45 125 -58 169 -57 183 -83
385 -50 385 7 0 49 -12 92 -26z m477 -2639 c300 -182 700 -302 1151 -345 202
-19 552 -12 749 15 l39 6 -7 -43 c-17 -101 -49 -200 -97 -298 -43 -90 -62
-117 -132 -185 -110 -109 -223 -167 -401 -205 -27 -5 -60 -12 -72 -15 l-23 -5
0 140 c0 162 -10 209 -60 281 -149 216 -476 188 -589 -51 -24 -51 -26 -66 -29
-227 l-4 -173 -136 6 c-75 3 -152 7 -169 10 l-33 5 0 149 c0 126 -3 158 -20
204 -42 110 -116 180 -229 213 -49 14 -57 14 -87 0 -25 -12 -35 -25 -40 -52
-8 -46 14 -80 63 -95 63 -20 109 -56 132 -102 20 -42 21 -59 21 -329 0 -257
-2 -288 -19 -326 -61 -132 -256 -132 -321 1 -21 44 -21 54 -19 367 l2 322 -22
18 c-13 11 -33 19 -46 19 -30 0 -38 44 -30 164 20 261 93 423 253 558 53 45
57 47 79 33 12 -8 56 -35 96 -60z m-1074 -151 c17 -14 47 -50 67 -80 76 -114
219 -210 352 -237 l64 -12 3 -125 c2 -69 6 -133 10 -142 9 -23 -18 -24 -112
-3 -244 51 -427 176 -566 385 -42 62 -49 119 -22 171 37 70 145 93 204 43z
m2008 -529 c50 -21 72 -42 94 -90 17 -36 19 -70 19 -325 0 -256 -2 -289 -19
-326 -61 -135 -255 -136 -320 -1 -21 44 -22 55 -19 339 l3 295 27 40 c47 72
137 100 215 68z m834 -740 c-59 -133 -256 -135 -320 -3 -17 36 -21 63 -21 140
l0 95 43 12 c96 29 281 128 304 164 5 7 9 -73 11 -176 2 -168 0 -193 -17 -232z"/>
<path d="M2052 3590 c-26 -25 -36 -72 -30 -155 5 -79 28 -109 82 -109 64 0 76
22 76 139 0 93 -2 103 -23 123 -29 28 -76 28 -105 2z"/>
<path d="M3672 3587 c-20 -21 -22 -34 -22 -124 0 -114 12 -137 76 -137 54 0
77 30 82 109 6 83 -4 130 -30 155 -30 27 -79 26 -106 -3z"/>
<path d="M2832 3539 c-91 -12 -129 -27 -174 -67 -51 -46 -68 -80 -68 -137 0
-87 39 -130 168 -185 l82 -35 0 -52 c0 -51 0 -51 -46 -73 -64 -31 -140 -25
-191 17 -75 59 -104 66 -147 32 -60 -47 -14 -136 101 -195 53 -26 67 -29 158
-29 89 0 106 3 150 27 l50 26 50 -26 c39 -20 69 -26 135 -30 107 -5 176 18
250 84 43 40 50 51 50 84 0 30 -6 43 -26 59 -43 34 -72 27 -147 -32 -51 -42
-127 -48 -191 -17 -46 22 -46 22 -46 73 l0 52 82 35 c130 55 168 98 168 188 0
59 -35 118 -93 155 -63 41 -202 61 -315 46z m169 -164 c27 -3 54 -14 64 -25
16 -18 15 -20 -51 -50 -38 -17 -82 -32 -99 -32 -16 0 -61 15 -98 33 -67 31
-68 32 -50 50 19 20 117 39 163 34 14 -2 46 -6 71 -10z"/>
<path d="M1640 3114 c-91 -14 -288 -62 -340 -82 -37 -14 -54 -39 -54 -77 0
-45 28 -75 70 -75 19 0 72 11 119 25 96 28 223 51 335 61 55 5 81 12 98 27 27
25 29 76 3 108 -24 29 -100 34 -231 13z"/>
<path d="M3983 3120 c-46 -19 -58 -93 -20 -127 16 -15 42 -22 97 -27 112 -10
239 -33 335 -61 105 -31 146 -32 170 -5 25 28 25 85 0 109 -34 32 -206 79
-390 106 -112 17 -160 18 -192 5z"/>
<path d="M1863 2791 c-99 -47 -276 -171 -370 -258 -36 -33 -43 -46 -43 -76 0
-48 28 -77 75 -77 29 0 51 13 121 70 105 86 175 134 275 189 87 47 106 74 89
126 -10 30 -43 55 -73 55 -7 0 -40 -13 -74 -29z"/>
<path d="M3852 2804 c-33 -23 -46 -61 -31 -96 8 -20 35 -41 94 -73 93 -50 212
-134 296 -206 65 -56 113 -65 149 -29 21 21 26 69 10 100 -24 45 -292 238
-403 291 -71 33 -84 35 -115 13z"/>
</g>
</svg>


</template>

<script>
export default {
  name: 'cat-icon'
}
</script>