<template>
  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M433.5 31.6c-8.5 0-15.1 6.58-15.1 14.81s6.6 14.81 15.1 14.81 15.1-6.58 15.1-14.81S442 31.6 433.5 31.6zM267 59.05c6.6 15.84 17.1 18.03 31.1 24.77-11.2 20.98-23.2 51.08-43.5 59.28-64.7 26.1-98.7 58.3-112.3 98.7-13.1 39-7 87.2 13.3 145.4 61.8 4.9 127.3 9.2 159.4 2.8-1.3-4.4-5.8-7.5-8.6-9-28.5-9.8-45.2-10.9-71.5-12l1.1-9.6c2.2-19.6.4-44.3-7.7-61.3-4.1-8.5-9.5-14.9-16.5-18.8-7-3.9-15.8-5.7-28.4-3.3l-3.2-17.8c13.5-2.4 29.4-.6 40.4 5.4 10.9 6.1 18.7 15.8 23.9 26.7 9.1 18.9 11.1 41.6 9.9 61.8 10 .6 18.9 1.6 26.8 2.9 19.2-30.7 37-60.4 39.5-90.7-13.8-4-32.4-10.7-34.3-24.4-2.7-19.6 3.6-45 19.3-55.5-1 27.8-4.3 43.9-2.3 49.7 5.8 10.9 30.3 15.2 40.1 17.4v.1c17.4 4.8 31.9 7 34.8 25.6 18.2-29.5-14.4-45-36.2-54.5l-.1-5.8c-.3-23.5 5.5-39.4 13.5-53.1 8-13.6 17.7-25 26.9-43.2v-.1c-9.4-1.3-19.4-.1-27.1 1.3 5.7-19.9 23.2-23.73 38.6-16.1 5.9-8.3 13.4-18.83 24.9-29.96-12.7-9.39-19.9-20.4-18.1-33.92-28-3.4-57.4-4.45-78.6 9.84l-4-3.72c-15.7-17.87-60.9-12.3-51.1 11.11zm67.9-4.17c4 7.92 14.9 12.95 29.2 13.88-8 6.49-20.7 11.42-30.3 5.24-7.3-4.71-3.7-13.07 1.1-19.12zM129.4 364.6c-14.6 3.2-38.77 7-49.63 16-8.5 7.4-15.03 19.8-16.19 31.4-1.17 11.6 1.99 21.6 12.85 28.8C120 469.7 165 466.4 205.4 454.9c40.4-11.6 75.4-32.6 119.4-22.8 8.4 2.5 15.7 6.5 14.5 15.9-.4 2.8-1.5 4.8-2.7 6.5-9.7 10.8-30.9 17.7-33.6 21.5-6.4 9.2 34.2 2.8 45-7.6 4.2-4.2 8.1-13 8.9-21.3.8-8.3-1.3-15.3-4.9-18.3-11.2-9.6-24.1-15.1-39.8-15.1-15.7-.1-34 1.7-53.5 6.5-39.1 9.6-83.6 27.5-127.4 18.6-17-3.4-27.4-6.8-33.77-14.9-3.22-4-4.39-10.6-2.48-15.4 7.45-15.2 28.15-17.8 40.95-20.5-2.4-7.2-4.6-16.4-6.6-23.4z"></path></svg> -->

  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="511.000000pt" viewBox="0 0 512.000000 511.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M3735 5026 c-181 -35 -327 -110 -456 -236 -133 -130 -203 -248 -247
-418 -17 -62 -18 -63 -47 -58 -98 18 -276 28 -389 22 -300 -15 -558 -78 -812
-200 l-131 -63 -28 45 c-108 170 -309 308 -535 368 -102 27 -322 25 -423 -4
-172 -49 -298 -120 -408 -231 -107 -108 -174 -218 -225 -371 -26 -80 -28 -96
-29 -255 0 -192 9 -237 76 -383 101 -219 284 -384 514 -464 47 -16 107 -32
134 -35 27 -3 53 -8 58 -12 6 -4 6 -39 1 -91 -16 -165 9 -377 63 -523 76 -210
236 -386 444 -489 68 -34 215 -87 303 -108 82 -21 44 -30 -120 -30 -150 0
-167 -2 -213 -24 -64 -30 -102 -68 -133 -134 -43 -94 -21 -213 55 -288 65 -66
77 -69 300 -72 l202 -3 21 -56 c22 -58 63 -136 88 -165 22 -27 83 -24 110 5
31 33 27 65 -17 154 -48 97 -66 154 -86 267 -16 93 -19 193 -9 267 l6 44 77
-7 c484 -44 1241 81 1734 286 48 20 89 36 91 36 10 0 68 -156 86 -232 98 -407
-64 -718 -463 -890 -317 -137 -744 -180 -1011 -103 -45 13 -114 39 -154 58
-61 30 -78 34 -104 26 -51 -14 -71 -65 -46 -110 15 -26 15 -28 -9 -51 -14 -12
-34 -41 -44 -63 -80 -181 42 -375 236 -375 91 1 130 24 286 172 77 73 147 136
157 140 9 4 49 7 87 8 124 1 338 39 486 84 l77 24 128 -105 c169 -137 189
-148 284 -148 90 0 138 22 197 89 49 56 68 116 60 196 -7 79 -40 134 -119 200
-35 29 -64 55 -65 59 -2 3 5 16 15 29 l18 23 74 -17 c101 -23 287 -15 385 15
39 13 150 63 248 112 215 108 265 123 353 107 74 -13 128 -2 182 39 99 75 96
256 -6 326 -71 50 -241 71 -364 46 -84 -17 -182 -57 -343 -140 -82 -43 -174
-84 -204 -93 -57 -17 -147 -22 -154 -9 -2 5 -1 41 2 82 l6 73 120 44 c66 24
140 51 164 60 66 25 126 86 151 150 49 132 -3 270 -125 329 -89 44 -168 35
-368 -44 -16 -6 -31 -11 -33 -11 -3 0 36 28 88 62 349 234 508 580 442 963
-15 93 -71 267 -117 367 l-26 57 57 32 c86 48 173 121 235 196 101 125 114
159 76 207 -16 20 -29 26 -59 26 -41 0 -43 -2 -143 -128 -49 -63 -141 -138
-209 -173 l-38 -19 -34 53 c-99 154 -263 330 -420 447 l-94 71 25 55 c118 259
301 438 567 558 l99 45 40 -32 c81 -64 179 -223 213 -345 9 -31 18 -111 21
-177 3 -75 10 -128 18 -141 30 -48 113 -37 136 17 18 44 6 255 -20 344 -54
189 -158 347 -305 462 -88 68 -247 146 -346 168 -100 23 -277 28 -365 11z
m271 -157 c72 -12 154 -38 154 -48 0 -4 -24 -19 -53 -33 -238 -116 -441 -322
-558 -563 l-40 -84 -74 35 c-40 20 -116 50 -168 67 l-94 32 18 65 c56 206 221
396 414 477 133 56 276 75 401 52z m-2996 -533 c186 -34 363 -145 467 -293
l42 -60 -45 -33 c-25 -17 -85 -70 -135 -117 l-89 -85 -62 58 c-142 131 -288
220 -453 277 -88 30 -232 62 -319 69 -28 3 -27 4 30 47 164 122 369 172 564
137z m1848 -167 c560 -61 1023 -389 1282 -907 121 -243 163 -461 124 -650 -30
-150 -95 -263 -219 -388 -225 -223 -611 -393 -1140 -503 -516 -106 -972 -120
-1296 -37 -540 137 -756 538 -629 1167 111 550 473 986 985 1189 283 113 612
160 893 129z m-2438 -179 c234 -28 410 -100 584 -241 41 -33 89 -77 105 -98
l30 -38 -54 -83 c-106 -160 -187 -345 -240 -542 -13 -50 -26 -92 -27 -94 -11
-11 -167 33 -237 66 -167 80 -286 199 -358 357 -87 190 -84 427 7 618 36 75
32 74 190 55z m3816 -2188 c55 -44 55 -115 1 -155 -29 -22 -265 -113 -272
-105 -1 2 -12 39 -23 83 -11 44 -23 87 -26 95 -5 12 20 24 106 56 125 46 182
53 214 26z m-2602 -549 c4 -43 10 -88 13 -100 l5 -23 -158 0 c-136 0 -162 3
-180 18 -54 43 -58 100 -10 148 l34 34 145 0 144 0 7 -77z m3266 46 c48 -11
60 -22 60 -56 0 -34 -30 -44 -92 -32 -108 20 -179 0 -418 -121 -221 -111 -297
-133 -436 -128 -57 2 -105 6 -108 9 -3 3 1 24 10 46 l16 41 77 -5 c117 -8 189
14 396 119 99 50 212 102 250 114 77 25 171 30 245 13z m-1188 -686 c99 -81
113 -130 54 -189 -55 -55 -87 -46 -225 67 l-80 66 87 55 c48 31 88 56 90 57 2
1 35 -25 74 -56z m-1381 -208 c76 -13 76 -15 -15 -103 -71 -70 -88 -82 -118
-82 -43 0 -76 23 -94 66 -18 42 -6 75 43 126 l35 36 52 -18 c29 -10 72 -21 97
-25z"/>
<path d="M3204 3486 c-89 -41 -141 -141 -124 -236 9 -50 30 -70 75 -70 49 0
72 23 80 81 8 58 33 89 70 89 26 0 65 -35 65 -57 0 -26 48 -63 80 -63 40 0 80
39 80 78 0 15 -11 51 -25 79 -55 111 -185 153 -301 99z"/>
<path d="M1635 3211 c-78 -35 -125 -113 -125 -206 0 -46 4 -60 25 -80 52 -53
135 -15 135 62 0 53 23 83 64 83 35 0 59 -20 70 -57 9 -30 53 -63 83 -63 35 0
73 42 73 82 -1 72 -59 152 -132 182 -51 22 -143 20 -193 -3z"/>
<path d="M2552 3179 c-198 -25 -332 -100 -383 -213 -42 -93 -5 -206 83 -252
39 -20 266 -74 312 -74 39 0 40 0 18 -16 -38 -26 -43 -47 -31 -124 11 -64 10
-72 -7 -91 -48 -54 -142 -64 -214 -23 -59 34 -106 36 -131 5 -25 -31 -24 -86
1 -111 26 -26 108 -67 151 -75 35 -7 35 -7 48 -83 31 -185 100 -227 311 -191
63 11 133 25 155 31 51 13 112 75 121 124 7 36 0 148 -12 190 -5 18 3 28 41
54 90 62 127 144 89 198 -12 17 -25 22 -59 22 -40 0 -47 -4 -80 -44 -55 -67
-136 -92 -213 -65 -27 9 -31 16 -37 62 -11 78 -30 117 -65 130 l-29 11 44 17
c69 26 251 158 274 199 45 82 35 164 -30 235 -22 23 -61 52 -87 63 -56 23
-180 33 -270 21z m173 -160 c24 -6 53 -22 66 -35 19 -20 20 -26 8 -40 -26 -31
-191 -136 -222 -141 -34 -5 -233 41 -254 59 -21 17 -15 35 20 70 67 67 275
114 382 87z m27 -742 c32 -6 60 -17 62 -24 3 -6 8 -39 12 -72 5 -47 3 -62 -7
-65 -38 -13 -229 -42 -239 -36 -6 3 -16 35 -22 70 -11 58 -11 64 6 74 11 6 37
25 60 43 23 17 48 29 56 26 8 -3 41 -10 72 -16z"/>
</g>
</svg>

</template>

<script>
export default {
  name: 'rodent-icon'
}
</script>