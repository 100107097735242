<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center" v-if="submitting">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <div class="py-8">Processing your payment...</div>
        </v-col>
        
        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='success'">
            <div>
                    <span class="text-h5 font-weight-light" style="letter-spacing:1px !important">Congratulations!</span>
                </div>

                 <div class="pa-8 text-center">
                        <v-icon class="golden-chip flip-icon" style="border-radius:60px; padding:15px" color="#d4af37" size="60">mdi-crown</v-icon>
                </div>

                <div class="text-center pb-3">
                    <div class="text-overline premium-title" style="font-size:.9em !important;">Premium Membership Activated</div>
                </div>


                <div class="py-8">

                    <v-btn color="primary" :to="'/home'">
                        Continue
                    </v-btn>
                </div>
        </v-col>


        <v-col cols="12" justify="center" align="center" v-if="!submitting && status=='failure'">
            <div>
                    <v-icon color="primary" size="80" class="pa-4">mdi-alert-circle-outline</v-icon>
                </div>
                <div class="pa-8">
                    <span class="text-h6 d-block pb-4">Payment Failed</span>
                    <span>We couldn't complete processing your payment</span>
                </div>

                <div class="py-8">

                    <v-btn color="primary"  @click="$store.dispatch('showPremiumPop', true)">
                        Retry
                    </v-btn>
                </div>
        </v-col>

    </v-row>

 
</v-container>
</template>

<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'PaymentStatus',
    data() {
        return {
            session_id:'',
            status:'',
            plan:'',
            submitting: true,
        }
    },
    mounted() {

        this.session_id = this.$route.query.session_id
        this.status = this.$route.query.status
        this.plan = this.$route.query.plan ? this.$route.query.plan : ''

        

        if(this.status == 'success')
        {

            //Save status
            this.paymentCallback();

        }
        else
        {

            this.submitting = false;

            if (typeof window.gtag === 'function') {
                window.gtag('event', 'subscription_failure_stripe', {
                                event_category: 'Subscription Failure',
                                event_label: 'Subscription Failure'
                            });
            }

            

        }

    },
    methods: {
        
        paymentCallback() {

            this.submitting = true;

            let session_id = this.session_id
            let plan = this.plan
            let status = this.status

            axios.post('/v1/subscription/payment-callback', {
                session_id,
                plan,
                status
            }).then(() => {
                

                let selectedPlan = this.$cookie.get('pm_selected_plan');
                let selectedValue = this.$cookie.get('pm_selected_value');

                window.gtag('set', 'user_data', {"email": this.$store.getters.userDetails.email});

                window.gtag('event', 'conversion', {
                    'send_to': 'AW-10848052375/2kazCJmCuqYDEJfJ4LQo',
                    'value': selectedValue,
                    'currency': 'USD'
                });

                window.gtag('event', 'subscription', {
                    event_category: 'Subscription',
                    event_label: 'Premium Membership',
                    value: selectedValue
                });

                window.gtag('event', 'subscription_stripe_'+selectedPlan, {
                    event_category: 'Subscription',
                    event_label: 'Premium Membership',
                    value: selectedValue
                });

                window.fbq('track', 'Subscribe', {
                    currency: "USD",
                    value: selectedValue
                }); 

                window.twq('event', 'tw-ocimw-ociry', {
                    email_address: this.$store.getters.userDetails.email
                });

                window.pintrk('track', 'checkout', {
                    value: selectedValue,
                    currency: 'USD'
                });

                window.rdt('track', 'Purchase');

                this.$store.dispatch('profile');
                this.$store.dispatch('getConversations');

                this.submitting = false;

                setTimeout(() => {
                    this.$router.replace('/home');
                }, 2000);


            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)
                this.submitting = false;
            });
        },



        
    }
}
</script>
