<template>
      <div  v-if="user.xid != this.$store.getters.userDetails.xid">
        <v-btn @click="reportDialog=true;" depressed text small color="error" style="background-color: #feeced; "><v-icon small >mdi-alert-circle</v-icon>&nbsp;Report User</v-btn>

        <v-dialog v-model="reportDialog" max-width="400">
          <v-card>
            <v-card-title class="text-subtitle-1">Report Scam</v-card-title>
            <v-card-text>
              <p >
                Please provide the reasons for why you believe this user ({{ user.name }}) is a scammer. If you have any proof or screenshots, email them to support@petmeetly.com
              </p>
              <v-textarea autofocus outlined  auto-grow row-height="30" rows="5" placeholder="Your allegation (*required)" v-model="reportMessage"></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="grey" @click="reportDialog = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="reportScam" :loading="submitting" :disabled="submitting">Submit</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" bottom>
          {{ snackbar_text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>

</template>
<script>
  export default {
    name: 'ScamReport',
    props: ['value', 'user'],
    mounted() {
      //console.log('mounted PetPop')
    },
    data() {
      return {
        reportDialog: false,
        reportMessage: '',

        snackbar: false,
        snackbar_text: '',
        submitting: false,
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      blockedUser() {
        return this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
            return o.buid;
        }) : [];
      }
    },
    methods: {
        reportScam()
        {
          let xid = this.user.xid
          let reason = this.reportMessage

          if(reason == '')
          {
              this.snackbar_text = "Please provide a reason";
              this.snackbar = true;
          }
          else
          {

          this.submitting = true;

          this.$store.dispatch('reportScam', {
            xid, reason
          })
              .then((resp) => {
                this.snackbar_text = "Reported successfully";
                this.snackbar = true;
                this.submitting = false;
                this.reportDialog = false;
                this.reportMessage = '';
              })
              .catch(err => {
                this.connecting = false;
                this.snackbar_text = "Error submitting";
                this.snackbar = true;
                this.submitting = false;
              });
        }
      }
    }
  }
</script>
