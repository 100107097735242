<template>
  <v-card-text scrollable>

    <div class="d-flex flex-row">
      <div class="flex-shrink-1">

        <v-avatar size="80" rounded class="rounded-lg">

          <v-img v-if="user.photo" :src="user.photo"></v-img>
          <v-icon v-else size="48" color="primary">$vuetify.icons.account-icon</v-icon>

        </v-avatar>




      </div>
      <div class="flex-grow-1 pl-4">
        <h3>{{ user.name }} <v-icon v-show="user.is_premium" size="20" color="#d4af37" class="pb-1">mdi-crown</v-icon> </h3>
        <div class="text-truncate text-caption">
          <v-icon x-small>
            mdi-map-marker-outline
          </v-icon> {{ location }}
        </div>
        <div>

     
            </div>
            <div>

          <v-chip v-if="user.phone_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-cellphone-check</v-icon>
                                    Phone Verified</v-chip>

            <v-chip v-if="user.email_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-email-check-outline</v-icon>
                                    Email Verified</v-chip>
            <v-chip v-if="user.video_verified" small class="ma-1" color="#eef9e9" text-color="green">
                                        <v-icon small class="mr-2">mdi-video-check-outline</v-icon>
                                    Video Verified</v-chip>
        </div>

      </div>
    </div>

    <div class="text-caption grey--text py-1 font-weight-medium" v-if="user.preferences">
                    Preferences:
                    </div>
                    <div v-if="user.preferences">
                        <v-chip small class="ma-1" > {{user.preferences.pet_type.type}} </v-chip>
                        <v-chip small class="ma-1" > {{user.preferences.age == 'B' ? 'Baby ' : (user.preferences.age == 'Y' ? 'Young ' : (user.preferences.age == 'A' ? 'Adult ' : ( user.preferences.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                        <v-chip small class="ma-1" > {{user.preferences.gender == 'M' ? 'Male ' : (user.preferences.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                        <v-chip small class="ma-1" > {{user.preferences.pet_size ? user.preferences.pet_size.size+' size ':'Any size'}} </v-chip>
                        <v-chip small class="ma-1" > {{user.preferences.pet_breed ? user.preferences.pet_breed.breed+' ':'Any breed'}} </v-chip>
                        <v-chip small class="ma-1" > {{user.preferences.available_for ? 'For '+ user.preferences.available_for: 'For sale/adoption'}} </v-chip>
                        <v-chip small class="ma-1" > {{'Within ' + user.preferences.pref_distance + ' Miles'}} </v-chip>
                    </div>

    <v-row justify="center" no-gutters v-if="user.preferences">
      <v-col cols="12" class="justify-center text-caption text-pre-wrap py-2">

        {{ user.preferences.description }}

      </v-col>
    </v-row>

    <div v-if="user.user_adopter && user.user_adopter.status == 'verified'">
                
                <v-sheet  class="pa-4 my-4 rounded-lg" style="background-color: #f2f2ff;">
                <div class="text-caption">Where does the member live?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.residence_type }}</div>

                <div class="text-caption pt-2">Does the member own or rent their home?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.owns_home == 1 ? "Own" : "Rented" }}</div>

                <div class="float-right mt-n4 primary--text text-caption"  style="cursor:pointer" v-if="!moreInfo" @click="moreInfo = !moreInfo">View More...</div>
                    
                    <v-expand-transition v-if="moreInfo">
                        <div>

                <div class="text-caption pt-2" v-if="user.user_adopter.owns_home != 1">Does the member have permission from their landlord to have a pet?</div>
                <div class="text-caption black--text font-weight-bold" v-if="user.user_adopter.owns_home != 1">{{ user.user_adopter.has_landlord_permission == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">Has the member ever owned a pet before?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_previous_pet_experience == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2" v-if="user.user_adopter.has_previous_pet_experience == 1">What type of pet(s) did the member own and for how long?</div>
                <div class="text-caption black--text font-weight-bold" v-if="user.user_adopter.has_previous_pet_experience == 1">{{ user.user_adopter.previous_pet_details || '-' }}</div>

                <div class="text-caption pt-2">How active is the member’s lifestyle? Do they enjoy outdoor activities?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.activity_level }}</div>

                <div class="text-caption pt-2">How much time is the member willing to dedicate to caring for a pet each day?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.time_commitment }}</div>

                <div class="text-caption pt-2">What is the member looking for in a pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.expectations }}</div>

                <div class="text-caption pt-2">Is the member willing to invest time and resources in training their pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.willing_to_train  == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">How much is the member willing to spend on pet care expenses like food, vet visits, grooming, etc.?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.pet_care_budget }}</div>

                <div class="text-caption pt-2">Does the member have a fenced yard?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_fenced_yard == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">How does the member plan to exercise their pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.exercise_plan }}</div>

                <div class="text-caption pt-2">Does the member have other pets in the household?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_other_pets == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2" v-if="user.user_adopter.has_other_pets == 1">Describe the other pets</div>
                <div class="text-caption black--text font-weight-bold" v-if="user.user_adopter.has_other_pets == 1">{{ user.user_adopter.other_pet_details || '-' }}</div>

                <div class="text-caption pt-2">Does the member have any allergies?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_allergies  == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2" v-if="user.user_adopter.has_allergies == 1">Specify the member’s allergies</div>
                <div class="text-caption black--text font-weight-bold" v-if="user.user_adopter.has_allergies == 1">{{ user.user_adopter.allergies || '-' }}</div>

                <div class="text-caption pt-2">Who will be the primary caregiver for the pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.primary_caregiver }}</div>

                <div class="text-caption pt-2">What are the member’s plans if they go on vacation or are unable to care for the pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.vacation_care_plan }}</div>

                <div class="text-caption pt-2">Is there anything else the member would like to share about themselves or their interest in getting a pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.additional_information || '-' }}</div>

                </div>
                </v-expand-transition>
              
                </v-sheet>
            </div>

    <div class="pa-4 d-flex flex-row align-center justify-center">
        <scam-report-button :user="user"></scam-report-button>
        <block-user-button :user="user"></block-user-button>
    </div>

  </v-card-text>
</template>
<script>
  import BlockUserButton from "./BlockUser.vue";
  import ScamReportButton from "./ScamReport.vue";

  export default {
    name: 'PetPop',
    components: {ScamReportButton, BlockUserButton},
    props: ['value', 'user'],
    mounted() {
      //console.log(this.user)
    },
    data() {
      return {
        moreInfo: false
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      location() {
        return this.user.preferences ? ((this.user.preferences.locality ? this.user.preferences.locality + ', ' : (this.user.preferences.city ? this.user.preferences.city + ', ' : '')) + this.user.preferences.state) : '';
      },
      blockedUser() {
        return this.$store.getters.userDetails.myBlocked ? this.$store.getters.userDetails.myBlocked.map(o => {
            return o.buid;
        }) : [];
      }
    },
    methods: {
    }
  }
</script>
