<template>
<v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable max-width="450" transition="dialog-transition" :retain-focus="false">
    <v-card>
        <v-toolbar flat dark color="primary" max-height="56">

            <v-btn icon @click.stop="show=false">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title>
                Messages
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height:600px" class="pa-0">

            <v-row no-gutters v-if="this.$store.getters.getPetConversations(pet.xid).length == 0">
                <v-col cols="12" class="pa-12 text-center" >
                    <v-icon size="120" color="#eee">mdi-email-off</v-icon>
                    <div>No Messages</div>
                </v-col>
            </v-row>

            <v-list two-line>
                <div v-for="(item,index) in this.$store.getters.getPetConversations(pet.xid)" :key="index">
                    <conversation-row :item="item" v-if="!item.is_archived_for_user"></conversation-row>
                    <v-divider inset v-if="!item.is_archived_for_user"></v-divider>
                </div>
            </v-list>
        </v-card-text>
    </v-card>
    
</v-dialog>

</template>

<script>
import ConversationRow from './ConversationRow.vue'
export default {
    name: 'Conversations',
    props: ['value', 'pet'],
    data() {
        return {

        }
    },
    components: {
        ConversationRow
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {

    }

}
</script>
