<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">

                <div v-if="$store.getters.isPremium">


                    <div v-if="fetching">
                    <v-row>
                        <v-col cols="12" justify="center" align="center" class="mt-5">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </v-col>

                    </v-row>
                </div>

                <div v-else>

                   
                    <div class="text-center">
                        <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                            large>mdi-crown</v-icon>
                        <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Premium Member
                        </div>
                        <div class="py-8">

                            <div class="text-subtitle-2">Your {{ subscription_period }} Subscription is <span
                                    class="font-weight-bold" :class="status == 'ACTIVE' ? 'success--text' : 'orange--text'"> {{ status }}</span></div>
                            <div v-if="next_billing != '' && status == 'ACTIVE'" class="text-caption pt-4">Next billing on <span
                                    class="font-weight-bold">{{ next_billing }}</span></div>
                            <div v-if="cancel_at != '' && status == 'ACTIVE'" class="text-caption pt-4">Expires on <span
                                    class="font-weight-bold">{{ cancel_at }}</span></div>
                            <div v-if="$store.getters.userDetails.subscription_route == 'apple' && status == 'ACTIVE' && canceled" class="text-caption pt-4">Auto-Renew Canceled</div>
                        </div>


                        <div class="pa-4 mb-8 text-left" style="background-color: rgba(0, 31, 63, 0.05); border-radius:10px;">
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Send/Receive unlimited messages</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Share contact details</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Direct connect credits</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4">Stand out with the Premium tag</v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4">Get Featured on Social Media</v-col>
                        </v-row> -->
                    </div>

                        
                        <div v-if="$store.getters.userDetails.subscription_route != 'apple' && sub_reference_id != '' && status == 'ACTIVE' && next_billing != ''">
                            <v-btn color="error" depressed @click="cancelConfirm = true" :disabled="cancelling" :loading="cancelling">
                                Cancel Subscription
                            </v-btn>
                        </div>
                        <div v-if="$store.getters.userDetails.subscription_route == 'apple' && status == 'ACTIVE' && !canceled">
                            <v-btn color="error" depressed target="_blank" href="https://apps.apple.com/account/subscriptions">
                                Cancel Subscription
                            </v-btn>
                        </div>


                    </div>




                </div>



                </div>

                <div v-else>

                    <div class="text-center">
                        <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                            large>mdi-crown</v-icon>
                        <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Unlock Premium
                            Benefits </div>
                    </div>

                    <div class="pa-4 mt-4 mb-8" style="background-color: rgba(0, 31, 63, 0.05); border-radius:10px;">
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Send/Receive unlimited messages</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Share contact details</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Direct connect credits</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Stand out with our Premium tag</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4">Get Featured on Social Media</v-col>
                        </v-row>
                    </div>

                    <div class="text-center my-8">
                        <v-btn class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                            PREMIUM</v-btn>
                    </div>

                </div>


                

                
            </v-col>
        </v-row>

    <v-dialog v-model="cancelConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p>
                    Are you sure you want to cancel your Premium Membership subscription?
                </p>
            </v-card-text>

            <v-card-actions>
                    <v-btn text color="primary" @click="cancelConfirm = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="cancelSubscription" :loading="cancelling" :disabled="cancelling">Yes, Cancel Now</v-btn>
                </v-card-actions>

        </v-card>
    </v-dialog>

        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>
    
<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'Membership',

    data() {
        return {

            snackbar: false,
            snackbar_text: '',
            next_billing: '',
            cancel_at: '',
            status: '',
            subscription_period: '',
            fetching: false,
            cancelling: false,
            sub_reference_id: '',
            cancelConfirm: false,
            route: '',
            canceled: false,


        }
    },

    mounted() {


        if (this.$store.getters.userDetails.name == '') {
            this.$store.dispatch('profile').then(() => {
                this.getSubscriptionDetails();
            });
        } else {
            this.getSubscriptionDetails();
        }

    },

    methods: {
        getSubscriptionDetails() {

            
            let url = '';

            //Based on route get the subscription details

            if(this.$store.getters.userDetails.subscription_route == 'stripe')
            {
                url = '/v1/subscription/get';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'fastspring')
            {
                url = '/v1/fastspring/subscription/get';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'fastspring-w')
            {
                url = '/v1/fastspring/subscription/get-w';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'cashfree')
            {
                url = '/v1/subscription-cf/get';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'cashfree-single')
            {
                url = '/v1/subscription-cfs/get';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'apple')
            {
                url = '/v1/apple/subscription/get';
            }

            if(url != '')

            {
                this.fetching = true;
                
                axios.get(url).then((resp) => {
               // console.log(resp.data);

                this.sub_reference_id = resp.data.sub_reference_id;
                this.status = resp.data.status;
                this.subscription_period = resp.data.subscription_period;
                this.next_billing = resp.data.next_billing;
                this.cancel_at = resp.data.cancel_at ? resp.data.cancel_at : '';
                this.canceled = resp.data.canceled ? resp.data.canceled : false;

                this.fetching = false;
                }).catch(err => {
                   // console.log(err.response);
                    Bugsnag.notify(err)
                    this.fetching = false;
                });
            }

            


        },
        cancelSubscription() {
            this.cancelling = true;
            let url;

            if(this.$store.getters.userDetails.subscription_route == 'stripe')
            {
                url = '/v1/subscription/cancel/';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'fastspring')
            {
                url = '/v1/fastspring/subscription/cancel/';
            }
            else if(this.$store.getters.userDetails.subscription_route == 'cashfree')
            {
                url = '/v1/subscription-cf/cancel/';
            }

            axios.post(url + this.sub_reference_id).then((resp) => {
                    //console.log(resp.data);
                    this.getSubscriptionDetails();
                    window.location.reload(true);
                    this.cancelling = false;
                    this.cancelConfirm = false;
                }).catch(err => {
                    //console.log(err.response);
                    Bugsnag.notify(err)
                    this.cancelling = false;
                    this.cancelConfirm = false;
                });   

        },

        showPremium() {
            this.$store.dispatch('showPremiumPop', true);
        },
    }
}
</script>
    