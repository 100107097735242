<template>

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M1715 5110 c-40 -15 -49 -38 -47 -113 3 -97 26 -243 57 -367 74 -293
204 -501 380 -610 l35 -22 0 -144 c0 -113 -3 -144 -13 -144 -8 0 -66 -17 -130
-38 l-116 -37 -16 95 c-73 416 -222 692 -427 793 -229 112 -453 50 -601 -167
-60 -89 -101 -185 -191 -455 -82 -243 -109 -311 -124 -311 -4 0 -33 27 -64 59
-82 85 -164 109 -256 77 -98 -35 -152 -112 -152 -217 0 -88 24 -132 131 -233
68 -65 110 -95 176 -128 85 -42 88 -43 192 -43 95 0 112 3 161 27 77 38 131
87 184 167 60 89 102 190 182 434 65 197 139 377 155 377 11 0 53 -57 77 -105
51 -102 110 -350 126 -530 l7 -70 -76 -59 c-42 -33 -102 -88 -133 -124 -68
-77 -75 -118 -27 -159 45 -39 73 -27 173 74 153 155 374 284 637 371 l120 41
11 -107 c13 -121 25 -171 61 -256 93 -222 297 -400 528 -461 85 -23 312 -34
660 -34 377 0 576 11 673 39 140 39 273 122 369 229 22 25 43 44 46 41 3 -3
16 -41 30 -85 115 -369 98 -815 -45 -1146 l-20 -48 -531 -323 c-342 -208 -538
-322 -552 -319 -86 14 -965 239 -1046 267 -138 48 -235 92 -223 103 5 4 139
100 299 212 159 112 312 227 340 254 200 196 173 535 -56 707 -105 79 -271
109 -402 74 -43 -12 -217 -104 -567 -301 -278 -156 -508 -280 -513 -276 -4 4
-19 59 -34 122 -23 98 -27 137 -27 274 -1 145 2 169 27 257 29 104 27 137 -12
157 -27 15 -73 14 -94 -1 -43 -32 -87 -240 -87 -407 0 -131 11 -211 51 -369
17 -68 29 -125 27 -127 -2 -1 -23 -13 -48 -27 -708 -399 -977 -553 -987 -566
-7 -9 -13 -32 -13 -51 1 -56 335 -1309 356 -1332 10 -12 30 -19 51 -19 19 0
181 32 360 71 l326 70 581 -41 c319 -22 835 -59 1146 -81 311 -23 615 -41 675
-41 212 -1 339 54 567 248 79 67 155 133 171 147 55 52 22 137 -54 137 -30 0
-56 -18 -199 -140 -187 -159 -264 -204 -387 -225 -73 -13 -145 -9 -1203 65
-619 44 -1170 82 -1225 85 -97 6 -109 4 -395 -59 -162 -35 -296 -63 -298 -61
-2 2 -71 257 -153 567 -116 439 -146 565 -136 574 19 17 1968 1114 2087 1174
61 31 161 38 228 16 30 -10 72 -36 102 -63 121 -109 143 -266 55 -399 -29 -44
-87 -89 -409 -318 -207 -147 -381 -276 -386 -286 -16 -30 -12 -64 11 -88 40
-43 299 -162 465 -213 103 -32 1033 -259 1060 -259 33 0 -9 -24 626 362 307
186 570 343 585 349 15 5 53 10 84 10 218 1 346 -245 220 -423 -14 -20 -138
-132 -276 -248 -293 -249 -305 -260 -305 -301 0 -40 39 -79 79 -79 33 0 44 9
416 327 254 217 294 278 295 454 0 137 -40 230 -139 322 -72 67 -146 99 -247
105 l-80 4 23 82 c51 173 66 295 67 526 0 179 -4 234 -22 330 -27 145 -63 273
-107 373 l-33 77 23 68 c34 101 45 220 45 499 l0 251 38 23 c55 34 159 136
202 199 88 130 152 295 195 503 32 153 44 339 24 363 -33 40 -39 39 -640 -111
l-581 -146 -493 0 -493 0 -581 146 c-319 80 -582 145 -583 144 -2 0 -12 -4
-23 -9z m499 -273 l368 -93 -66 -43 -66 -43 -63 21 c-145 47 -376 91 -481 91
-51 0 -49 -4 -64 103 -5 31 -6 57 -3 57 3 0 172 -42 375 -93z m2732 28 c-4
-36 -9 -71 -12 -78 -3 -10 -32 -16 -92 -20 -122 -9 -244 -32 -384 -73 l-121
-34 -65 42 -65 42 364 92 c200 51 368 93 373 93 5 1 6 -28 2 -64z m-961 -214
c28 -7 92 -33 143 -57 130 -62 224 -156 286 -286 56 -117 67 -168 73 -345 l6
-143 -309 0 c-282 0 -381 -6 -455 -25 -24 -6 -31 0 -73 64 -47 70 -111 126
-173 150 -20 8 -61 12 -107 9 -68 -3 -80 -7 -133 -43 -38 -27 -74 -64 -106
-110 -30 -43 -55 -69 -65 -69 -9 1 -48 7 -87 13 -39 6 -206 11 -379 11 l-309
0 6 153 c7 175 21 238 80 352 99 190 278 307 522 339 17 2 255 3 530 1 353 -1
515 -6 550 -14z m-1918 -61 c101 -17 188 -37 241 -55 10 -4 7 -14 -11 -37 -47
-63 -82 -133 -112 -225 l-31 -92 -48 47 c-55 54 -92 105 -130 179 -27 54 -76
179 -76 194 0 13 36 11 167 -11z m2823 15 c0 -2 -13 -41 -30 -86 -42 -114 -92
-200 -162 -275 l-62 -65 -27 85 c-33 99 -47 130 -101 209 -27 40 -35 59 -26
62 114 38 408 89 408 70z m-3535 -224 c173 -85 297 -335 358 -723 l14 -88 -66
-33 -67 -34 -18 123 c-59 409 -196 654 -367 654 -58 0 -124 -71 -176 -188 -14
-31 -63 -168 -109 -304 -119 -352 -173 -451 -275 -502 -113 -56 -221 -22 -361
113 -65 64 -78 81 -78 108 0 32 37 73 66 73 8 0 49 -32 91 -71 133 -121 194
-138 255 -71 51 56 87 141 178 412 44 134 99 281 122 327 60 123 140 205 228
234 48 16 139 2 205 -30z m2115 -557 c22 -20 49 -52 61 -71 l22 -35 -52 -50
c-29 -28 -66 -68 -82 -91 -16 -22 -29 -35 -29 -28 0 6 -34 47 -76 90 -83 88
-83 87 -26 156 62 76 120 85 182 29z m-499 -174 c128 -24 252 -119 307 -237
27 -58 27 -60 30 -312 l3 -254 -223 7 c-139 4 -247 12 -287 22 -182 42 -342
169 -427 339 -50 101 -64 163 -71 313 l-6 132 310 0 c171 0 335 -5 364 -10z
m1516 -117 c-7 -147 -23 -219 -72 -318 -81 -165 -248 -299 -425 -339 -42 -10
-151 -18 -294 -22 l-226 -7 0 189 c0 218 11 307 45 381 50 109 177 209 295
232 25 5 189 9 364 10 l319 1 -6 -127z"/>
<path d="M2746 4134 c-83 -83 -43 -264 59 -264 62 0 95 51 95 146 0 119 -86
185 -154 118z"/>
<path d="M3927 4133 c-45 -48 -54 -154 -19 -220 33 -61 124 -58 156 5 69 138
-42 313 -137 215z"/>
<path d="M2756 3469 c-35 -28 -36 -80 -1 -114 39 -40 96 -32 121 17 42 81 -47
154 -120 97z"/>
<path d="M2970 3230 c-12 -12 -20 -33 -20 -55 0 -48 29 -75 79 -75 31 0 44 6
60 26 12 15 21 37 21 49 0 12 -9 34 -21 49 -26 33 -88 37 -119 6z"/>
<path d="M2683 3225 c-78 -33 -47 -145 39 -145 60 0 95 59 67 113 -15 29 -12
26 -46 36 -19 6 -40 4 -60 -4z"/>
<path d="M3925 3465 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 15 -16 36
-25 55 -25 19 0 40 9 55 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -15 16 -36
25 -55 25 -19 0 -40 -9 -55 -25z"/>
<path d="M3734 3250 c-28 -11 -54 -48 -54 -76 0 -12 9 -33 21 -48 42 -54 139
-23 139 45 0 41 -9 58 -41 74 -32 17 -36 17 -65 5z"/>
<path d="M4010 3210 c-26 -26 -26 -70 1 -104 44 -56 139 -20 139 53 0 64 -94
97 -140 51z"/>
<path d="M2026 2309 c-32 -25 -34 -71 -7 -150 60 -175 139 -258 247 -259 48 0
197 68 213 99 17 31 13 79 -9 101 -29 29 -78 25 -141 -10 -30 -16 -60 -30 -67
-30 -20 0 -71 88 -93 163 -31 100 -84 132 -143 86z"/>
</g>
</svg>


  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path fill-rule="nonzero" d="M17.841 15.659l.176.177.178-.177a2.25 2.25 0 0 1 3.182 3.182l-3.36 3.359-3.358-3.359a2.25 2.25 0 0 1 3.182-3.182zM12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 7.75-7.996L12 14zm0-13c3.315 0 6 2.685 6 6a5.998 5.998 0 0 1-5.775 5.996L12 13c-3.315 0-6-2.685-6-6a5.998 5.998 0 0 1 5.775-5.996L12 1zm0 2C9.79 3 8 4.79 8 7s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"></path></g></svg> -->
</template>

<script>
export default {
name: 'adopter-icon'
}
</script>