<template>

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M1170 4860 c-517 -66 -941 -413 -1105 -903 -48 -145 -65 -254 -65
-428 0 -221 38 -392 130 -583 71 -147 207 -330 256 -342 57 -14 115 44 98 98
-4 13 -25 43 -46 68 -103 117 -200 302 -244 465 -21 80 -28 130 -31 250 -3 86
-1 180 6 221 108 699 809 1152 1480 957 239 -69 353 -143 604 -391 l187 -186
-324 -326 c-226 -227 -329 -337 -340 -364 -22 -54 -21 -148 3 -194 41 -80 143
-156 264 -197 92 -31 241 -35 328 -9 125 37 163 65 392 292 l217 216 58 -55
c716 -692 701 -679 735 -679 64 0 104 60 75 113 -7 13 -159 165 -338 337 -179
172 -362 349 -407 394 -76 74 -86 81 -123 81 -39 0 -47 -7 -287 -247 -268
-267 -296 -287 -415 -306 -112 -17 -234 18 -317 93 -31 28 -41 45 -41 68 0 37
47 86 706 731 489 479 526 510 710 583 167 67 261 86 439 87 121 1 181 -4 245
-18 150 -32 289 -91 421 -176 86 -55 133 -52 159 10 20 49 -1 82 -84 135 -137
88 -303 155 -476 192 -117 24 -381 24 -502 0 -179 -36 -347 -105 -497 -204
-60 -39 -146 -116 -283 -252 l-198 -195 -197 195 c-136 134 -225 213 -284 252
-149 99 -320 169 -492 202 -96 19 -324 27 -417 15z"/>
<path d="M4705 4380 c-34 -38 -24 -79 37 -163 101 -138 180 -329 208 -500 15
-93 12 -303 -5 -396 -30 -157 -90 -304 -183 -446 -27 -42 -122 -148 -236 -262
l-190 -193 -142 140 c-130 128 -146 140 -179 140 -62 0 -102 -59 -75 -109 5
-10 133 -141 284 -291 151 -150 283 -289 293 -309 25 -50 23 -144 -4 -196 -53
-101 -157 -150 -265 -125 -51 12 -63 23 -398 355 -190 189 -355 348 -367 354
-55 28 -113 -10 -113 -75 0 -36 17 -55 394 -433 383 -386 394 -397 406 -449
25 -109 -25 -213 -130 -268 -50 -26 -143 -24 -195 3 -23 12 -197 180 -400 383
-198 199 -374 368 -392 376 -24 10 -39 11 -62 3 -37 -13 -51 -35 -51 -80 0
-29 35 -67 343 -378 313 -315 345 -350 357 -393 29 -107 -24 -219 -130 -274
-49 -25 -144 -25 -192 1 -43 23 -323 297 -343 336 -30 56 -107 129 -172 161
-53 26 -80 32 -149 36 -82 4 -84 5 -78 28 10 40 -16 156 -48 216 -67 125 -209
208 -357 208 l-38 0 -5 73 c-7 97 -37 171 -98 240 -72 80 -155 119 -266 125
-82 4 -84 5 -78 28 10 40 -16 156 -48 217 -78 145 -249 229 -408 199 -109 -20
-161 -52 -285 -173 l-115 -112 -79 76 c-87 85 -116 95 -165 56 -20 -15 -26
-29 -26 -57 0 -32 9 -46 77 -114 l76 -77 -63 -67 c-37 -40 -75 -95 -94 -133
-28 -60 -31 -75 -31 -166 0 -91 3 -106 31 -165 56 -116 160 -196 285 -221 l58
-11 -7 -32 c-4 -17 -7 -67 -6 -111 2 -107 29 -178 99 -253 103 -112 248 -153
401 -116 20 5 23 0 29 -44 14 -106 93 -221 190 -278 84 -49 216 -65 311 -38
19 5 22 0 28 -43 9 -69 58 -165 111 -218 25 -26 76 -61 113 -78 60 -28 77 -32
157 -32 106 0 174 20 241 71 l44 34 45 -35 c55 -41 157 -75 229 -75 188 1 351
133 387 317 l13 63 35 -7 c20 -5 70 -7 111 -7 103 2 174 30 250 100 66 62 104
129 121 215 l12 59 41 -8 c69 -12 170 -6 224 13 103 37 200 134 237 237 19 55
26 167 12 229 l-7 36 58 12 c87 17 154 55 216 121 91 99 125 231 92 360 -23
87 -52 133 -148 235 l-81 86 190 192 c273 277 369 430 439 709 35 139 45 404
20 548 -37 211 -123 410 -254 583 -41 54 -46 57 -86 57 -31 0 -48 -6 -61 -20z
m-3307 -1899 c109 -56 158 -197 105 -306 -21 -42 -440 -462 -485 -485 -53 -28
-111 -35 -165 -19 -65 18 -147 97 -163 159 -16 59 -6 132 24 184 13 22 120
136 238 252 234 232 257 247 354 240 28 -3 70 -14 92 -25z m439 -442 c79 -38
133 -124 133 -210 0 -90 -8 -100 -289 -382 -146 -148 -284 -278 -306 -290 -28
-15 -58 -21 -100 -21 -97 0 -172 50 -214 144 -25 55 -26 101 -4 166 14 43 49
83 272 307 281 284 312 307 410 307 35 0 70 -7 98 -21z m451 -448 c109 -56
158 -197 105 -306 -21 -42 -440 -462 -485 -485 -53 -28 -111 -35 -165 -19 -65
18 -147 97 -163 159 -14 53 -8 126 16 172 9 18 116 133 238 255 196 196 227
223 271 237 61 19 129 14 183 -13z m439 -442 c55 -27 107 -88 123 -145 16 -56
7 -135 -19 -177 -11 -18 -102 -113 -203 -213 -161 -159 -189 -182 -231 -194
-140 -38 -283 61 -295 206 -8 99 10 128 207 326 123 123 190 183 221 197 60
27 141 27 197 0z m381 -436 c25 -85 5 -164 -60 -230 -82 -84 -209 -96 -294
-28 l-29 23 121 124 c96 99 125 135 144 182 l25 58 40 -45 c23 -24 47 -63 53
-84z"/>
</g>
</svg>


  <!-- <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M519.2 127.9l-47.6-47.6A56.252 56.252 0 0 0 432 64H205.2c-14.8 0-29.1 5.9-39.6 16.3L118 127.9H0v255.7h64c17.6 0 31.8-14.2 31.9-31.7h9.1l84.6 76.4c30.9 25.1 73.8 25.7 105.6 3.8 12.5 10.8 26 15.9 41.1 15.9 18.2 0 35.3-7.4 48.8-24 22.1 8.7 48.2 2.6 64-16.8l26.2-32.3c5.6-6.9 9.1-14.8 10.9-23h57.9c.1 17.5 14.4 31.7 31.9 31.7h64V127.9H519.2zM48 351.6c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16c0 8.9-7.2 16-16 16zm390-6.9l-26.1 32.2c-2.8 3.4-7.8 4-11.3 1.2l-23.9-19.4-30 36.5c-6 7.3-15 4.8-18 2.4l-36.8-31.5-15.6 19.2c-13.9 17.1-39.2 19.7-55.3 6.6l-97.3-88H96V175.8h41.9l61.7-61.6c2-.8 3.7-1.5 5.7-2.3H262l-38.7 35.5c-29.4 26.9-31.1 72.3-4.4 101.3 14.8 16.2 61.2 41.2 101.5 4.4l8.2-7.5 108.2 87.8c3.4 2.8 3.9 7.9 1.2 11.3zm106-40.8h-69.2c-2.3-2.8-4.9-5.4-7.7-7.7l-102.7-83.4 12.5-11.4c6.5-6 7-16.1 1-22.6L367 167.1c-6-6.5-16.1-6.9-22.6-1l-55.2 50.6c-9.5 8.7-25.7 9.4-34.6 0-9.3-9.9-8.5-25.1 1.2-33.9l65.6-60.1c7.4-6.8 17-10.5 27-10.5l83.7-.2c2.1 0 4.1.8 5.5 2.3l61.7 61.6H544v128zm48 47.7c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16c0 8.9-7.2 16-16 16z"></path></svg> -->
</template>

<script>
export default {
name: 'friend-icon'
}
</script>

