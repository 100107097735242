import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'

import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import VueCookie from 'vue-cookie'

import auth from './auth'
import app from './app'
import pet from './pet'

Vue.use(Vuex)
Vue.use(VueCookie)

export default new Vuex.Store({
  modules: {
      auth,
      app,
      pet,
  },
  state: {
      petTypes:[],
      petSizes:[],
      cookiePolicy: Vue.cookie.get("pm_cookie_policy") ? Vue.cookie.get("pm_cookie_policy") : "",
      routerHistory: [],
      location: Vue.cookie.get("pm_location") ? Vue.cookie.get("pm_location") : null,
  },
  getters: {

    cookiePolicy: state => state.cookiePolicy == "accept" ? false : true,
    location: state => state.location,

      petTypes: state => state.petTypes,
      petSizes: state => state.petSizes,
      
      previousRoute: (state) => {
          const historyLen = state.routerHistory.length;
          if (historyLen == 0) return null;
          return state.routerHistory[historyLen - 1];
        },

      breedDetails: (state) => (type_id, breed_id) =>{
          let pet_type = state.petTypes.find(
              pet => pet.xid === type_id
          );
          return pet_type.breeds.find(
              breed => breed.xid === breed_id
          );
      }, 

      sizeDetails: (state) => (size_id) =>{
          return state.petSizes.find(
              size => size.xid === size_id
          );
      }, 

      typeDetails: (state) => (type_id) =>{
          return state.petTypes.find(
              type => type.xid === type_id
          );
      }, 

  },
  mutations: {

      setStaticData(state,payload)
      {
          state.petTypes = payload.petTypes;
          state.petSizes = payload.petSizes;
      },

      setCookiePolicy(state,payload)
      {
          state.cookiePolicy = payload;
      },

      setLocation(state, locationData) {
        state.location = locationData;
      }


  },
  actions: {
    
      setCookiePolicy({ commit }, accept) {
        Vue.cookie.set("pm_cookie_policy", accept, 9999);
        commit("setCookiePolicy", accept);
      },

      getStaticData({ commit }) {
          return new Promise((resolve, reject) => {
              axios
                  .get("/v1/static-data")
                  .then(resp => {
                      const data = resp.data;
                      commit("setStaticData", data);
                      resolve();
                  })
                  .catch(err => {
                      Bugsnag.notify(err);
                      reject(err);
                  });
          });
      },

      contactSupport({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post("/v1/contact-support", data)
                .then(resp => {
                    let message = resp.data;
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    unSubscribeProspect({ commit }, email) {
        return new Promise((resolve, reject) => {
            axios
                .post("/v1/prospect/unsubscribe/"+email)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    fetchLocation({ commit }) {
        axios.get('/v1/get-location')
          .then(response => {
            const iso_code = response.data.iso_code;
            commit('setLocation', iso_code);
            Vue.cookie.set("pm_location", iso_code, 9999);
          })
          .catch(error => console.error('Error fetching location:', error));
      }

    }
  
})
